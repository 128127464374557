import {compose, lifecycle, withHandlers, withState} from 'recompose';

import View from './View';
import {
    deleteAnswer,
    quizStateActions,
    sortAnswer,
    updateAnswer,
    updateQuestion,
    updateQuizData,
    uploadImage,
    deleteResult,
    sortResult, cpAddError, cpSetIsEditQuizRoute
} from "../../../_actions";
import {connect} from 'react-redux';
import {
    deleteAnswerHandler as deleteAnswerHandlerOld,
    editHandler as editHandlerOld,
    getColorsCodes as oldGetColorsCodes,
    sortAnswerHandler as sortAnswerHandlerOld,
    uploadImageHandler as uploadImageHandlerOld
} from "oldkitleadforms";

import {
    deleteAnswerHandler as deleteAnswerHandlerNew,
    editHandler as editHandlerNew,
    getColorsCodes,
    sortAnswerHandler as sortAnswerHandlerNew,
    uploadImageHandler as uploadImageHandlerNew
} from "newkitleadforms";
import {t} from "leadformsquizentities/translate";
import {store} from "../../../_services";
import {isNumber} from "util";

export default compose(
    connect(
        (state: any, props: any) => {
            const quizData = state.quizData.toJS();
            return {
                quizData: quizData,
                quizState: state.quizState,
                quizId: props.match.params.quizId,
                isNewTheme: quizData.isNewTheme,
                isQuizBot: !!quizData.isQuizBot,
                resultAfterQuestion: quizData && quizData.info && quizData.info.results && quizData.info.results.afterQuestion,
                colorTheme: !quizData.isEmpty ? quizData.info.design.colors.main : '',
                language: !quizData.isEmpty && quizData.info.language ? quizData.info.language : 'russian'
            }
        },
        (dispatch) => {
            return {
                dispatch
            }
        }
    ),
    withState('previewQuizId', 'setPreviewQuizId', false),
    withState('isActiveMobileMenu', 'setIsActiveMobileMenu', false),
    withState('initedQuizModule', 'setInitedQuizModule', false),
    withHandlers({
        cahngeIsActiveMobileMenu: (props: any) => () => {
            props.setIsActiveMobileMenu(!props.isActiveMobileMenu)
        },
        PreviewQuizHandler: (props: any) => (quizId: number) => {
            props.setPreviewQuizId(quizId)
        },
        changeIsNewThemeHandler: (props: any) => () => {
            let isNewTheme = props.isNewTheme
            props.quizData.isNewTheme = !isNewTheme
            props.dispatch(updateQuizData(props.quizData));
        },
        changeIsQuizBotHandler: (props: any) => () => {
            let isQuizBot = !props.isQuizBot
            props.quizData.isQuizBot = isQuizBot
            if (isQuizBot) {
                props.quizData.isNewTheme = true;
            }
            props.dispatch(updateQuizData(props.quizData));
        },
        initQuizModule: (props: any) => () => {

            const colorTheme = props.colorTheme;
            const language = props.language;

            getColorsCodes(colorTheme);
            oldGetColorsCodes(colorTheme);
            t('', language);

            const editHandlerCallback = (keyField: string, value: string) => {

                if (!keyField || keyField.length === 0) {
                    return;
                }
                const path = keyField.split('.');

                const updateObject = (data: [], path: string | number[], value: any) => {
                    if (path.length === 1) {
                        (data as any)[(path as any)[0]] = value;
                        return;
                    } else if (path.length > 1) {
                        updateObject((data as any)[(path as any)[0]], path.slice(1), value)
                    }
                }
// изменение вопроса
                if (path[0] === 'questions') {
                    const questionId: number = path[1] as any;
                    const quizData = store.getState().quizData.toJS();
                    let question = quizData.questions[questionId];
// изменение ответа вопроса
                    if (path[2] === 'answers') {
                        const answerId: number = path[3] as any;
                        let answer = question.answers[answerId];
                        updateObject(answer, path.slice(4) as any, value)
                        store.dispatch(updateAnswer(answer.id, answer));
                        return;
                    }

                    updateObject(question, path.slice(2) as any, value)
                    store.dispatch(updateQuestion(question.id, question));
                } else {
// изменение остального
                    let quizData = store.getState().quizData.toJS();

                    updateObject(quizData, path as any, value)
                    store.dispatch(updateQuizData(quizData))
                }
            }

            editHandlerNew('', '', editHandlerCallback);
            editHandlerOld('', '', editHandlerCallback);

            deleteAnswerHandlerOld('', (answerId: string | number) => {
                if (!answerId || (answerId as string).length === 0) {
                    return;
                }
                store.dispatch(deleteAnswer(answerId))
            });
            deleteAnswerHandlerNew('', undefined, (answerId: string | number, type?: string) => {
                if ((answerId as string).length === 0) {
                    return;
                }
                if (type === 'result') {
                    store.dispatch(deleteResult(answerId))
                } else {
                    store.dispatch(deleteAnswer(answerId))
                }

            });

            sortAnswerHandlerOld('', '', (newIndex: string | number, oldIndex: string | number) => {
                if (!isNumber(newIndex) || !isNumber(oldIndex)) {
                    return;
                }
                if (newIndex === oldIndex) return;
                store.dispatch(sortAnswer(newIndex, oldIndex))
            });
            sortAnswerHandlerNew('', '', undefined, (newIndex: string | number, oldIndex: string | number, type?: string) => {
                if (!isNumber(newIndex) || !isNumber(oldIndex)) {
                    return;
                }

                if (newIndex === oldIndex) return;

                if(type === 'result') {
                    store.dispatch(sortResult(newIndex, oldIndex))
                } else {
                    store.dispatch(sortAnswer(newIndex, oldIndex))
                }
            });



            uploadImageHandlerOld('', false, undefined, (type: string, e: any, additional?: any) => {
                if (!e) return;

                e.preventDefault();
                const files = e.target.files;

                let setData: any = (quizData: any) => {
                    return quizData
                };
                switch (type) {
                    case "START_LOGO":
                        setData = (quizData: any, value: any) => {
                            quizData.info.startPage.logo = value;
                            return quizData;
                        }
                        break;
                    case "START_RENDER":
                        setData = (quizData: any, value: any) => {
                            quizData.info.startPage.bg = value;
                            return quizData;
                        }
                        break;
                    case "IMAGE_ANSWER":
                        setData = (quizData: any, value: any) => {
                            const quizState = store.getState().quizState;
                            const questionActive = quizData.questions[quizState.activeKeyItem];

                            questionActive.answers[additional].image = value;
                            quizData.questions[quizState.activeKeyItem] = questionActive;

                            store.dispatch(updateAnswer(questionActive.answers[additional].id, questionActive.answers[additional]));
                            return quizData;
                        }

                        break;
                    case "MANAGER_LOGO":
                        setData = (quizData: any, value: any) => {
                            quizData.info.assistant.avatar = value;
                            return quizData;
                        }
                        break;
                }

                if (files && files.length) {
                    store.dispatch(uploadImage(files[0], (fileData: any) => {
                        let quizData = store.getState().quizData.toJS();
                        store.dispatch(updateQuizData(setData(quizData, {
                            server: fileData.server,
                            url: "images/" + fileData.name
                        })))
                    }))
                }
                ;
            })
            uploadImageHandlerNew('', false, undefined, (type: string, e: any, additional?: any) => {
                if (!e) return;

                e.preventDefault();
                const files = e.target.files;

                let setData: any = (quizData: any) => {
                    return quizData
                };

                switch (type) {
                    case "START_LOGO":
                        setData = (quizData: any, value: any) => {
                            quizData.info.startPage.logo = value;
                            return quizData;
                        }
                        break;
                    case "START_RENDER":
                        setData = (quizData: any, value: any) => {
                            quizData.info.startPage.bg = value;
                            return quizData;
                        }
                        break;
                    case "IMAGE_ANSWER":
                        setData = (quizData: any, value: any) => {
                            const quizState = store.getState().quizState;
                            const questionActive = quizData.questions[quizState.activeKeyItem];
                            questionActive.answers[additional].image = value;
                            quizData.questions[quizState.activeKeyItem] = questionActive;

                            store.dispatch(updateAnswer(questionActive.answers[additional].id, questionActive.answers[additional]));
                            return quizData;
                        }

                        break;
                    case "IMAGE_RESULT":
                        setData = (quizData: any, value: any) => {
                            quizData.info.results.items[additional].image = value;

                            store.dispatch(updateQuizData(quizData));
                            return quizData;
                        }

                        break;
                    case "MANAGER_LOGO":
                        setData = (quizData: any, value: any) => {
                            quizData.info.assistant.avatar = value;
                            return quizData;
                        }
                        break;
                }

                if (files && files.length) {
                    store.dispatch(uploadImage(files[0], (fileData: any) => {
                        let quizData = store.getState().quizData.toJS();
                        store.dispatch(updateQuizData(setData(quizData, {
                            server: fileData.server,
                            url: "images/" + fileData.name
                        })))
                    }))
                }
                ;
            })

            props.setInitedQuizModule(true);
        }
    }),
    lifecycle({
        componentWillUnmount() {
            (this as any).props.dispatch(cpSetIsEditQuizRoute(false))
        },
        componentDidMount() {
            (this as any).props.dispatch(cpSetIsEditQuizRoute(true))
            if ((this as any).props.quizState.loading || (this as any).props.quizId != (this as any).props.quizData.id) {
                (this as any).props.dispatch(quizStateActions.init((this as any).props.quizId))
            }

            if (this.props.colorTheme) {
                (this as any).props.initQuizModule();
            }

        }, componentWillReceiveProps(this: any, nextProps: any) {
            if (this.props.colorTheme !== nextProps.colorTheme || this.props.language !== nextProps.language) {
                (this as any).props.initQuizModule();
            }

        }
    })
)(View);
