import {compose, lifecycle, withHandlers, withState} from 'recompose';
import { connect } from 'react-redux';
import View from './View';
import {updateQuizData, addResult} from "../../../../_actions";
export default compose(
    connect(
        (state) => {
            const quizData = state.quizData.toJS();
            return {
                quizData: quizData,
                color: quizData.info.design.colors.main,
                results: quizData.info.results,
                contacts: quizData.info.form.contacts,
                hasExtra: quizData.info.form.extra,
                isNewTheme: quizData.isNewTheme,
                isQuizBot: quizData.isQuizBot
            }
        },
        {updateQuizData, addResult}
    ),
    withState('branchResultIndex', 'setBranchResultIndexHandler', (props) => null),
   withHandlers({

        changeEnableResult: props => () => {
            let quizData = props.quizData;
            if (!quizData.info.results) {
                quizData.info.results = {enabled: false, items: [{
                        value: 'Предложение',
                        image: null,
                        key: 1
                    }]};
            }
            quizData.info.results.enabled = !quizData.info.results.enabled;
            props.updateQuizData(quizData);
        },
       changeAfterQuestion: props => () => {
           let quizData = props.quizData;
           quizData.info.results.afterQuestion = !quizData.info.results.afterQuestion;
           props.updateQuizData(quizData);
       },
       addResultItemHandler: props => () => {

           let resultData = {
               value: 'Предложение',
               image: null
           };
           props.addResult(resultData);
       }
    }),
    lifecycle({
        componentWillReceiveProps(nextProps) {

        }
    })
)(View);

