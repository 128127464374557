import {createAction} from 'redux-act'

export * from './quizState.actions';
export * from './user.actions';
export * from './quizzes.actions';
export * from './quizData.actions';
export * from './question.actions';
export * from './answer.actions';
export * from './result.actions';

export const setModalComponent = createAction('SET_MODAL_COMPONENT')
export const setUserInfo = createAction('SET_USER_INFO')

export const cpStartLoading = createAction('CP_START_LOADING')
export const cpCompleteLoading = createAction('CP_COMPLETE_LOADING')
export const cpSetShowModalAdditionalUser = createAction('CP_SET_SHOW_ADDITIONAL_USER')


export const cpAddError = createAction('CP_ADD_ERROR')
export const cpResetError = createAction('CP_RESET_ERROR')

export const cpAddMessage = createAction('CP_ADD_MESSAGE')
export const cpResetMessage = createAction('CP_RESET_MESSAGE')

export const cpSetIsEditQuizRoute = createAction('CP_SET_IS_EDIT_QUIZ_ROUTE',  (data) => data)
export const cpSetIsAnswersQuizRoute = createAction('CP_SET_IS_ANSWERS_QUIZ_ROUTE')

export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';

export const signOutSuccess = createAction(SIGN_OUT_SUCCESS)

export const setQuizData = createAction('SET_QUIZ_DATA')

export const setQuizzesList = createAction('SET_QUIZZES_LIST')
export const setOneToQuizzesList = createAction('SET_ONE_TO_QUIZZES_LIST')
export const deleteOneQuizzesList = createAction('DELETE_ONE_QUIZZES_LIST')


export const deleteQuizAnswer = createAction('DELETE_QUIZ_ANSWER',  (quizId, answerId) =>
    ({quizId, answerId})
)
export const setQuizAnswers = createAction('SET_QUIZ_ANSWERS',  (quizId, answers, meta) =>
    ({quizId, answers, meta})
)


