import React from "react";
import {
    FormControl,
    FormControlLabel,
    InputLabel,
    Link,
    Radio,
    RadioGroup,
    Switch,
    TextField
} from "@material-ui/core";
import {t} from "leadformsquizentities/translate";

const View = ({
                  changeAdmitadData,
                  admitadData,
                  changeNotificationCheckedHandler,
                  showAdditional
              }) => (
    <div>
        <FormControlLabel
            control={
                <Switch
                    checked={admitadData && admitadData.enabled}
                    onChange={changeNotificationCheckedHandler}
                />
            }
            label={t("Admitad")}
        />
        <Link target="_blanc" href="https://leadforms.ru/instructions/admitad">Смотреть инструкцию</Link>
        {admitadData && admitadData.enabled &&
        <div>
            <TextField
                margin="normal"
                required
                fullWidth
                value={admitadData.campaign_code}
                placeholder="campaign_code"
                onChange={e => changeAdmitadData("campaign_code", e.target.value)}
                variant="filled"
                helperText="Код программы  (Пример: 127765c902)"
            />
            <TextField
                margin="normal"
                required
                fullWidth
                value={admitadData.tariff_code}
                placeholder="tariff_code"
                onChange={e => changeAdmitadData("tariff_code", e.target.value)}
                variant="filled"
                helperText="Код тарифа (Пример: 1)"
            />
            <TextField
                margin="normal"
                required
                fullWidth
                value={admitadData.postback_key}
                placeholder="postback_key"
                onChange={e => changeAdmitadData("postback_key", e.target.value)}
                variant="filled"
                helperText="Ключ идентификации (Пример: 99ECF3C036f88026c20E1F92ed9D559a)"
            />
            <TextField
                margin="normal"
                required
                fullWidth
                value={admitadData.action_code}
                placeholder="action_code"
                onChange={e => changeAdmitadData("action_code", e.target.value)}
                variant="filled"
                helperText="Код целевого действия (Пример: 1)"
            />
            <br/><br/>
            {showAdditional &&
            <div>
                <hr/>
                <FormControl>
                    <InputLabel shrink>Тип отправки</InputLabel>
                    <br/>
                    <RadioGroup row value={admitadData.isDeal ? true : false}>
                        <FormControlLabel value={true} control={<Radio/>} label="сделка"
                                          onChange={() => changeAdmitadData("isDeal", true)}/>
                        <FormControlLabel value={false} control={<Radio/>} label="лид"
                                          onChange={() => changeAdmitadData("isDeal", false)}/>
                    </RadioGroup>
                </FormControl>
                <TextField
                    margin="normal"
                    fullWidth
                    value={admitadData.title}
                    placeholder="Заголовок"
                    onChange={e => changeAdmitadData("title", e.target.value)}
                    variant="filled"
                    helperText="Необязательный параметр. Заголовок лида (Заявка с LeadForms)"
                />
                <br/><br/><br/>
            </div>}
        </div>
        }
    </div>
)


export default (View);
