import {compose, withHandlers, withState} from 'recompose';

import View from './View';
import connect from "react-redux/es/connect/connect";
import {updateQuizData} from "../../../../../_actions/index";

export default compose(
    connect(
        (state) => ({
            quizData: state.quizData.toJS(),
            text: state.quizData.toJS().info.startPage.pageTitle
        }),
        {updateQuizData}),
    withState('isEdit', 'changeIsEdit', false),
    withHandlers({
        changeIsEditHandler: props => () => {
            props.changeIsEdit(true);
        },
        changeTextHandler:  props => (value) => {
            let quizData = props.quizData;
            quizData.info.startPage.pageTitle = value;
            props.updateQuizData(quizData);
            props.changeIsEdit(false);
        }
    })
)(View);


