import {store} from "./index";

export const getQuestionById = (questionId) => {
    let questions = [];
    store.getState().quizData.toJS().questions.forEach((question) => {
        questions[question.id] = question;
    });
    return questions[questionId] ? questions[questionId] : null;
}

export const getQuestionIndexById = (questionId) => {
    let questionIndexes = [];
    store.getState().quizData.toJS().questions.forEach((question, questionIndex) => {
        questionIndexes[question.id] = questionIndex;
    });
    return questionIndexes[questionId] !== undefined ? questionIndexes[questionId] : null;
}



