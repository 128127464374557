//prod
export const config = {
    apiUrl: 'https://api.leadforms.ru/v1',
    apiOrigin: 'https://api.leadforms.ru',
    scriptUrl: 'https://script.leadforms.ru',
    quizUrl: 'https://quiz.leadforms.ru',
    quizHosts: ['quiz.leadforms.ru', 'localhost:3000'],
    ipCustomDomainQuiz: '45.12.19.92',
    proxyHostCustomDomainQuiz: 'proxy.leadforms.ru.',
    robokassa: {
        IsTest: false,
        mrh_login: "Leadforms.ru",
        price_base_1: 9,
        price_base_2: 7,
        price_unlimit_one: 900,
        price_unlimit_10: 2390,
        price_unlimit_all: 4990,
        price_whitelabel: 100
    },
    pusherKey:'40487c477a8f65c4a37b', //prod
    pusherAuthEndpoint: 'https://api.leadforms.ru/broadcasting/auth', //prod,
    amoCrmClientId: '98081bbd-89a2-4834-a4a5-7c73564bfb38'

};

// //dev
// export const config = {
//     apiUrl: 'https://dev-api.leadforms.ru/v1',
//     scriptUrl: 'https://dev-script.leadforms.ru',
//     apiOrigin: 'https://dev-api.leadforms.ru',
//     quizUrl: 'https://dev-quiz.leadforms.ru',
//     quizHosts: ['dev-quiz.leadforms.ru', 'localhost:3000'],
//     ipCustomDomainQuiz: '45.84.227.138',
//     proxyHostCustomDomainQuiz: 'proxy.leadforms.ru.',
//     robokassa: {
//         IsTest: false,
//         mrh_login: "leadformsru",
//         price_base_1: 8,
//         price_base_2: 5,
//         price_unlimit_one: 800,
//         price_unlimit_all: 2000,
//         price_whitelabel: 100
//     },
//     pusherKey:'40487c477a8f65c4a37b',
//     pusherAuthEndpoint: 'https://dev-api.leadforms.ru/broadcasting/auth',
//     amoCrmClientId: '98081bbd-89a2-4834-a4a5-7c73564bfb38'
// };
