import React from "react";
import {
    Grid,
    Card,
    CardContent,
    AppBar,
    Tabs,
    Tab,
    withStyles
} from "@material-ui/core";


const View = ({tabActive, quizData, changeTabActiveHandler, copyToClipboard, classes}) => {
    const Component = require(`./components/${tabActive}`).default
    return (

        <Grid container item lg={10} md={12}>

        <Card className={classes.container}>
            <AppBar position="static">
                <Tabs value={tabActive} onChange={changeTabActiveHandler}>
                    <Tab value="Site" label="На сайт"  />
                    <Tab value="Link" label="Прямая ссылка" />
                </Tabs>
            </AppBar>
          <CardContent>
            <Component quizData={quizData} copyToClipboard={copyToClipboard} quizId={quizData.id}/>
        </CardContent>
        </Card>
    </Grid>
)};


const styles = theme => ({
    container: {
        marginTop: 20,
        width: '100%'
    }
});
export default withStyles(styles)(View);