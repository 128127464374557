import React from "react";
import {

    Typography,
    FormControl,
    Button,
    FormLabel,
    FormGroup,
    FormControlLabel,
    withStyles, Grid

} from "@material-ui/core";

import {

    Delete as DeleteIcon

} from "@material-ui/icons";

const image1 = require(`../../../../img/instruction_telegram/1.png`)
const image3 = require(`../../../../img/instruction_telegram/3.png`)

const View = ({
                  removeTelegramAccount,
                  getСodeTelegramForNotify,
                  telegramAccounts,
                  сodeTelegramForNotify,
                  timer,
                  classes
              }) =>
        <Grid container item lg={8} md={10} sm={12} xs={12}>
            <Grid item xs={6}>
                <FormControl>
                    <FormLabel component="legend">Уведомление через Telegram</FormLabel>
                    <br/>
                    <br/>
                    {telegramAccounts.length > 0 &&
                    <FormGroup>
                        {telegramAccounts.map((telegramAccount) =>
                            <FormControlLabel
                                control={<DeleteIcon className={classes.deleteIcon}
                                                     onClick={removeTelegramAccount.bind(this, telegramAccount.id)}/>}
                                label={telegramAccount.username ? telegramAccount.username : `${telegramAccount.first_name} ${telegramAccount.last_name}`}
                            />
                        )}
                        <br/>
                        <br/>
                    </FormGroup>
                    }
                    {(сodeTelegramForNotify && timer) &&
                    <Typography>
                        <Typography> Ваш код: <strong>{сodeTelegramForNotify}</strong></Typography>
                        <small> Код истекает через  <strong>{timer}</strong> секунд</small>
                    </Typography>
                    }
                    {!сodeTelegramForNotify &&
                    <Button className={classes.button} variant="contained" onClick={getСodeTelegramForNotify}>получить код</Button>
                    }

                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormLabel component="legend">Инструкция</FormLabel>
                <br/>
                <Typography>
                    <ol>
                        <li>Зайдите в Telegram</li>
                        <li>Найдите и подпишитесь на бота <strong><a href={'tg://resolve?domain=LeadFormsBot'}>@LeadFormsBot</a></strong> <img src={String(image1)}/><br/><br/></li>
                        <li>Нажмите на кнопку слева "ПОЛУЧИТЬ КОД" <br/><br/></li>
                        <li>Введите комманду <strong>YOUR_CODE</strong>, где YOUR_CODE - полученый вами код <br/><br/></li>
                        <li>При успешной подписке на уведомления квиза, вам придет сообщение "Уведомление на квиз #НОМЕР_КВИЗА успешно подключено" <img src={String(image3)}/></li>
                    </ol>
                </Typography>
            </Grid>
        </Grid>


const styles = theme => ({
    button: {
        marginLeft: 5
    },
    deleteIcon: {
        marginLeft: 12,
        marginRight: 12
    }
});

export default withStyles(styles)(View);