import React from "react";
import {FormControlLabel, Switch, TextField, Button, Typography, Link} from "@material-ui/core";
import {t} from "leadformsquizentities/translate";
import NotificationComponent  from "../../../../components/Notification";
const View = ({
                  disabledHandler,
                    data,
                  changeDataHandler,
                  saveDataHandler,
                  successSave
}) => { 

    return (

    <div>

        <FormControlLabel
            control={
                <Switch
                    checked={true}
                    onChange={disabledHandler}
                />
            }
            label={t("Roistat")}
        />
        <Link target="_blanc" href="https://leadforms.ru/instructions/roistat">Смотреть инструкцию</Link>
            <TextField
                    fullWidth
                    onChange={changeDataHandler} 
                    value={data} 
                    placeholder="Roistat Id"
                    InputProps={{
                        endAdornment: (<Button onClick={saveDataHandler}>сохранить</Button>)
                    }}
                />

        {successSave &&
        <NotificationComponent  shadowless type="feedback" message="Сохранено" variant="rounded" color="success"/>
        }
                 <Typography>
                <small>Пример: 66512ae3c15afab804f8674122a9eec9</small><br/><br/>
                <small>
                    Подключите данную интеграцию только если вы используете квиз по прямой ссылке.

                    Если вы используете квиз как виджет для сайта, включите обратную интеграцию в кабинете Roistat и установите счётчик на вашем сайте. roistat_visit будет передаваться автоматически.
                </small>
                 </Typography>

        
    </div>
)}


export default (View);
