
import {compose, withHandlers} from 'recompose';

import View from './View';
import connect from "react-redux/es/connect/connect";
import {updateQuizData, uploadFavicon} from "../../../../../_actions";

export default compose(
    connect(
        (state) => ({
            favicon:  state.quizData.toJS().info.startPage.favicon
        }),
        {updateQuizData, uploadFavicon}
    ),
    withHandlers({
        handleFiles:  props => (e) => {
            e.preventDefault();
            let files = e.target.files;
            if (files.length) {
                props.uploadFavicon(files[0]);
            }
        }
    })
)(View);


