import React from "react";
import {t} from "leadformsquizentities/translate";
import {
    withStyles,
    Grid,
    Switch,
    FormControlLabel,
    Checkbox,
    Typography,
    Divider,
    Card,
    CardHeader, CardContent, CardActions
} from "@material-ui/core";
import FullScreenDialog from "../../../../components/FullScreenDialog";
import PhoneMaskSettings from './PhoneMaskSettings';
import QuizContactContainer from './QuizContactContainer';
import classnames from "classnames";

const View = ({
                  changeEnabledContactFieldHandler,
                  changeRequiredContactFieldHandler,
                  color,
                  contacts,
                  hasExtra,
                  hasAdditionalText,
                  changeExtraHandler,
                  changeAdditionalTextHandler,
                  isOpenPhoneMaskSettings,
                  changeOpenPhoneMaskSettings,
                  changePhoneMaskEnabled,
                  phoneMask,
                  enabled,
                  changeEnableContact,
                  classes,
                  isQuizBot
              }) => (

    (enabled) ?

        <Grid
            justify="center"
            container
            alignItems="stretch"
        >
            <Card className={classes.cardStartPageDisabled}>
                <CardHeader title="Контакты"/>
                <CardContent>
                    <Typography variant="body2" component="p">
                    </Typography>
                </CardContent>
                <CardActions>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={false}
                                onChange={changeEnableContact}
                            />
                        }
                        label={'Включить'}
                    />
                </CardActions>
            </Card>
        </Grid>
        :
        <Grid justify="center" container>
            <Grid className={classnames(classes.content, isQuizBot && 'quiz_bot')}>
            <QuizContactContainer/>
        </Grid>
        <Grid
            container
            direction="column"
            justify="space-between"
            className={classes.rightPanel}
        >
            <Grid>
                <FormControlLabel fullWidth
                                  control={
                                      <Switch
                                          classes={{
                                              root: classes.root,
                                              switchBase: classes.switchBase,
                                              thumb: classes.thumb,
                                              track: classes.track,
                                              checked: classes.checked,
                                          }}
                                          checked={true}
                                          onChange={changeEnableContact}
                                      />
                                  }
                                  label={'Показывать страницу с контактами'}
                />
                <br/><br/>
                {Object.keys(contacts).map((keyContact) => {
                    const contact = contacts[keyContact];
                    return (
                        <>
                            <FormControlLabel
                                key={keyContact}
                                control={
                                    <Switch
                                        checked={contact.enabled}
                                        onChange={changeEnabledContactFieldHandler.bind(this, keyContact)}
                                    />
                                }
                                label={t(contact.name)}
                            />
                            {contact.enabled && <FormControlLabel className={classes.checkboxControl}
                                                                  control={
                                                                      <Checkbox className={classes.checkbox}
                                                                                color="primary"
                                                                                checked={undefined === contact.required || contact.required === true}
                                                                                onChange={changeRequiredContactFieldHandler.bind(this, keyContact)}/>
                                                                  }
                                                                  label={<Typography
                                                                      variant="caption">Обязательное</Typography>}
                            />}
                            {('phone' === keyContact && contact.enabled) &&
                            <Typography
                                noWrap
                                onClick={() => {
                                    changeOpenPhoneMaskSettings(true)
                                }}
                                variant={'subtitle2'}
                                style={{cursor: 'pointer'}}
                                color={'primary'}
                            >
                                Настройки маски
                            </Typography>
                            }
                            <Divider light/>
                        </>
                    )
                })}
            </Grid>
            <Grid>
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasAdditionalText}
                            onChange={changeAdditionalTextHandler}
                        />
                    }
                    label="Заголовок"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasExtra}
                            onChange={changeExtraHandler}
                        />
                    }
                    label="Бонус"
                />
            </Grid>
        </Grid>
        {isOpenPhoneMaskSettings &&
        <FullScreenDialog
            modalTitle={
                <FormControlLabel
                    control={
                        <Switch
                            checked={phoneMask.enabled}
                            onChange={() => changePhoneMaskEnabled()}
                        />
                    }
                    label={"Маска для телефона"}
                />
            }
            handleClose={() => changeOpenPhoneMaskSettings(false)}
        >
            <PhoneMaskSettings/>
        </FullScreenDialog>
        }
    </Grid>


);

const styles = theme => ({
    content: {
        maxWidth: 1024,
        width: '100%',
        '@media(min-width: 1201px)': {
            width: 'calc(100% - 150px)',
        },
        '&.quiz_bot': {
            width: 'auto'
        }
    },
    rightPanel: {
        padding: 10,
        width: '100%',
        '@media(min-width: 1201px)': {
            width: 150
        },
        '& span,p': {
            fontSize: '13px !important'
        }
    },
    checkbox: {
        paddingTop: 0,
        paddingBottom: 0
    },
    checkboxControl: {
        marginTop: -10
    }
});

export default withStyles(styles)(View);
