import React from 'react';
import { Grid, withStyles, Paper, Link } from '@material-ui/core';
import PageTitle from '../../components/PageTitle';
import { Typography } from '../../components/Wrappers';

const Support = ({classes, ...props}) => (
  <React.Fragment>
      <Grid justify="center" container>
          <Grid container item lg={8} md={8} sm={10} xs={12}>
              <PageTitle title="Справка"/>
              <Paper  className={classes.block}>
                  <Typography className={classes.subTitle} color="textSecondary">
                      Как добавить LeadForms на сайт?
                  </Typography>
                  <Typography><b>1.</b>Скопируйте код LeadForms<br/>Создайте LeadForms и на посленем шаге скопируйте html код из конструктора.<br/><br/><b>2.</b> Вставьте код LeadForms на сайт<br/> Скопированный код из конструктора нужно вставить на вашем сайте в том месте, где должна быть кнопка.<br/><br/><b>3.</b> Хотите использовать свою кнопку?<br/> В разделе “Установка” выберете пункт “Своя кнопка” и скопируйте ссылку "#popup:leadforms_xx" (где xx - ваш уникальный номер квиза). <br/> Тогда при её нажатии будет открываться LeadForms.</Typography>
                  <br/><br/><Link href="https://leadforms.ru/instructions/branch">Как настроить ветвление вопросов</Link>
              </Paper>
              <Paper  className={classes.block} >
                  <Typography className={classes.subTitle}  color="textSecondary">
                      Как добавить LeadForms на конструкторах?
                  </Typography>
                  <Link href="https://leadforms.ru/instructions/tilda">Как добавить квиз в сайт на Тильде</Link><br/><br/>
                  <Link href="https://leadforms.ru/instructions/platformalp">Как добавить квиз в сайт на PlatformaLP</Link><br/><br/>
                  <Link href="https://leadforms.ru/instructions/bmbullet">Как добавить квиз в сайт на БМ.Пуля</Link><br/><br/>
                  <Link href="https://leadforms.ru/instructions/lpmotor">Как добавить квиз в сайт на LP Motor</Link>
              </Paper>
              <Paper className={classes.block}>
                  <Typography className={classes.subTitle} color="textSecondary">
                      Как добавить LeadForms в аналитику
                  </Typography>
                  <Link href="https://leadforms.ru/instructions/yandexmetrika">Как установить Яндекс.Метрику в
                      квиз?</Link><br/><br/>
                  <Link href="https://leadforms.ru/instructions/googleanalytics">Как установить Google Analytics
                      в квиз?</Link>
              </Paper>
              <Paper className={classes.block}>
                  <Typography className={classes.subTitle}  color="textSecondary">
                      Интеграция с сервисами
                  </Typography>
                  <Link href="https://leadforms.ru/instructions/bitrix24">Как интегрировать квиз с Bitrix 24?</Link><br/><br/>
                  <Link href="https://leadforms.ru/instructions/amocrm">Как интегрировать квиз с AmoCRM?</Link><br/><br/>
                  <Link href="https://leadforms.ru/instructions/admitad">Как интегрировать квиз с Admitad?</Link><br/><br/>
                  <Link href="https://leadforms.ru/instructions/webhooks">Как интегрировать квиз через WbHooks?</Link>
              </Paper>
        </Grid>
      </Grid>
  </React.Fragment>
);

const styles = theme => ({
    block: {
        width: '100%',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    subTitle: {
        marginBottom: theme.spacing(1)
    }
});

export default  withStyles(styles)(Support);