
import { compose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import View from './View';
import {updateQuizData} from "../../../../../_actions";
import React from "react";


export default compose(
    connect(
        state => ({
            quizData: state.quizData.toJS(),
            language: state.quizData.toJS().info.language ? state.quizData.toJS().info.language : "russian",
            notAutoNextStep: state.quizData.toJS().notAutoNextStep
        }),
        { updateQuizData }
    ),
    withState("name", "setName", props => props.quizData.info.name),
    withHandlers({
        onChangeLanguage: props => (e) => {
            let quizData = props.quizData
            quizData.info.language = e.target.value
            props.updateQuizData(props.quizData);
        },
        onChangeNotAutoNextStep: props => (e) => {
            let quizData = props.quizData
            quizData.notAutoNextStep = !quizData.notAutoNextStep
            props.updateQuizData(props.quizData);
        },
        onChangeNameQuiz: props => (e) => {
            props.setName(e.target.value)
            let quizData = props.quizData
            quizData.info.name = e.target.value ? e.target.value : 'Без названия'
            props.updateQuizData(props.quizData);
        }

    })


)(View);
