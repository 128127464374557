import {QuizQuestion, QuizContact} from "newkitleadforms";
import React from "react";
import {connect} from "react-redux";
import {ContactInterface} from "leadformsquizentities/Contact";
import {ContactType} from "leadformsquizentities/Contacts/Contact";
import {store} from "../../../../_services";
import {Contacts as ContactsOld, editHandler} from "oldkitleadforms";
import LayoutAdmin from "../LayoutAdmin";
import QuizContainer from "../QuizContainer";
import {t} from "leadformsquizentities/translate";
import {updateQuizData} from "../../../../_actions";
import {PhoneCountriesInterface} from "leadformsquizentities/Contacts/PhoneMask";

const getDiscount = () => {
    const quizState = store.getState().quizState;
    const quizData = store.getState().quizData.toJS();

    if (quizData.info.discount) {
        if (quizData.info.discount.type === 'melting') {
            const currency = quizData.info.discount.currency ? quizData.info.discount.currency : "₽";
            return {saleAmount: {value: `${quizState.discount}`, mark: currency}};
        }
        if (quizData.info.discount.type === 'increasing') {
            const currency = quizData.info.discount.currency ? quizData.info.discount.currency : "₽";
            return {saleAmount: {value: `${quizState.discount}`, mark: currency}};
        }
        if (quizData.info.discount.type === 'increasingPercent') {
            return {saleAmount: {value: `${quizState.discount}`, mark: '%'}};
        }
    }

    return undefined;
}

const View = ({isQuizBot, whitelabel, quizTitle, countQuestion, activeQuestionNumber, progress, colorTheme, isNewTheme, dataContact, buttonNextCaption}: {isQuizBot: boolean, whitelabel: boolean,quizTitle: string, countQuestion: number, activeQuestionNumber: number,progress: number, colorTheme: string, isNewTheme: boolean, dataContact: ContactInterface, buttonNextCaption: string }) => {
    if (!isNewTheme) {
        return <LayoutAdmin><div style={{background: 'white', width: '100%', height: '100%'}}><QuizContainer color={colorTheme}><ContactsOld
            data={dataContact as ContactInterface}
            buttonNextCaption={buttonNextCaption}
            nextQuestionHandler={()=>{}}
            isAdmin
            changeHandler={(answerKey: ContactType | 'privacyAgreement', value: string|boolean) => {}}

        /></QuizContainer></div></LayoutAdmin>;
    }
    return (<div style={{background: 'white', width: '100%', borderRadius: 6, overflow: 'hidden', maxWidth: isQuizBot ? 375 : 'none', height: isQuizBot ? 550 : '100%', margin: 'auto'}}>
        <QuizContact
            data={dataContact}
            nextQuestionHandler={()=>{}}
            prevQuestionHandler={()=>{}}
            buttonNextCaption={buttonNextCaption}
            isAdmin={true}
            serialQuestionNumber={activeQuestionNumber}
            countQuestion={countQuestion}
            progress={progress}
            isDisabledPrevButton={false}
            isDisabledNextButton={false}
            whitelabel={whitelabel}
            quizTitle={quizTitle}
        />
    </div>);
}


const mapStateToProps = (state: any) => {
    const quizState = state.quizState;
    const quizData = state.quizData.toJS();
    const isNewTheme = quizData.isNewTheme;

    const progress = Math.round(100 * quizData.questions.length / (quizData.questions.length + (isNewTheme ? 1 : 0)));
    const colorTheme = true !== quizData.isEmpty ? quizData.info.design.colors.main : '';

    let countryData = undefined;
    const phoneData = quizData.info.form.contacts.phone;

    if (undefined !== phoneData.mask && true === phoneData.mask.enabled) {
        if ('country' === phoneData.mask.type) {
            const phoneMaskCountries: PhoneCountriesInterface = phoneData.mask.phoneMaskCountries;
            const onlyCountries = 0 < phoneMaskCountries.only.length ? [...phoneMaskCountries.only, ...phoneMaskCountries.preferred, phoneMaskCountries.default] : [];
            const excludeCountries = phoneMaskCountries.ignored.some((v: string) => [...onlyCountries, phoneMaskCountries.default].includes(v)) === false ? phoneMaskCountries.ignored : [];
            const preferredCountries = 0 === excludeCountries.length ? phoneMaskCountries.preferred : phoneMaskCountries.preferred.filter((x: string) => !excludeCountries.includes(x));

            countryData = {
                defaultCountry: phoneMaskCountries.default,
                onlyCountries: onlyCountries,
                excludeCountries: excludeCountries,
                preferredCountries: preferredCountries
            };
        }
    }

    const dataContact: ContactInterface = {
        email: quizData.info.form.contacts.email.enabled ? {
            value: quizState.clientData.contacts.email,
            errorMessage: quizState.contactsValidate['email']
        } : undefined,
        phone: phoneData.enabled ? {
            value: quizState.clientData.contacts.phone,
            errorMessage: quizState.contactsValidate['phone'],
            maskRegexpArray: undefined,
            countryData: countryData
        } : undefined,
        name: quizData.info.form.contacts.name.enabled ? {
            value: quizState.clientData.contacts.name,
            errorMessage: quizState.contactsValidate['name']
        } : undefined,
        privacyAgreement: true,
        sale: getDiscount(),
        bonus: quizData.info.form.extra ? quizData.info.form.extra : undefined,
        text:  quizData.info.form.text ? quizData.info.form.text : undefined,
        title: quizData.info.form.contactsTitle ? quizData.info.form.contactsTitle : t('Последний шаг!')
    }

    return {
        colorTheme: colorTheme,
        dataContact: dataContact,
        buttonNextCaption: quizData.info.form.button,
        isNewTheme: isNewTheme,
        progress: progress,
        activeQuestionNumber: quizData.questions.length + 1,
        countQuestion: quizData.questions.length + 1,
        quizTitle: quizData.info.title,
        whitelabel: !!quizData.whitelabel,
        isQuizBot: quizData.isQuizBot
    };
};


export default connect(
    mapStateToProps,
)(View);
