import React from "react";
import {FormControlLabel, Switch, TextField, Button, Typography, Link} from "@material-ui/core";
import {t} from "leadformsquizentities/translate";
import NotificationComponent  from "../../../../components/Notification";
const View = ({
                  disabledHandler,
                    data,
                  changeDataHandler,
                  saveDataHandler,
                  enabled,
                  instructionData,
                  closeInstructionHandler,
                  openInstructionHandler,
                  successSave


}) => { 

    return (

    <div>

        <FormControlLabel
            control={
                <Switch
                    checked={true}
                    onChange={disabledHandler}
                />
            }
            label={t("Facebook пиксель")}
        />
         <Link target="_blanc" href="https://leadforms.ru/instructions/facebook">Смотреть инструкцию</Link>

            <TextField
                    fullWidth
                    onChange={changeDataHandler} 
                    value={data} 
                    placeholder="ID ПИКСЕЛЯ"
                    InputProps={{
                        endAdornment: (<Button onClick={saveDataHandler}>сохранить</Button>)
                    }}
                />

        {successSave &&
        <NotificationComponent  shadowless type="feedback" message="Сохранено" variant="rounded" color="success"/>
        }
                 <Typography>
                <small>Пример: 2476293663219191</small><br/><br/>
                <small>
                    Leadforms отправляет следующие события:
                    <br/> - <strong>Посетитель открыл квиз</strong> тип события: "Просмотр контента" (ViewContent), content_name: leadforms_open
                    <br/> - <strong>Посетитель нажал на кнопку стартовой страницы</strong> тип события: "Просмотр контента" (ViewContent), content_name: leadforms_start
                    <br/> - <strong>Посетитель прошёл вопрос N</strong> тип события: "Просмотр контента" (ViewContent), content_name: leadforms_stepN
                    <br/> - <strong>Посетитель оставил контакты</strong> тип события: "Контакт" (Contact), content_name: leadforms_finish
                </small>
                 </Typography>

        
    </div>
)}


export default (View);
