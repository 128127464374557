import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import {cpReducer} from '../_reducers';
import {quizStateSubscribers}  from '../_subscribers';

export const store = createStore(
    cpReducer,
    applyMiddleware(
        thunkMiddleware
    )
);

store.subscribe(quizStateSubscribers.handlerChangeForm.bind(undefined, store))
store.subscribe(quizStateSubscribers.handlerChangeProgress.bind(undefined, store))
store.subscribe(quizStateSubscribers.handlerSendClientForm.bind(undefined, store, true))
store.subscribe(quizStateSubscribers.handlerChangeDiscountIncreasing.bind(undefined, store))
store.subscribe(quizStateSubscribers.handlerChangeDiscountOneStep.bind(undefined, store))
