import React from "react";
import {
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Input,
    Button,
    Chip

} from "@material-ui/core";

import {config} from "../../../../_services";
const View = ({
    changeCountLeadsHandler,
    countLeads,
    payHandler,
    MrchLogin,
    SignatureValue,
    InvoiceID,
    OutSum,
    isTest,
    onRef

}) =>
    <Typography component="div">
        <form action="https://merchant.roboxchange.com/Index.aspx" hidden method="post"
              ref={onRef}>
            <input name="MrchLogin" type="hidden" value={MrchLogin}/>
            <input name="SignatureValue" type="hidden" value={SignatureValue}/>
            <input name="InvoiceID" type="hidden" value={InvoiceID}/>
            <input name="OutSum" type="hidden" value={OutSum}/>
            {isTest &&
            <input name="IsTest" type="hidden" value="1"/>
            }
        </form>
        <Typography>
            Оплата за каждую заявку<br/><br/>
            {config.robokassa.price_base_1}₽ / 1 заявка<br/>
            {config.robokassa.price_base_2}₽ / 1 заявка при покупке от 100 штук
        </Typography>

        <FormControl  fullWidth>
            <InputLabel>Количество заявок</InputLabel>
            <Input type="number" value={countLeads} onChange={changeCountLeadsHandler} placeholder="Введите количество заявок"/>
        </FormControl>
        <Grid
            container
            direction="row"
            justify="space-between"
        >
            <Grid item>
                <Chip label={`${OutSum}₽, ${countLeads} заявок`}/>
            </Grid>
            <Grid item>
                <Button onClick={payHandler} variant="contained">
                    ОПЛАТИТЬ
                </Button>
            </Grid>
        </Grid>
    </Typography>
;



export default View;