import React from "react";
import {t} from "leadformsquizentities/translate";
import Input from '../../../../../components/Input'
import EditLabel from '../../../../../components/EditLabel/View';
import {Typography, withStyles} from "@material-ui/core";

const View = ({text, isEdit, changeIsEditHandler, changeTextHandler, classes}) => (
    (text || isEdit) ?
        <Typography className={classes.name} >
            {isEdit && <Input maxlength="50" onChangeHandler={changeTextHandler}  value={text} placeholder={t('Title cnhfybws')}/>}
            {!isEdit && <span onClick={changeIsEditHandler}>{text}<EditLabel/></span>}
        </Typography>
    : null

);


const styles = theme => ({
    name: {
        fontSize: 16,
        fontWeight: 500,
        paddingLeft: 25,
        width: '100%',
        maxWidth: 'calc(100% - 150px)',
        marginTop: 'auto',
        marginBottom: 'auto',
        '& span':{
          position: 'relative'  
        }
        
    }
});
export default withStyles(styles)(View);
