import { authHeader } from '../_services';
import {requestApi, requestApiNoLogout} from "./requestApi";

export const userService = {
    login,
    getUserInfo,
    createUser,
    activateProgram,
    updateUser,
    requestResetPassword,
    resetPassword,
    findQuizByDomain,
    attachPromoCode
};

function updateUser(newUserInfo) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(newUserInfo),
    };
    return requestApi(`users/${newUserInfo.id}`, requestOptions);
}

function activateProgram(quizId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader({'Content-Type': 'application/json'}),
        body: JSON.stringify({ quizId: quizId })
    };
    return requestApi(`users/activate_program`, requestOptions);
}

function resetPassword(password, token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify({ newPassword: password })
    };
    return requestApi(`users/reset-password`, requestOptions);
}

function requestResetPassword(username) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email:username })
    };
    return requestApi(`users/reset`, requestOptions);
}

function createUser(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email:username, password:password })
    };
    return requestApiNoLogout(`users`, requestOptions);
}


function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email:username, password:password })
    };
    return requestApiNoLogout(`users/login`, requestOptions);
}

function attachPromoCode(promocode) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ promocode: promocode})
    };
    return requestApi(`users/attach_promo_code`, requestOptions);
}

function getUserInfo() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return requestApi(`users/whoame`, requestOptions);
}

function findQuizByDomain(domain) {
    const requestOptions = {
        method: 'GET'
    };
    return requestApiNoLogout(`get_quiz_by_domain/${domain}`, requestOptions);
}