import React from "react";
import {t} from "leadformsquizentities/translate";
import {withStyles, Grid, Switch, FormControlLabel, Input} from "@material-ui/core";
import ToolTip from "../../../../components/ToolTip";
import QuizFinishContainer from './QuizFinishContainer'
import classnames from "classnames";

const View = ({
    changeThanksHandler,
    hasThanks,
    hasRedirect,
    changeHasRedirectHandler,
    changeRedirectHandler,
    redirect,
    isQuizBot,
    classes
}) => (
    <Grid justify="center" container>
        <Grid className={classnames(classes.content, isQuizBot && 'quiz_bot')}>

                    <FormControlLabel
                        control={
                              <Switch
                                checked={hasRedirect}
                                onChange={changeHasRedirectHandler}
                              />
                            }
                        label={["Редирект", <ToolTip keyItem={'contactRedirect'}/>]}
                    /> 
       
                {hasRedirect ? 
                     <Input value={redirect} onChange={changeRedirectHandler} placeholder="https://example.com"/>
                    :
                    <QuizFinishContainer/>
                }
            </Grid>
            {!hasRedirect && <Grid className={classes.rightPanel}>
                <FormControlLabel
                    control={
                      <Switch
                        checked={hasThanks}
                        onChange={changeThanksHandler}
                      />
                    }
                    label={t("Текст после отправки")}
                />
        </Grid>}
    </Grid>



);

const styles = theme => ({
    content:{
        maxWidth: 1024,
        width: '100%',
        '@media(min-width: 1201px)':{
            width: 'calc(100% - 150px)',
        },
        '&.quiz_bot': {
            width: 'auto'
        }
    },
    rightPanel:{
        padding: 10,
        width: '100%',
        '@media(min-width: 1201px)':{
            width: 150,
            marginTop: 20
        },
        '& span,p': {
            fontSize: '13px !important'
        }
    }
});
export default withStyles(styles)(View);
