import React from 'react';
import {
    Grid,
    CircularProgress,
    Typography,
    withStyles,
    Button,
    Tabs,
    Tab,
    TextField,
    Fade,
    Card
} from "@material-ui/core";
import Footer from "../../components/Footer";
import { Link } from 'react-router-dom';
import logo from "../../img/logo.png";


const View = ({classes, error, loading, email, success, handleSubmit, changeEmailhandler}) => (

    <Grid container className={classes.container}>

        <div className={classes.formContainer}>
            <Card className={classes.form}>

                <img src={logo} alt="logo" className={classes.logotypeImage}/>
                <Tabs
                    value={0}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Восстановление пароля" classes={classes.tab}/>
                </Tabs>
             
                    {!success && 
                    <React.Fragment>
                        <Fade in={error}>
                            <Typography color="secondary" className={classes.errorMessage}>
                                {error}
                            </Typography>
                        </Fade>
                        <TextField
                            InputProps={{
                                    classes: {
                                        underline: classes.textFieldUnderline,
                                        input: classes.textField
                                    }
                                }}
                            value={email} 
                            onChange={changeEmailhandler}   
                            margin="normal"
                            placeholder="Email"
                            type="email"
                            fullWidth
                        />
                       
                        <div className={classes.formButtons}>
                            {loading ? (
                                <CircularProgress size={26}/>
                            ) : (
                                <Button
                                    disabled={
                                            email.length === 0 
                                        }
                                    onClick={handleSubmit}
                                    variant="contained"
                                    color="primary"
                                    
                                    size="large"
                                >
                                    Сменить пароль
                                </Button> )}

                                <Button
                                    component={Link}  
                                    to="/login"
                                    variant="contained"
                                    size="large"
                                >
                                    Назад
                                </Button>


                        </div>
                        
                    </React.Fragment>}
                    {success && 
                            <Typography>
                                На Ваш email {email} отправлена ссылка для восстановления пароля
                            </Typography>
                    }
               
                
            </Card>
            <div className={classes.footerAbsolute}>
                <Footer/>
            </div>
        </div>
    </Grid>


);

const styles = theme => ({
     container: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0
    },
    logotypeContainer: {
        backgroundColor: theme.palette.primary.main,
        width: "60%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "50%"
        },
        [theme.breakpoints.down("md")]: {
            display: "none"
        }
    },
    logotypeImage: {
        width: 165,
        marginBottom: 32
    },
    logotypeText: {
        color: "white",
        fontWeight: 500,
        fontSize: 84,
        [theme.breakpoints.down("md")]: {
            fontSize: 48
        }
    },
    formContainer: {
        width: "40%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "50%"
        },
        textAlign: 'center'
    },
    form: {
        width: 420,
        padding: 50
    },
    tab: {
        fontWeight: 400,
        fontSize: 16
    },
    greeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(4)
    },
    subGreeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(2)
    },
    googleButton: {
        marginTop: theme.spacing(6),
        boxShadow: theme.customShadows.widget,
        backgroundColor: "white",
        width: "100%",
        textTransform: "none"
    },
    googleButtonCreating: {
        marginTop: 0
    },
    googleIcon: {
        width: 30,
        marginRight: theme.spacing(2)
    },
    creatingButtonContainer: {
        marginTop: theme.spacing(2.5),
        height: 46,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    createAccountButton: {
        height: 46,
        textTransform: "none"
    },
    formDividerContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: "flex",
        alignItems: "center"
    },
    formDividerWord: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    formDivider: {
        flexGrow: 1,
        height: 1,
        backgroundColor: theme.palette.text.hint + "40"
    },
    errorMessage: {
        textAlign: "center"
    },
    textFieldUnderline: {
        "&:before": {
            borderBottomColor: theme.palette.primary.light
        },
        "&:after": {
            borderBottomColor: theme.palette.primary.main
        },
        "&:hover:before": {
            borderBottomColor: `${theme.palette.primary.light} !important`
        }
    },
    textField: {
        borderBottomColor: theme.palette.background.light
    },
    formButtons: {
        width: "100%",
        marginTop: theme.spacing(4),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    forgetButton: {
        textTransform: "none",
        fontWeight: 400
    },
    loginLoader: {
        marginLeft: theme.spacing(4)
    },
    footerAbsolute: {
        position: "absolute",
        bottom: theme.spacing(2)
    }
})



export default withStyles(styles)(View);
