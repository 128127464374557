import { quizzesService } from '../_api';
import {
    setQuizData,
    cpStartLoading,
    cpCompleteLoading
} from './';
import {checkConditionIsDone} from "../_services/branchQuestionService";
import {isPossiblePhoneNumber} from "react-phone-number-input";
import {t} from "leadformsquizentities/translate";
import {initQuizSateAction, setQuizStateIem} from '../_reducers/quizState.reducer'

export const quizStateActions = {
    init,
    changeContactValue,
    changeAnswerClient,
    validateContactValue
};

export const nextStepIsLoading = () => (dispatch, getState) => {
    const quizState = getState().quizState
    dispatch(setQuizStateIem('nextStepLoading', true))
}

export const quizStatePrevStep = () => (dispatch, getState) => {
    const state = getState();
    const quizState = state.quizState;
    const quizData = state.quizData.toJS();
    const clientAnswers = quizState.clientData.answers;

    let value = null;
    for (let keyQuestion = quizData.questions.length; keyQuestion >=  0; keyQuestion--) {
        const question = quizData.questions[keyQuestion];
        if(quizState.activeKeyItem > keyQuestion) {
            if(question.displayConditions){
                if(checkConditionIsDone(question, clientAnswers)){
                    value = keyQuestion;
                    break;
                }
            }else{
                value = keyQuestion;
                break;
            }
        }
    }

    if(quizState.contacts && quizData.questions.length){
        dispatch(setQuizStateIem('contacts', false))
    }else if(quizState.finish){
        dispatch(setQuizStateIem('contacts', true))
        dispatch(setQuizStateIem('finish', false))
    }else{
        dispatch(setQuizStateIem('activeKeyItem', value))
    }
}


export const quizStateNextStep = () => (dispatch, getState) => {
    const state = getState()
    const quizState = state.quizState
    const quizData = state.quizData.toJS()
    const clientAnswers = quizState.clientData.answers

    if(quizState.finish)
        return;

    let value = null;
    for (let keyQuestion = 0; keyQuestion < quizData.questions.length; keyQuestion++) {
        const question = quizData.questions[keyQuestion];
        if(quizState.activeKeyItem < keyQuestion) {
            if(question.displayConditions){
                if(checkConditionIsDone(question, clientAnswers)){
                    value = keyQuestion;
                    break;
                }
            }else{
                value = keyQuestion;
                break;
            }
        }
    }
    dispatch(setQuizStateIem('nextStepLoading', false));
    if(quizState.start){
        dispatch(setQuizStateIem('start', false))
    }else if(quizState.contacts){
        dispatch(setQuizStateIem('finish', true))
        dispatch(setQuizStateIem('contacts', false))
        dispatch(setQuizStateIem('loading', true))
    }else if(value === null && !quizState.contacts && !quizState.finish){
        dispatch(setQuizStateIem('contacts', true))
    }else{
        dispatch(setQuizStateIem('activeKeyItem', value))
    }

}


function changeContactValue(keyContact, valueContact) {
    return (dispatch, getState) => {
        const state = getState()
        const quizState = state.quizState;
        const quizData = state.quizData.toJS();
        let newClientData = quizState.clientData

        newClientData.contacts[keyContact] = valueContact;
        dispatch(setQuizStateIem('clientData', newClientData));

        const errorText = quizState.contactsValidate[keyContact];

        if (null !== errorText) {
            dispatch(changeContactErrorMessage(keyContact, null));
        }

        let isPossibleSendClientForm = true;

        Object.keys(quizData.info.form.contacts).forEach((contactKey) => {
            const contactData = quizData.info.form.contacts[contactKey];

            if (true === contactData.enabled) {
                const contactValue = quizState.clientData.contacts[contactKey];
                let phoneMaskType;
                let phoneMaskRegexp;

                if (undefined !== contactData.mask && true === contactData.mask.enabled) {
                    phoneMaskType = contactData.mask.type;
                    if ('mask' === contactData.mask.type && typeof contactData.mask.regexp === 'string' && contactData.mask.regexp.length >= 1) {
                        phoneMaskRegexp = contactData.mask.regexp;
                    }
                }

                if (null !== validate(contactKey, contactData.required, contactValue, phoneMaskType, phoneMaskRegexp)) {
                    isPossibleSendClientForm = false;
                }
            }

        })

        if (isPossibleSendClientForm !== quizState.isPossibleSendClientForm) {
            dispatch(changeIsPossibleSendClientForm(isPossibleSendClientForm));
        }
    }
}

function changeContactErrorMessage(keyContact, errorMessage) {
    return (dispatch, getState) => {
        const state = getState()
        const quizState = state.quizState
        let contactsValidate = quizState.contactsValidate
        contactsValidate[keyContact] = errorMessage
        dispatch(setQuizStateIem('contactsValidate', contactsValidate))
    }
}

function validateContactValue(keyContact) {
    return (dispatch, getState) => {
        const state = getState()
        const quizState = state.quizState
        const quizData = state.quizData.toJS();

        let phoneMaskType;
        let phoneMaskRegexp;

        const contactData = quizData.info.form.contacts[keyContact];
        const contactValue = quizState.clientData.contacts[keyContact];

        if (undefined !== contactData.mask && true === contactData.mask.enabled) {
            phoneMaskType = contactData.mask.type;
            if ('mask' === contactData.mask.type && typeof contactData.mask.regexp === 'string' && contactData.mask.regexp.length >= 1) {
                phoneMaskRegexp = contactData.mask.regexp;
            }
        };

        const errorText = validate(keyContact, contactData.required, contactValue, phoneMaskType, phoneMaskRegexp);
        if (errorText !== keyContact) {
            dispatch(changeContactErrorMessage(keyContact, errorText));
        }

    }
}


const validate = (contactKey, isRequired, value, phoneMaskType, phoneMaskRegexp) => {
    if (undefined === value || 0 === value.length) {
        if (true === isRequired || undefined === isRequired) {
            return t('Поле обязательно для заполнения');
        }
    } else {
        switch (contactKey) {
            case "email":
                if (false === /\S+@\S+\.\S+/.test(value)) {
                    return t('Неправильный формат email');
                }
                break;
            case "phone":
                if(undefined !== phoneMaskType) {
                    if ('mask' === phoneMaskType && undefined !== phoneMaskRegexp && 0 < phoneMaskRegexp.length) {
                        const phonePattern = new RegExp(phoneMaskRegexp.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&").replace(/1/g, '\\d'));
                        if (false === phonePattern.test(value)) {
                            return t('Неправильный формат номера');
                        }
                    } else if ('country' === phoneMaskType) {
                        if (false === isPossiblePhoneNumber(value)) {
                            return t('Неправильный формат номера');
                        }
                    }
                }
        }
    }

    return null
}

function changeIsPossibleSendClientForm(isPossibleSendClientForm) {
    return (dispatch) => {
        dispatch(setQuizStateIem('isPossibleSendClientForm', isPossibleSendClientForm))
    }
}

function changeAnswerClient(questionId, answerKey, value) {
    return (dispatch, getState) => {
        const state = getState()
        const quizState = state.quizState
        const quizData = state.quizData.toJS()
        let newClientData = {
            answers: quizState.clientData.answers,
            contacts: quizState.clientData.contacts
        }
        let bufAnswers = ( undefined !== newClientData.answers[questionId]) ? newClientData.answers[questionId] : {}
        const questionActive = quizData.questions[quizState.activeKeyItem]
        if(!questionActive.multySelect && !(questionActive.sliderOptions && questionActive.sliderOptions.isMultiple)) bufAnswers = {}
        bufAnswers[answerKey] =  value
        let newAnswers = {}
        for (let index in bufAnswers){
            if(undefined !== bufAnswers[index] && null !== bufAnswers[index] && '' !== bufAnswers[index])
                newAnswers[index] = bufAnswers[index]
        }
        newClientData.answers[questionId] = newAnswers
        dispatch(setQuizStateIem('clientData', newClientData))
    }

}

function init(quizId) {
    return dispatch => {
        dispatch(cpStartLoading())
        quizzesService.getQuiz(quizId)
            .then(
                quizData => {
                    dispatch(setQuizData(quizData))
                    dispatch(initQuizSateAction(getQuizState(quizData)))
                    dispatch(cpCompleteLoading());
                    quizzesService.quizIsLoad(quizId);
                },
               // error => dispatch(failure(error))
            );
    };
}

const checkIsPossibleSendClientForm = (contacts) => {
    for (let [key, contact] of Object.entries(contacts)) {
        if (true === contact.enabled && (true === contact.required || undefined === contact.required)) {
            return false;
        }
    }

    return true;
} ;

function getQuizState(quizData) {
    return{
        loading: false,
        loadingImage: false,
        error: false,
        nextStepLoading: false,
        errorMessage: null,
        sendingClientForm: false,
        isPossibleSendClientForm: checkIsPossibleSendClientForm(quizData.info.form.contacts),
        clientData: {
            answers: {},
            contacts: {}
        },
        contactsValidate: {
            'phone': null,
            'email': null,
            'name': null
        },
        finish: false,
        start: quizData.info.startPage.enabled,
        noQuestionPage: false,
        contacts: (quizData.questions.length) ? false : true,
        activeKeyItem: (quizData.questions.length) ? 0 : null,
        form: {
            buttonPrevDisabled: true,
            buttonNextDisabled: quizData.questions.length > 1 ? false : true
        },
        discountOneStep: false,
        discountSeek: false,
        discount: (quizData.info.discount && quizData.info.discount.type === 'melting') ? quizData.info.discount.value : false,
        progress: 0
    }
}
