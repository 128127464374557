import {userService} from '../_api';
import {history} from '../_services/history';
import {eventUserLogin, eventRegistration, eventUpdateUser} from '../_services/events';
import {
    setUserInfo,
    setModalComponent,
    cpStartLoading,
    cpCompleteLoading,
    cpAddError,
    signOutSuccess,
    cpAddMessage,
    cpSetShowModalAdditionalUser
} from './'


export const userActions = {
    getUserInfo,
    activateProgram
};

export const login = (username, password) => (dispatch) => {

    dispatch(cpStartLoading());
    userService.login(username, password)
        .then(
            user => {
                eventUserLogin();
                localStorage.setItem('user', JSON.stringify(user));
                if (!user.info.name && !user.info.phone && !user.info.site) dispatch(cpSetShowModalAdditionalUser(true));
                dispatch(getUserInfo());
                history.push('/app')
            },
            error => {
                dispatch(cpAddError(error));
                dispatch(cpCompleteLoading());
            }
        );
}

export const loginTelegram = (user) => (dispatch) => {
    dispatch(cpStartLoading());
    eventUserLogin();
    localStorage.setItem('user', JSON.stringify(user));
    if (!user.info.name && !user.info.phone && !user.info.site) dispatch(cpSetShowModalAdditionalUser(true));
    dispatch(getUserInfo());
    history.push('/app')
}

export const signOut = () => (dispatch) => {
    localStorage.removeItem('user');
    history.push('/login')
    dispatch(signOutSuccess());
}

export const updateUser = (newUserInfo) => (dispatch) => {
    dispatch(cpStartLoading());

    userService.updateUser(newUserInfo).then((userInfo) => {
        eventUpdateUser();
        dispatch(setUserInfo(userInfo))
        dispatch(cpSetShowModalAdditionalUser(false));
        dispatch(cpAddMessage("Спасибо! Мы начислили вам на счёт 10 заявок."));
        dispatch(cpCompleteLoading());
    })
}

function activateProgram(quizId) {
    return dispatch => {
        dispatch(cpStartLoading());
        userService.activateProgram(quizId)
            .then(
                () => {
                    dispatch(getUserInfo())
                }
            );
    };

}

export const createUser = (username, password) => (dispatch) => {
    dispatch(cpStartLoading());
    userService.createUser(username, password)
        .then(
            user => {
                eventRegistration();
                localStorage.setItem('user', JSON.stringify(user));
                dispatch(login(username, password));
            },
            error => {
                dispatch(cpAddError(error));
                dispatch(cpCompleteLoading());
            }
        );
}

export const attachPromoCode = (promocode) => dispatch => {
    dispatch(cpStartLoading());
    userService.attachPromoCode(promocode)
        .then(
            res => {
                dispatch(cpAddMessage(res.message));
                dispatch(cpCompleteLoading());
                dispatch(getUserInfo());
            },
            error => {
                dispatch(cpAddError(error));
                dispatch(cpCompleteLoading());
            }
        );
}


function getUserInfo() {
    return dispatch => {
        dispatch(cpStartLoading());
        userService.getUserInfo()
            .then(
                userInfo => {
                    dispatch(setUserInfo(userInfo));
                    if (!userInfo.info.name && !userInfo.info.phone && !userInfo.info.site) {
                        setTimeout(() => {
                            dispatch(setModalComponent({
                                title: "Получите 10 бесплатных заявок!",
                                actionClose: (() => {
                                    dispatch(setModalComponent(null))
                                }),
                                bodyComponent: '../_components/ModalAdditionalInfo'
                            }))
                        }, 10000);
                    }
                    dispatch(cpCompleteLoading());
                }
            );
    };


}