import React from "react";
import {connect} from "react-redux";
import {Finish, editHandler} from "oldkitleadforms";
import {t} from "leadformsquizentities/translate";
import LayoutAdmin from "../LayoutAdmin";
import QuizContainer from "../QuizContainer";
import {QuizFinish} from "newkitleadforms";

const View = ({isQuizBot, isNewTheme, title, thanksTitle, thanks, colorTheme}: {isQuizBot: boolean, isNewTheme: boolean, title: string, thanksTitle: string, thanks: string , colorTheme: string}) => {
    if (!isNewTheme) {
        return (<LayoutAdmin><div style={{background: 'white', width: '100%', height: '100%'}}><QuizContainer color={colorTheme}>
            <Finish
                thanksTitle={thanksTitle}
                thanks={thanks}
                isAdmin
            /></QuizContainer></div>
        </LayoutAdmin>);
    }
    return (<div style={{background: 'white', borderRadius: 6, overflow: 'hidden', width: isQuizBot ? 375 : '100%', height: isQuizBot ? 550 : 'auto'}}>
        <QuizFinish
            finishTitle={thanksTitle}
            finishSubTitle={thanks}
            quizTitle={title}
            isAdmin
        />
    </div>);
};

const mapStateToProps = (state: any) => {
    const quizData = state.quizData.toJS();
    return {
        thanksTitle: quizData.info.form.thanksTitle ? quizData.info.form.thanksTitle : t('Спасибо!'),
        thanks: quizData.info.form.thanks,
        colorTheme: true !== quizData.isEmpty ? quizData.info.design.colors.main : '',
        title: quizData.info.title,
        isNewTheme: quizData.isNewTheme,
        isQuizBot: quizData.isQuizBot
    };
};

export default connect(
    mapStateToProps
)(View);
