import { compose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import HeaderView from './HeaderView';
import { signOut } from "../../../_actions";
export default compose(
  connect(
    state =>  {
      return {
        userInfo: state.userInfo.toJS(),
          quizData: state.quizData.toJS(),
        isEditQuizRoute:  state.cpState.toJS().isEditQuizRoute,
        isAnswersQuizRoute:  state.cpState.toJS().isAnswersQuizRoute,
    }},
    { signOut},
  ),
  withState('mailMenu', 'setMailMenu', null),
  withState('isMailsUnread', 'setIsMailsUnread', true),
  withState('notificationsMenu', 'setNotificationsMenu', null),
  withState('isNotificationsUnread', 'setIsNotificationsUnread', true),
  withState('profileMenu', 'setProfileMenu', null),
  withState('mobileMenu', 'setMobileMenu', null),
  withHandlers({
    openMailMenu: props => event => {
      props.setMailMenu(event.currentTarget);
      props.setIsMailsUnread(false);
    },
    closeMailMenu: props => () => {
      props.setMailMenu(null);
    },
    openNotificationsMenu: props => event => {
      props.setNotificationsMenu(event.currentTarget);
      props.setIsNotificationsUnread(false);
    },
    closeNotificationsMenu: props => () => {
      props.setNotificationsMenu(null);
    },
    openMobileMenu: props => (event) => {
      props.setMobileMenu(event.currentTarget);
    },
    closeMobileMenu: props => () => {
      props.setMobileMenu(null);
    },
    openProfileMenu: props => event => {
      props.setProfileMenu(event.currentTarget);
    },
    closeProfileMenu: props => () => {
      props.setProfileMenu(null);
    },
  })
)(HeaderView);