import {compose, withHandlers, withState} from 'recompose';
import {connect} from 'react-redux';
import View from './View';
import {getQuestionById} from '../../../../_services/questionService';
export default compose(
    connect(
        (state) => ({
            quizData: state.quizData.toJS()
        })
    ),
    withState('questionId', 'setQuestionId', (props) => props.condition ? props.condition.questionId : null),
    withState('selectedQuestion', 'setSelectedQuestion', (props) => props.condition ? getQuestionById(props.condition.questionId) : null),
    withState('condition', 'setCondition', (props) => props.condition ? props.condition.condition : null),
    withHandlers({
        changeQuestionIdHandler: props => (e) => {
            props.setQuestionId(e.target.value);
            props.setSelectedQuestion(getQuestionById(e.target.value));
            props.setCondition(null);
        },
        changeAnswerHandler: props => (condition) => {
            props.setCondition(condition);
            props.handler({
                questionId: props.questionId,
                condition: condition,
            })
            if(props.byNewCondition){
                props.setQuestionId(null);
                props.setCondition(null);
                props.setSelectedQuestion(null);
            }
        },
    })
)(View);

