import {requestApi} from './requestApi';
import { authHeader } from '../_services';

export const answerService = {
    add,
    update,
    remove,
    sort
};


function add(quizId, questionId, data) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    };
    return requestApi(`quizzes/${quizId}/questions/${questionId}/answers`, requestOptions);
}

function update(quizId, questionId, answerId, data) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(data)
    };
    return requestApi(`quizzes/${quizId}/questions/${questionId}/answers/${answerId}`, requestOptions);
}

function remove(quizId, questionId, answerId) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'DELETE',
        headers: headers
    };
    return requestApi(`quizzes/${quizId}/questions/${questionId}/answers/${answerId}`, requestOptions);
}

function sort(quizId, questionId, answerIdsBySort) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({answer_ids: answerIdsBySort})
    };
    return requestApi(`quizzes/${quizId}/questions/${questionId}/answers/sort`, requestOptions);
}



