import {Map} from 'immutable'
import {createReducer} from 'redux-act'

import {
    setQuizzesList,
    setOneToQuizzesList,
    signOutSuccess,
    deleteOneQuizzesList,
    setQuizData
} from '../_actions'


const entityToMap = (data) => {
    return new Map(data)
}

const defaultState = entityToMap({isLoading: false, list: {}});

export const quizzes = createReducer((on) => {
    on(setQuizzesList, (state, data) => {
        let list = {}
        data.forEach(function (quizz) {
            list[quizz.id] = quizz;
        })
        return state.set('list', list).set('isLoading', true)
    })

    on(setQuizData, (state, data) => {
        let list = state.get('list')
        list[data.id] = data
        return state.set('list', list)
    })

    on(signOutSuccess, () => defaultState)

    on(setOneToQuizzesList, (state, quizData) => {
        let list = state.get('list')
        list[quizData.id] = quizData;
        return state.set('list', list)
    })

    on(deleteOneQuizzesList, (state, quizId) => {
        const list = state.get('list')
        if (list[quizId]) delete list[quizId]
        return state.set('list', list)
    })

}, defaultState)




