import React from "react";
import {NavLink, Route, Redirect, Switch} from "react-router-dom";
import Start from './Start'
import Questions from './Questions'
import Contacts from './Contacts'
import Finish from './Finish'
import Results from './Results'
import CodeComponent from './Code'
import Integrations from './Integrations'
import {Switch as SwitchForm} from "@material-ui/core";

import {
    Grid,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    withStyles, FormControlLabel
} from "@material-ui/core";
import classnames from "classnames";
import {
    LibraryBooks,
    Contacts as ContactsIcon,
    Code,
    Send,
    Web,
    CheckCircleOutline,
    ChevronRight,
    ChevronLeft,
    Visibility as VisibilityIcon,
    Bookmark as BookmarkIcon
} from "@material-ui/icons";
import PreviewQuiz from '../../components/PreviewQuiz'

const Content = () => (
    <Switch>
        <Route exact path="/app/quizzes/edit/:quizId" render={props => (
            <Redirect to={{pathname: `/app/quizzes/edit/${props.match.params.quizId}/start`}}/>)}/>
        <Route path="/app/quizzes/edit/:quizId/start" component={Start}/>
        <Route path="/app/quizzes/edit/:quizId/questions" component={Questions}/>
        <Route path="/app/quizzes/edit/:quizId/contacts" component={Contacts}/>
        <Route path="/app/quizzes/edit/:quizId/finish" component={Finish}/>
        <Route path="/app/quizzes/edit/:quizId/results" component={Results}/>
        <Route path="/app/quizzes/edit/:quizId/code" component={CodeComponent}/>
        <Route path="/app/quizzes/edit/:quizId/integrations" component={Integrations}/>
    </Switch>
)
const View = ({isQuizBot, changeIsQuizBotHandler, resultAfterQuestion, initedQuizModule, changeIsNewThemeHandler, isNewTheme, classes, quizId, quizData, isActiveMobileMenu, cahngeIsActiveMobileMenu, previewQuizId, PreviewQuizHandler}) => (
    (quizData.isEmpty || !initedQuizModule) ? null :
        <React.Fragment>
            <div className={classes.container}>
                <Paper  className={
                            classnames(
                            classes.leftBar,
                            {[classes.leftBarIfActiveMobileMenu]: isActiveMobileMenu})
                        }>
                    <div className={classes.fakeToolbar}/>
                    <List component="nav">
                        <ListItem className={classes.hideDesktop} button onClick={cahngeIsActiveMobileMenu}>
                            <ListItemIcon>
                                {isActiveMobileMenu ? <ChevronLeft/> : <ChevronRight/>}
                            </ListItemIcon>
                            <ListItemText primary="Свернуть"/>
                        </ListItem>

                        <ListItem button component={NavLink} to={`/app/quizzes/edit/${quizId}/start`}>
                            <ListItemIcon>
                                <Web/>
                            </ListItemIcon>
                            <ListItemText primary="Стартовая страница"/>
                        </ListItem>
                        <ListItem button component={NavLink} to={`/app/quizzes/edit/${quizId}/questions`}>
                            <ListItemIcon>
                                <LibraryBooks/>
                            </ListItemIcon>
                            <ListItemText primary="Форма и вопросы"/>
                        </ListItem>
                        {(resultAfterQuestion && isNewTheme) && <ListItem button component={NavLink} to={`/app/quizzes/edit/${quizId}/results`}>
                            <ListItemIcon>
                                <BookmarkIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Результаты"/>
                        </ListItem>}
                        <ListItem button component={NavLink} to={`/app/quizzes/edit/${quizId}/contacts`}>
                            <ListItemIcon>
                                <ContactsIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Сбор контактов"/>
                        </ListItem>
                        {(!resultAfterQuestion && isNewTheme) && <ListItem button component={NavLink} to={`/app/quizzes/edit/${quizId}/results`}>
                            <ListItemIcon>
                                <BookmarkIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Результаты"/>
                        </ListItem>}
                        <ListItem button component={NavLink} to={`/app/quizzes/edit/${quizId}/finish`}>
                            <ListItemIcon>
                                <CheckCircleOutline/>
                            </ListItemIcon>
                            <ListItemText primary="Страница финиша"/>
                        </ListItem>
                        <ListItem button component={NavLink} to={`/app/quizzes/edit/${quizId}/code`}>
                            <ListItemIcon>
                                <Code/>
                            </ListItemIcon>
                            <ListItemText primary="Установка квиза"/>
                        </ListItem>

                        <ListItem button component={NavLink} to={`/app/quizzes/edit/${quizId}/integrations`}>
                            <ListItemIcon>
                                <Send/>
                            </ListItemIcon>
                            <ListItemText primary="Уведомления и настройки"/>
                        </ListItem>
                    </List>
                    <List>
                        {!isQuizBot && <ListItem>
                            <FormControlLabel
                                className={classes.newDesignLabel}
                                control={
                                    <SwitchForm
                                        checked={isNewTheme}
                                        onChange={changeIsNewThemeHandler}
                                    />
                                }
                                label={<p>Новый дизайн</p>}
                            />
                        </ListItem>}
                        {/*<ListItem>*/}
                            {/*<FormControlLabel*/}
                                {/*className={classes.newDesignLabel}*/}
                                {/*control={*/}
                                    {/*<SwitchForm*/}
                                        {/*checked={isQuizBot}*/}
                                        {/*onChange={changeIsQuizBotHandler}*/}
                                    {/*/>*/}
                                {/*}*/}
                                {/*label={<p>Квиз-бот</p>}*/}
                            {/*/>*/}
                        {/*</ListItem>*/}
                    </List>
                    <ListItem className={classes.bottomListItem} button onClick={PreviewQuizHandler.bind(this, quizId)}>
                        <ListItemIcon>
                            <VisibilityIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Предпросмотр квиза"/>
                    </ListItem>
                </Paper>

                <Grid  container  className={classes.content} >
                    <Content/>
                </Grid>

                <PreviewQuiz isNewTheme={isNewTheme} previewQuizId={previewQuizId} onClose={PreviewQuizHandler.bind(this, false)} />

            </div>
        </React.Fragment>
);


const styles = theme => ({
    container: {
        display: 'flex'
    },
    leftBar: {
        width: 200,
        position: 'fixed',
        bottom: 0,
        top: 0,
        '& a > div':{
            minWidth: 35
        },
        '& .active': {
            backgroundColor: '#F3F5FF',
            '&  svg,span': {
                color: theme.palette.primary.main
            }
        },
        '& a span':{
            fontSize: 14
        },
        '& a svg':{
            fontSize: 16
        },
        '@media (max-width:1024px) ': {
            width: 50,
            '& span': {
               display: 'none'
            }
        }
    },

    leftBarIfActiveMobileMenu: {
        width: 200,
        zIndex: 11,
        '& span': {
               display: 'unset'
            }
    },
    content: {
        width: 'calc(100vw - 200px)',
        marginLeft: 200,
        padding: 10,
        '@media (max-width:1024px) ': {
            width: 'calc(100vw - 50px)',
            marginLeft: 50,
        }
    },
    hideDesktop: {
        '@media (min-width:1024px) ': {
            display: 'none'
        }
    },
    bottomListItem: {
        bottom: 0,
        position: 'absolute',
        background: theme.palette.primary.main,
        '& span':{
            fontSize: 10,
            fontWeight: 'bold',
            color: '#fff'
        },
        '& svg':{
            fontSize: 16,
            color: '#fff'
        },
    },
    fakeToolbar: {
        ...theme.mixins.toolbar,
    },
    newDesignLabel:{
        '& p': {
            color: '#4CC724',
            fontSize: 13,
            fontWeight: 'bold'
        }
    }
});


export default withStyles(styles)(View);
