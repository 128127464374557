import React from "react";
import {Link, NavLink} from "react-router-dom";
import {AppBar, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Typography, withStyles} from "@material-ui/core";
import {
    AccountCircle as AccountIcon,
    ArrowBack,
    Help as SupportIcon,
    Menu as MenuIcon,
    Payment as AttachMoneyIcon,
    Settings,
    SupervisorAccount,
    ViewColumn as QuizzesIcon,
} from "@material-ui/icons";
import classnames from "classnames";

import logo from "../../img/logo.svg";

const notifications = [
    {id: 0, color: "warning", message: "Check out this awesome ticket"},
    {
        id: 1,
        color: "success",
        type: "info",
        message: "What is the best way to get ..."
    },
    {
        id: 2,
        color: "secondary",
        type: "notification",
        message: "This is just a simple notification"
    },
    {
        id: 3,
        color: "primary",
        type: "e-commerce",
        message: "12 new orders has arrived today"
    }
];

const Header = ({classes, closeMobileMenu, openMobileMenu, mobileMenu, isAnswersQuizRoute, isEditQuizRoute, quizData, ...props}) => (
    <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
            <IconButton
                color="inherit"
                className={classnames(
                    classes.mobileMenu,
                    classes.buttonMobileMenu
                )}
                onClick={openMobileMenu}
            >
                <MenuIcon
                    classes={{
                        root: classnames(classes.headerIcon, classes.headerIconCollapse)
                    }}
                />
            </IconButton>


            <MenuItem
                component={NavLink}
                to={`/app`}
            >
                <img src={logo} alt="logo" className={classes.logotypeImage}/>
            </MenuItem>
            {((isAnswersQuizRoute || isEditQuizRoute) && quizData.id) && <><IconButton edge="start" color="inherit"
                                                                                       component={Link}
                                                                                       to={`/app/quizzes`}>
                <ArrowBack/>
            </IconButton>
                <Typography variant="h7" className={classes.title}>
                    {quizData.info.name}
                </Typography>
                <IconButton title="Редактировать квиз" color={isEditQuizRoute ? "primary" : "default"} component={Link}
                            to={`/app/quizzes/edit/${quizData.id}`}>
                    <Settings/>
                </IconButton>
                <IconButton title="Заявки" color={isAnswersQuizRoute ? "primary" : "default"} component={Link}
                            to={`/app/quizzes/answers/${quizData.id}`}>
                    <SupervisorAccount/>
                </IconButton></>
            }

            <div className={classes.grow}/>

            <MenuItem
                className={classnames(classes.headerMenuWithTitleItem, classes.desktopMenu)}
                component={NavLink}
                to={`/app/quizzes`}
            >
                <QuizzesIcon classes={{root: classes.headerIcon}}/>
                <Typography className={classnames(classes.headerTitle, classes.headerTitleBottom)}>
                    Мои квизы
                </Typography>
            </MenuItem>

            <MenuItem
                className={classnames(classes.headerMenuWithTitleItem, classes.desktopMenu)}
                component={NavLink}
                to={`/app/instructions`}
            >
                <SupportIcon classes={{root: classes.headerIcon}}/>
                <Typography className={classnames(classes.headerTitle, classes.headerTitleBottom)}>
                    Справка
                </Typography>
            </MenuItem>

            <MenuItem
                className={classnames(classes.headerMenuWithTitleItem, classes.desktopMenu)}
                component={NavLink}
                to={`/app/balance`}
            >
                <AttachMoneyIcon classes={{root: classes.headerIcon}}/>
                <Typography className={classnames(classes.headerTitle, classes.headerTitleBottom)}>
                    Баланс
                </Typography>
            </MenuItem>

            <Typography component="div" className={classnames(classes.headerMenuDivider, classes.desktopMenu)}/>

            <Typography className={classnames(classes.headerTitle, classes.desktopMenu, classes.headerMenuButton)}>
                {props.userInfo.info.telegram ? (props.userInfo.info.telegram.username ? props.userInfo.info.telegram.username : `${props.userInfo.info.telegram.first_name} ${props.userInfo.info.telegram.last_name}`) : props.userInfo.email}
            </Typography>
            <IconButton
                className={classnames(
                    classes.headerMenuButton,
                    classes.profileButton
                )}

                onClick={props.openProfileMenu}
            >
                <AccountIcon classes={{root: classes.headerIcon}}/>
            </IconButton>


            <Menu
                open={Boolean(props.profileMenu)}
                anchorEl={props.profileMenu}
                onClose={props.closeProfileMenu}
                className={classes.menuModal}
            >
                <div className={classes.profileMenuUser}>
                    <Typography variant="h4" weight="medium">
                        {props.userInfo.name}
                    </Typography>
                    <Typography
                        className={classes.profileMenuLink}
                        component="a"
                        color="primary"
                    >
                        {props.userInfo.info.telegram ? (props.userInfo.info.telegram.username ? props.userInfo.info.telegram.username : `${props.userInfo.info.telegram.first_name} ${props.userInfo.info.telegram.last_name}`) : props.userInfo.email}
                    </Typography>
                </div>
                <div className={classes.profileMenuUser}>
                    <Typography
                        className={classes.profileMenuLink}
                        color="primary"
                        onClick={props.signOut}
                    >
                        Выйти
                    </Typography>
                </div>
            </Menu>


            <Menu
                open={Boolean(mobileMenu)}
                anchorEl={mobileMenu}
                onClose={closeMobileMenu}
                className={classes.menuModal}
            >
                <MenuItem component={NavLink} to={`/app/quizzes`}>
                    <ListItemIcon>
                        <QuizzesIcon/>
                    </ListItemIcon>
                    <Typography variant="inherit"> Мои квизы</Typography>
                </MenuItem>
                <MenuItem component={NavLink} to={`/app/instructions`}>
                    <ListItemIcon>
                        <SupportIcon/>
                    </ListItemIcon>
                    <Typography variant="inherit"> Справка</Typography>
                </MenuItem>
                <MenuItem component={NavLink} to={`/app/balance`}>
                    <ListItemIcon>
                        <AttachMoneyIcon/>
                    </ListItemIcon>
                    <Typography variant="inherit"> Баланс</Typography>
                </MenuItem>
            </Menu>


        </Toolbar>
    </AppBar>
);

const styles = theme => ({
    logotypeImage: {
        width: 165,
        marginBottom: theme.spacing(1.5),
        [theme.breakpoints.down("xs")]: {
            width: 130,
            marginBottom: theme.spacing(0.5)
        }
    },
    appBar: {
        width: "100vw",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    hide: {
        display: "none"
    },
    buttonMobileMenu: {
        padding: 0
    },
    grow: {
        flexGrow: 1
    },
    inputRoot: {
        color: "inherit",
        width: "100%"
    },
    inputInput: {
        height: 36,
        padding: 0,
        paddingRight: 36 + theme.spacing(1.25),
        width: "100%"
    },
    messageContent: {
        display: "flex",
        flexDirection: "column"
    },
    menuModal: {
        marginTop: theme.spacing(7)
    },
    headerTitle: {
        color: theme.palette.secondary.main
    },
    headerTitleBottom: {
        lineHeight: 0.2,
        fontSize: '9px !important',
        textTransform: 'uppercase'
    },
    headerMenuList: {
        display: "flex",
        flexDirection: "column"
    },
    headerMenuWithTitleItem: {
        display: 'block',
        textAlign: 'center',
        height: 'auto'
    },
    mobileMenu: {
        display: "none",
        [theme.breakpoints.down("sm")]: {
            display: "block"
        }
    },
    desktopMenu: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "block"
        }
    },
    headerMenuButton: {
        marginLeft: theme.spacing(2),
        padding: theme.spacing(0.5)
    },
    headerMenuButtonCollapse: {
        marginRight: theme.spacing(2)
    },
    profileButton: {
        padding: theme.spacing(0.5)
    },
    headerMenuDivider: {
        background: theme.palette.primary.main,
        height: 32,
        margin: theme.spacing(0.5),
        width: 1
    },
    headerIcon: {
        fontSize: 28,
        color: theme.palette.primary.main
    },
    headerIconCollapse: {
        color: theme.palette.primary.main,
    },
    profileMenu: {
        minWidth: 265
    },

    profileMenuUser: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2)
    },
    profileMenuItem: {
        color: theme.palette.text.hint
    },
    profileMenuIcon: {
        marginRight: theme.spacing(2),
        color: theme.palette.text.hint
    },
    profileMenuLink: {
        fontSize: 16,
        textDecoration: "none",
        "&:hover": {
            cursor: "pointer"
        }
    },
    messageNotification: {
        height: "auto",
        display: "flex",
        alignItems: "center"
    },
    messageNotificationSide: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginRight: theme.spacing(2)
    },
    messageNotificationBodySide: {
        alignItems: "flex-start",
        marginRight: 0
    },
    sendMessageButton: {
        margin: theme.spacing(4),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textTransform: "none"
    },
    sendButtonIcon: {
        marginLeft: theme.spacing(2)
    }
});

export default withStyles(styles)(Header);
