export const values = {
    utm_source: {value: 'utm_source', name: 'utm_source'},
    utm_medium: {value: 'utm_medium', name: 'utm_medium'},
    utm_campaign: {value: 'utm_campaign', name: 'utm_campaign'},
    utm_content: {value: 'utm_content', name: 'utm_content'},
    roistat_visit: {value: 'roistat_visit', name: 'roistat_visit'},
    _ym_uid: {value: '_ym_uid', name: 'Яндекс.Метрика (_ym_uid)'},
    _ga: {value: '_ga', name: 'Google Analytics (_ga)'}
};

