import 'whatwg-fetch';
import {handleResponse} from './handleResponse';
import { authHeader, config } from '../_services';
import {eventCreateOrder} from '../_services/events';

export const orderService = {
    getOrder
};



function getOrder(data) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)

    };
    eventCreateOrder();

    return fetch(`${config.apiUrl}/get_order`, requestOptions).then(handleResponse)
}

