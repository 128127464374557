import React from "react";
import {withStyles} from "@material-ui/core";
import uploadImage from '../../img/uploadImage.png'
const View = ({htmlFor, classes}) => (
            <label  htmlFor={htmlFor} className={classes.uploadImage}>
                <img src={uploadImage}/>
            </label>
);


const styles = theme => ({
    uploadImage: {
        top: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderRadius: 100000,
        textAlign: 'center',
        margin: 0,
        cursor: 'pointer',
        display: 'block',
        '& img': {
            display: 'none',
        },
        '&:hover': {
            background: '#f7f7f78a',
            '& img': {
                width: 'auto',
                display: 'block',
                height: '50%',
                marginTop: '25%',
                marginBottom: '25%',
                marginLeft: 'auto',
                marginRight: 'auto',
            }
        }
    }
});
export default withStyles(styles)(View);