import React from "react";
import {config} from "../../../../../_services";
import {updateQuizData} from "../../../../../_actions";
import {connect} from "react-redux";
import {
   TextField,
   Button,
   Grid,
   Typography,
   Card,
   CardContent
} from "@material-ui/core";

import {
   FileCopy,
   Link as LinkIcon
} from "@material-ui/icons";

class Link extends React.Component {

    constructor(props) {
        super(props);
        this.state = {customDomain: props.quizData.customDomain}
    }

    changeCustomDomain(e){
        let newState = this.state
        newState.customDomain = e.target.value
        this.setState(newState);
    }

    saveCustomDomain(e){
        e.preventDefault()
        let newQuizData = this.props.quizData
        newQuizData.customDomain = this.state.customDomain
        this.props.dispatch(updateQuizData(newQuizData));
    }

    render() {
        const link = `${config.quizUrl}/default/${this.props.quizId}`
        return (
            <Grid container variant='columns'>
                <Button  target="_blanc" href={`${link}`}><LinkIcon/> открыть</Button>
                  <TextField
                    fullWidth
                    variant="filled"
                    margin="dense"
                    value={link}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (<Button onClick={this.props.copyToClipboard.bind(this, link)}><FileCopy/> скопировать</Button>)
                    }}
                />

                <br/><br/><br/><br/>
                <Card>

                <CardContent>
                        <Typography variant="h5">Привязать домен</Typography>
                        <br/>
                        <Typography variant="h6">1. Настройте записи в регистраторе домена</Typography>
                        <br/>
                        <Typography variant="button">Для поддоменов:</Typography>
                        <Typography variant="body2">Создайте для нужного поддомена CNAME-запись
                        <TextField  
                            variant="filled"
                            margin="dense"
                            fullWidth
                            value={config.proxyHostCustomDomainQuiz}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (<Button  onClick={this.props.copyToClipboard.bind(this, config.proxyHostCustomDomainQuiz)}><FileCopy/> скопировать</Button>)
                            }}
                        />
                        </Typography>
                        <br/>
                        <Typography variant="button">Для доменов первого уровня:</Typography>
                        <Typography variant="body2">Создайте A-запись
                        <TextField 
                            fullWidth 
                            variant="filled"
                            margin="dense"
                            value={config.ipCustomDomainQuiz} 
                            InputProps={{
                                readOnly: true,
                                endAdornment: (<Button onClick={this.props.copyToClipboard.bind(this, config.proxyHostCustomDomainQuiz)}><FileCopy/> скопировать</Button>)
                            }}
                        />
                        </Typography>
                        <br/>
                        <Typography variant="h6">2. Укажите домен и сохраните квиз</Typography>
                        <form >
                            <div className="input-group mb-3">
                                <TextField 
                                    fullWidth
                                    placeholder="quiz.example.com" 
                                    onChange={this.changeCustomDomain.bind(this)} 
                                    value={this.state.customDomain}
                                    InputProps={{
                                        endAdornment: (<Button variant="outlined"  onClick={this.saveCustomDomain.bind(this)}>сохранить</Button>)
                                    }}
                                />
    
                            </div>
                        </form>

                        <Typography variant="overline">Только домен без протокола и слеша в конце.</Typography>
                    </CardContent>
                </Card>
            </Grid>)
    }
}

function mapStateToProps(state) {
    const quizData = state.quizData.toJS()
    return {
        quizData
    };
}

export default connect(mapStateToProps)(Link);