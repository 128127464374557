
import { compose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import View from './View';
import {updateQuizData} from "../../../../../_actions";
import React from "react";


export default compose(
    connect(
        state => ({
            quizData: state.quizData.toJS(),
            emailList: state.quizData.toJS().notifications.email.addresses,
            selfEmailIsActive: state.quizData.toJS().notifications.selfEmail.active,
            selfEmail: state.userInfo.toJS().email,
        }),
        { updateQuizData }
    ),
    withHandlers({
        changeSelfEmailActiveHandler: props => (e) => {
            let newQuizData = props.quizData
            newQuizData.notifications.selfEmail.active = e.target.checked
            props.updateQuizData(newQuizData);
        },
        addEmailNotificationsHandler: props => (e) => {
            e.preventDefault()
            const data = new FormData(e.target);
            let newQuizData = props.quizData
            if(newQuizData.notifications.email.addresses.indexOf(data.get('email')) == -1)
                newQuizData.notifications.email.addresses.push(data.get('email'))
            props.updateQuizData(newQuizData);
            e.target.reset()
        },
        removeEmail: props => (deleteEmail) => {
            let addresses = []
            props.quizData.notifications.email.addresses.map((email) => {
                if(deleteEmail != email){
                    addresses.push(email)
                }
            })
            props.quizData.notifications.email.addresses = addresses
            props.updateQuizData(props.quizData);
        }
    })


)(View);