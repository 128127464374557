
import {compose, withState, withHandlers, lifecycle} from 'recompose';
import { connect } from 'react-redux';

import View from './View';
import {updateQuizData} from "../../../../../_actions";
import React from "react";
import {testBitrix24} from "../../../../../_api/crmService";
import {Chip} from "@material-ui/core";

const checkConnection = (props) => {
    props.setBitrix24Loading(true);
    testBitrix24({
        domain: props.bitrix24Data.domain,
        token: props.bitrix24Data.token,
        user:  props.bitrix24Data.user,
        country: props.bitrix24Data.country
    }).then(
        (r) => {
            if(!r.error_code) {
                props.setShowAdditional(true);
            }
            props.setMessage((r.error_code)
                ? <Chip
                    variant="outlined"
                    color="red"
                    label={`Ошибка: ${r.message}`}
                />
                : <Chip
                    variant="outlined"
                    label="Подключение установлено"
                    color="primary"
                />);

            props.setBitrix24Loading(false);
        },
        () => {
            props.setMessage('Ошибка');
            props.setBitrix24Loading(false);
        }
    );
}

export default compose(
    connect(
        state => ({
            quizData: state.quizData.toJS(),
        }),
        { updateQuizData}
    ),
    withState("message", "setMessage", null),
    withState("bitrix24Loading", "setBitrix24Loading", false),
    withState('bitrix24Data', 'setBitrix24Data', props => (props.quizData.notifications.bitrix24)),
    withState('showAdditional', 'setShowAdditional', false),
    withState("additionalFieldLeadforms", "setAdditionalFieldLeadforms", 0),
    withState("additionalFieldBitrix", "setAdditionalFieldBitrix", ''),

    withHandlers({
        changeNotificationCheckedHandler: props => () => {
            const bitrix24Data = {...props.bitrix24Data, enabled:!props.bitrix24Data.enabled}
            props.setBitrix24Data(bitrix24Data);
            props.quizData.notifications.bitrix24 = bitrix24Data
            props.updateQuizData(props.quizData)
        },
        testConnectionBitrix24: props => {
            checkConnection(props);
            let quizData = props.quizData
            quizData.notifications.bitrix24 = props.bitrix24Data
            props.updateQuizData(quizData)
        },
        changebitrix24Data: (props) => (key, value) => {
            let bitrix24Data = props.bitrix24Data
            bitrix24Data[key] = value
            props.setBitrix24Data(bitrix24Data);

            if(["domain", "token", "user", "country"].indexOf(key) !== -1){
                props.setMessage(null);
                props.setShowAdditional(false);
            } else {
                let quizData = props.quizData
                quizData.notifications.bitrix24 = props.bitrix24Data
                props.updateQuizData(quizData)
            }
        },
        addAdditionalFieldHandler: props => () => {
            let bitrix24Data = props.bitrix24Data;
            if(undefined === bitrix24Data.additionalFields) {
                bitrix24Data.additionalFields = [];
            }
            bitrix24Data.additionalFields.push({
                bitrix: props.additionalFieldBitrix,
                leadforms: props.additionalFieldLeadforms
            })
            props.setAdditionalFieldLeadforms(0);
            props.setAdditionalFieldBitrix('');
            props.setBitrix24Data(bitrix24Data);

            let quizData = props.quizData
            quizData.notifications.bitrix24 = bitrix24Data
            props.updateQuizData(quizData)
        },
        deleteAdditionalFieldHandler: props => (deletedKey) => {
            let bitrix24Data = props.bitrix24Data;
            bitrix24Data.additionalFields.splice(deletedKey, 1);
            props.setBitrix24Data(bitrix24Data);

            let quizData = props.quizData
            quizData.notifications.bitrix24 = bitrix24Data
            props.updateQuizData(quizData)
        },

    }),
    lifecycle({
        componentDidMount() {
            if(this.props.quizData.notifications.bitrix24.domain
                && this.props.quizData.notifications.bitrix24.token
                && this.props.quizData.notifications.bitrix24.user){
                checkConnection(this.props);
            }

        }
    })
)(View);


