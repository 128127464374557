import {compose, withHandlers} from 'recompose';
import {connect} from 'react-redux';
import View from './View';
import {updateResult} from '../../../_actions';

export default compose(
    connect(
        (state, props) => {
            const quizData = state.quizData.toJS();
            const result = quizData.info.results.items[props.resultIndex];
            return {
                quizData: quizData,
                title: result.value,
                displayConditions: result.displayConditions ? result.displayConditions : [],
                questionsByCondition: quizData.questions,
                typeIfFewConditions: result.typeIfFewConditions ? result.typeIfFewConditions : 'and',
                result: result,
                url: result.url,
                showUrl: !quizData.info.results.afterQuestion
            }
        },
        {updateResult}
    ),
    withHandlers({
        newConditionHandler: props => (conditionData) => {
            if(!props.result.displayConditions) props.result.displayConditions = []
            props.result.displayConditions.push(conditionData)
            props.updateResult(props.result.key, props.result);
        },
        changeUrlHandler: props => (e) => {
            e.preventDefault()
            props.result.url = e.target.value
            props.updateResult(props.result.key, props.result);
        },
        updateConditionHandler: props => (conditionIndex, conditionData) => {
            if(!props.result.displayConditions) props.result.displayConditions = []
            props.result.displayConditions[conditionIndex] = conditionData
            props.updateResult(props.result.key, props.result);
        },
        deleteConditionHandler: props => (conditionIndexForDelete) => {
            props.result.displayConditions = props.result.displayConditions.filter((displayCondition, conditionIndex) => conditionIndex !== conditionIndexForDelete);
            props.updateResult(props.result.key, props.result);
        },
        handleChangeTypeIfFewConditions: props => (e) => {
            props.result.typeIfFewConditions = e.target.value
            props.updateResult(props.result.key, props.result);
        }
    })
)(View);


