import React from "react";
import {
    withStyles,
    Typography
} from "@material-ui/core";


import vk from "../../img/vk.png";
import tm from "../../img/fb.png";

const FooterView = ({ classes }) => (

        <footer className={classes.footer}>
            <Typography component={React.Link} to='https://leadforms.ru' color="secondary" className={classes.copyright}>
                © {(new Date()).getFullYear()} Lead Forms
            </Typography>
            <a href='https://vk.com/leadforms' target='_blanc'>
                <img src={vk}  className={classes.logotypeImage}/>
            </a>
            <a href='https://www.facebook.com/leadforms.ru' target='_blanc'>
                <img src={tm}  className={classes.logotypeImage}/>
            </a>
        </footer>


);

const styles = theme => ({


    footer: {
        textAlign: 'center'
    },
    copyright: {
        marginLeft: theme.spacing(2),
        lineHeight: '2.5',
        display: 'inline-block'
    },
    logotypeImage: {
        width: 32,
        marginLeft: theme.spacing(1)
    }
});

export default withStyles(styles)(FooterView);
