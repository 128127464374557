
import {Map} from 'immutable'
import {createReducer} from 'redux-act'

import {cpSetIsAnswersQuizRoute, cpSetIsEditQuizRoute, cpAddError, setModalComponent, cpStartLoading, cpCompleteLoading, cpResetError, signOutSuccess, cpAddMessage, cpResetMessage, cpSetShowModalAdditionalUser} from '../_actions'

const entityToMap = (data) => {
    return new Map(data)
}

const defaultState = entityToMap({isEditQuizRoute: false, isAnswersQuizRoute: false, modal: null, loading: false, error: null, message: null, showModalAdditionalUser: false})

export const cpState = createReducer((on) => {
    on(setModalComponent, (state, modalComponent) => {
        return state.set('modal', modalComponent)
    })

    on(cpStartLoading, (state) => {
        return state.set('loading', true)
    })

    on(cpCompleteLoading, (state) => {
        return state.set('loading', false)
    })

    on(cpAddError, (state, error) => {
        return state.set('error', error)
    })

    on(cpResetError, (state, error) => {
        return state.set('error', null)
    })

    on(cpAddMessage, (state, message) => {
        return state.set('message', message)
    })

    on(cpResetMessage, (state, error) => {
        return state.set('message', null)
    })

    on(signOutSuccess, (state, error) => {
        return defaultState
    })

    on(cpSetShowModalAdditionalUser, (state, value) => {
        return state.set('showModalAdditionalUser', value)
    })

    on(cpSetIsEditQuizRoute, (state, data) => {
        return state.set('isEditQuizRoute', data)
    })

    on(cpSetIsAnswersQuizRoute, (state, data) => {
        return state.set('isAnswersQuizRoute', data)
    })

}, defaultState)




