import React from "react";
import QuizStartContainer from './QuizStartContainer';
import TitlePage from "./TitlePage";
import Favicon from "./Favicon";

import {
    FormControlLabel,
    Grid,
    Switch,
    Card,
    CardContent,
    Typography,
    CardActions,
    CardHeader,
    withStyles

} from "@material-ui/core";
import ToolTip from "../../../../components/ToolTip";
import classnames from "classnames";

const View = ({
                  quizData,
                  changeEnableStartPage,
                  hasTitle,
                  changeTitleHandler,
                  hasSubTitle,
                  changeSubTitleHandler,
                  hasPhone,
                  changePhoneHandler,
                  hasLegal,
                  changeLegalHandler,
                  hasRender,
                  changeRenderHandler,
                  changeLogoHandler,
                  hasLogo,
                  changeNameHandler,
                  hasName,
                  changeTitlePageHandler,
                  hasTitlePage,
                  changeHasFaviconHandler,
                  hasFavicon,
                  isNewTheme,
                  classes,
                  isQuizBot
              }) => (


    !quizData.info.startPage.enabled ?

        <Grid
            justify="center"
            container
            alignItems="stretch"
        >
            <Card className={classes.cardStartPageDisabled}>
                <CardHeader title="Стартовая страница"/>
                <CardContent>
                    <Typography variant="body2" component="p">
                        Стартовая страница нужна, чтобы замотивировать пользователя пройти
                        тест.<br/>Также, вы можете открывать квиз по прямой ссылке или привязать к нему домен, и
                        тогда стартовая страница будет служить полноценным Landing Page.
                    </Typography>
                </CardContent>
                <CardActions>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={quizData.info.startPage.enabled}
                                onChange={changeEnableStartPage}
                            />
                        }
                        label={'Включить'}
                    />
                </CardActions>
            </Card>
        </Grid>
        :
        <Grid
            container
            justify="center"
        >


            <Grid className={classnames(classes.content, isQuizBot && 'quiz_bot')}>
                <QuizStartContainer/>
                <Grid
                    container
                    direction="row"
                >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={hasTitlePage}
                                onChange={changeTitlePageHandler}
                            />
                        }
                        label={["Title", <ToolTip keyItem={'startTitlePage'}/>]}
                    />
                    <TitlePage/>
                </Grid>
            </Grid>


            <Grid className={classes.rightPanel}>
                <FormControlLabel fullWidth
                                  control={
                                      <Switch
                                          classes={{
                                              root: classes.root,
                                              switchBase: classes.switchBase,
                                              thumb: classes.thumb,
                                              track: classes.track,
                                              checked: classes.checked,
                                          }}
                                          checked={quizData.info.startPage.enabled}
                                          onChange={changeEnableStartPage}
                                      />
                                  }
                                  label={'Показывать стартовую страницу'}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasTitle}
                            onChange={changeTitleHandler}
                        />
                    }
                    label={["Заголовок", <ToolTip keyItem={'startTitle'}/>]}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasSubTitle}
                            onChange={changeSubTitleHandler}
                        />
                    }
                    label={["Подзаголовок", <ToolTip keyItem={'startSubTitle'}/>]}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasPhone}
                            onChange={changePhoneHandler}
                        />
                    }
                    label="Телефон"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasLegal}
                            onChange={changeLegalHandler}
                        />
                    }
                    label={["Юридическая информация", <ToolTip keyItem={'startLegal'}/>]}
                />
                {!isNewTheme && <FormControlLabel
                    control={
                        <Switch
                            checked={hasRender}
                            onChange={changeRenderHandler}
                        />
                    }
                    label={["Рендер", <ToolTip keyItem={'startRender'}/>]}
                />}
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasLogo}
                            onChange={changeLogoHandler}
                        />
                    }
                    label="Логотип"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasName}
                            onChange={changeNameHandler}
                        />
                    }
                    label={["Название или слоган", <ToolTip keyItem={'startName'}/>]}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasFavicon}
                            onChange={changeHasFaviconHandler}
                        />
                    }
                    label={["Favicon", <ToolTip keyItem={'startFavicon'}/>]}
                />
                {hasFavicon && <Favicon/>}
            </Grid>
        </Grid>
);


const styles = theme => ({
    cardStartPageDisabled: {
        maxWidth: 600
    },
    cardMedia: {
        width: '100%'
    },
    content: {
        maxWidth: 1024,
        width: '100%',
        '@media(min-width: 1201px)': {
            width: 'calc(100% - 150px)',
        },
        '&.quiz_bot': {
            width: 'auto'
        }
    },
    rightPanel: {
        padding: 10,
        width: '100%',
        '@media(min-width: 1201px)': {
            width: 150
        },
        '& span,p': {
            fontSize: '13px !important'
        }
    }
});
export default withStyles(styles)(View);
