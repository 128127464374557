
import {compose, lifecycle, withHandlers, withState} from 'recompose';
import { connect } from 'react-redux';

import View from './View';
import {updateQuizData} from "../../../../_actions";
import {t} from "leadformsquizentities/translate";
export default compose(
    connect(
        (state) => ({
            quizData: state.quizData.toJS(),
            redirect: state.quizData.toJS().info.form.redirect,
            isQuizBot: state.quizData.toJS().isQuizBot
        }),
        {updateQuizData}
    ),
    withState('hasThanks', 'changeHasThanks', (props) => props.quizData.info.form.thanks ? true : false ),
    withState('hasRedirect', 'changeHasRedirect', (props) => props.quizData.info.form.redirect ? true : false ),
    withHandlers({
        changeThanksHandler: props => () => {
            let quizData = props.quizData
            quizData.info.form.thanks = (props.hasThanks) ? '' : t('Сообщение после отправки')
            props.changeHasThanks(!props.hasThanks)
            props.updateQuizData(quizData);
        },
        changeHasRedirectHandler: props => () => {
            if(props.hasRedirect){
                let quizData = props.quizData
                quizData.info.form.redirect = ''
                props.updateQuizData(quizData);
            }
            props.changeHasRedirect(!props.hasRedirect);
            
        },
        changeRedirectHandler: props => (e) => {
            let quizData = props.quizData
            quizData.info.form.redirect = e.target.value
            props.updateQuizData(quizData);
        }
    }),
    lifecycle({
        componentWillReceiveProps(nextProps) {
            if (this.props.hasThanks && !nextProps.quizData.info.form.thanks)
                this.props.changeHasThanks(false)
        }
    })
)(View);

