import React from 'react';

import "../static/css/steps.css";
import {Grid, Typography} from "@material-ui/core";
import img1 from '../static/img/how_it_works_1.png'
import img2 from '../static/img/how_it_works_2.png'
import img3 from '../static/img/how_it_works_3.png'
import img4 from '../static/img/how_it_works_4.png'



export  default class Steps extends React.Component {
    render(){
        return (
            <Grid container className="steps">
                <Grid item xs={12} md={3} lg={3} className="steps__step">
                    <div className="steps__step-img-wrap">
                        <img src={String(img1)} />
                    </div>
                    <Typography  className="caption">1<br/>шаг</Typography>
                    <Typography className="steps__step-title">Установка кода виджета на сайт</Typography>
                    <Typography className="steps__step-caption">Сервис легко установить самостоятельно. <br/> Мы всегда готовы ответить на <br/>возникшие вопросы и помочь с установкой.</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3} className="steps__step">
                    <div className="steps__step-img-wrap">
                        <img src={String(img2)} />
                    </div>
                    <Typography  className="caption">2<br/>шаг</Typography>
                    <Typography className="steps__step-title">На Вашем сайте появится<br/>форма опроса</Typography>
                    <Typography className="steps__step-caption">Сервис легко установить самостоятельно. <br/> Мы всегда готовы ответить на <br/>возникшие вопросы и помочь с установкой.</Typography>
                </Grid>
                 <Grid item xs={12} md={3} lg={3} className="steps__step">
                    <div className="steps__step-img-wrap">
                        <img src={String(img3)} />
                    </div>
                    <Typography  className="caption">3<br/>шаг</Typography>
                    <Typography className="steps__step-title">Клиент получает<br/> выгодный бонус</Typography>
                    <Typography className="steps__step-caption">Пройдя опрос до конца и заполнив форму,<br/> клиента ждет вознаграждение, <br/> в виде скидки, либо бонуса</Typography>
                </Grid>
                 <Grid item xs={12} md={3} lg={3} className="steps__step">
                    <div className="steps__step-img-wrap">
                        <img src={String(img4)} />
                    </div>
                    <Typography  className="caption">4<br/>шаг</Typography>
                    <Typography className="steps__step-title">Вы узнаете мнение клиента <br/> и получаете его контакты</Typography>
                    <Typography className="steps__step-caption">Пройдя опрос до конца и заполнив форму,<br/> клиента ждет вознаграждение, <br/> в виде скидки, либо бонуса</Typography>
                </Grid>
            </Grid>
        )
    }
}


