import React from "react";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Input,
    Radio,
    RadioGroup,
    Typography,
    withStyles
} from "@material-ui/core";
import {Delete as DeleteIcon} from "@material-ui/icons";
import FullScreenDialog from '../../../components/FullScreenDialog';

import DisplayCondition from './DisplayCondition';

const View = ({
                  closeHandler,
                  resultIndex,
                  title,
                  questionsByCondition,
                  displayConditions,
                  newConditionHandler,
                  updateConditionHandler,
                  deleteConditionHandler,
                  typeIfFewConditions,
                  handleChangeTypeIfFewConditions,
                  changeUrlHandler,
                  url,
                  showUrl,
                  classes
              }) => (

    <FullScreenDialog modalTitle={`Настройки результата (#${resultIndex + 1} ${title})`} handleClose={closeHandler}>
        <Grid container>
            <Grid item xs={12} sm={6}>
                <Typography variant="h5">Условия показа</Typography>
                <br/>
                {(displayConditions.length >= 2) &&
                <FormControl className={classes.formTypeIfFewConditions} component="fieldset">
                    <FormLabel component="legend">Показывать вопрос, если выполнены</FormLabel>
                    <RadioGroup
                        style={{marginTop: 0}}
                        value={typeIfFewConditions}
                        onChange={handleChangeTypeIfFewConditions}
                    >
                        <FormControlLabel value="and" control={<Radio/>} label="все условия"/>
                        <FormControlLabel value="or" control={<Radio/>} label="хотя бы одно"/>
                    </RadioGroup>

                </FormControl>}
                <br/><br/>
                <Typography variant="h6">Добавьте условие</Typography>
                <DisplayCondition key={'new'} condition={null} byNewCondition={true} handler={newConditionHandler}
                                  questionsByCondition={questionsByCondition}/>
                {(displayConditions.length > 0) &&
                [<Typography className={classes.titleList} variant="h6">Список условий</Typography>,
                    displayConditions.map((condition, conditionIndex) =>

                        <Grid container>
                            <Grid item xs={10}>
                                <DisplayCondition key={conditionIndex}
                                                  handler={updateConditionHandler.bind(this, conditionIndex)}
                                                  condition={condition}
                                                  questionsByCondition={questionsByCondition}/>
                            </Grid>
                            <Grid item xs={2}
                                  container
                                  justify="center"
                                  alignItems="center"
                            >
                                <DeleteIcon onClick={deleteConditionHandler.bind(this, conditionIndex)}/>
                            </Grid>
                        </Grid>
                    )]
                }
            </Grid>
            {showUrl && <Grid item xs={12} sm={6}>
                <Typography variant="h5">Ссылка</Typography>
                <br/><br/>
                <Input value={url} onChange={changeUrlHandler} placeholder="https://example.com"/>
            </Grid>}
        </Grid>
    </FullScreenDialog>
);

const styles = theme => ({
    titleList: {
        marginBottom: 20
    },
    formTypeIfFewConditions: {
        paddingLeft: 20
    }
});

export default withStyles(styles)(View);