import React from "react";
import {
   TextField,
   InputProps,
   FormLabel,
   Button,
   withStyles
} from "@material-ui/core";

import {
   FileCopy
} from "@material-ui/icons";

const View = ({
    copyToClipboard,
   quizData,
    classes
}) => { 
const html = `<div class="Leadforms__block" data-id="${quizData.id}"></div>`

    return (
        <React.Fragment>
            <FormLabel>Вставьте код, если хотите, чтобы виджет отображался в определенном месте на сайте</FormLabel>
            <TextField
                    fullWidth
                    value={html}
                    variant="filled"
                    margin="dense"
                    InputProps={{
                        readOnly: true,
                        endAdornment: (<Button onClick={copyToClipboard.bind(this, html)}><FileCopy/> скопировать</Button>)
                    }}
                />
        </React.Fragment>
)}

const styles = theme => ({

});

export default  withStyles(styles)(View);

