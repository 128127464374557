import React from "react";
import {withStyles} from "@material-ui/core";
import noImage from "../../../../../img/no-image.png";
import LabelUploadImage from "../../../../../components/LabelUploadImage";

const View = ({favicon, handleFiles, classes}) => (

    <figure className={classes.image}>
        <LabelUploadImage htmlFor="assistant_avatar"/>
        <input id="assistant_avatar" type="file" accept="image/jpeg,png,jpg,gif,svg" hidden
               className="image-upload__input"
               onChange={handleFiles}/>

        <img src={(favicon) ? `//${favicon.server}/estorage-s80-cscale/${favicon.url}` : String(noImage)}/>
    </figure>


);

const styles = theme => ({
    image: {
        width: 40,
        height: 40,
        margin: 0,
        display: 'block',
        position: 'relative',
        '& img': {
            width: '100%',
            height:'100%',
            borderRadius: 40
        }
    },
});





export default withStyles(styles)(View);
