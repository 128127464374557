import {ResultList, ResultOne} from "newkitleadforms";
import React from "react";
import {connect} from "react-redux";
import {t} from "leadformsquizentities/translate";
import {ResultInterface} from 'leadformsquizentities/Result';
import {ThunkDispatch} from "redux-thunk";
import {store} from "../../../../_services";
import {updateQuizData} from "../../../../_actions";
import BranchResult from "../../../components/BranchResult";




const View = ({isQuizBot, items, title, whitelabel, quizTitle, countQuestion, activeQuestionNumber, progress, colorTheme, buttonNextCaption,adminHandler}: {isQuizBot: boolean, items: ResultInterface[], title: string, whitelabel: boolean, quizTitle: string, countQuestion: number, activeQuestionNumber: number, progress: number, colorTheme: string, buttonNextCaption: string, adminHandler: Function }) => {
    const [branchResultIndex, setBranchResultIndexHandler] = React.useState(null);


    if (items.length === 1) {
        return <div style={{background: 'white', width: '100%', borderRadius: 6, overflow: 'hidden', height: isQuizBot ? 550 : '100%', margin: 'auto'}}>
            <ResultOne
                title={title}
                item={items[0]}
                buttonHandler={() => {
                }}
                buttonNextCaption={items[0].button ? items[0].button : t('Далее')}
                indexKey={0}
                isAdmin={true}
                whitelabel={whitelabel}
                quizTitle={quizTitle}
                adminHandler={(resultIndex: any, val: any) => {
                    //TODO refactor
                    if(val === 'open_settings') {
                        setBranchResultIndexHandler(resultIndex)
                    } else {
                        adminHandler(resultIndex, val)
                    }
                }}

            />
            {branchResultIndex !== null && <BranchResult closeHandler={() => setBranchResultIndexHandler( null)}
                                                         resultIndex={branchResultIndex}/>}
        </div>
    }
    return (<div style={{background: 'white', width: '100%', borderRadius: 6, overflow: 'hidden', height: isQuizBot ? 550 : 'auto', margin: 'auto', maxWidth: isQuizBot ? 375 : 'none'}}>
        <ResultList
            title={title}
            items={items}
            nextQuestionHandler={() => {
            }}
            prevQuestionHandler={() => {
            }}
            buttonNextCaption={buttonNextCaption}
            isAdmin={true}
            serialQuestionNumber={activeQuestionNumber}
            countQuestion={countQuestion}
            progress={progress}
            isDisabledPrevButton={false}
            isDisabledNextButton={false}
            whitelabel={whitelabel}
            quizTitle={quizTitle}
            adminHandler={(resultIndex: any, val: any) => {
                //TODO refactor
                if(val === 'open_settings') {
                    setBranchResultIndexHandler(resultIndex)
                } else {
                    adminHandler(resultIndex, val)
                }
            }}

        />
        {branchResultIndex !== null && <BranchResult closeHandler={() => setBranchResultIndexHandler( null)}
                                                       resultIndex={branchResultIndex}/>}
    </div>);
}


const mapStateToProps = (state: any) => {
    const quizData = state.quizData.toJS();
    const isNewTheme = quizData.isNewTheme;

    const progress = Math.round(100 * quizData.questions.length / (quizData.questions.length + (isNewTheme ? 1 : 0)));
    const colorTheme = true !== quizData.isEmpty ? quizData.info.design.colors.main : '';

    return {
        colorTheme: colorTheme,
        buttonNextCaption: quizData.info.results.button ? quizData.info.results.button : t('Далее'),
        progress: progress,
        activeQuestionNumber: quizData.questions.length + 1,
        countQuestion: quizData.questions.length + 1,
        quizTitle: quizData.info.title,
        whitelabel: !!quizData.whitelabel,
        title: quizData.info.results.title ? quizData.info.results.title : t('Предложения для Вас'),
        items: quizData.info.results ? quizData.info.results.items.map((item: any) => {
                return {
                    key: item.key,
                    value: item.value ? item.value : t('Предложения'),
                    image: item.image,
                    checked: false,
                    title: item.title,
                    salePercent: item.salePercent,
                    url: item.url,
                    branchesCount: item.displayConditions ? item.displayConditions.length : 0,
                    description: item.description,
                    button: item.button,
                }
            }
        ) : [],
        isQuizBot: quizData.isQuizBot
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {

    return {
        adminHandler: (resultIndex: any, val: any) => {
            const quizData = store.getState().quizData.toJS();

            if (resultIndex === null) {
                return;
            }
            if (val === 'add_title') {
                quizData.info.results.items[resultIndex]['title'] = t('Заголовок');
                store.dispatch(updateQuizData(quizData));
            } else if(val === 'add_description') {
                quizData.info.results.items[resultIndex]['description'] = t('Описание');
                store.dispatch(updateQuizData(quizData));
            } else if(val === 'add_sale') {
                quizData.info.results.items[resultIndex]['salePercent'] = 15;
                store.dispatch(updateQuizData(quizData));
            }
        }
    }
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(View);
