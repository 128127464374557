
import {compose, lifecycle, withState, withHandlers} from 'recompose';
import { connect } from 'react-redux';

import View from './View';

export default compose(
    connect(
        (state) => ({
            quizData: state.quizData.toJS()
        }),
        (dispatch) => {
            return {
                dispatch
            }
        }
    ),
    withState('tabActive', 'setTabActive', 'Site'),

    withHandlers({
        copyToClipboard: (props) => (code) => {
            let textField = document.createElement('textarea')
            textField.innerText = code.replace(/\s+/g, ' ').trim();
            document.body.appendChild(textField)
            textField.select()
            document.execCommand('copy')
            textField.remove()
        },
        changeTabActiveHandler: (props) => (e, tab) => {
             props.setTabActive(tab)
        }


    }),

    lifecycle({

    })
)(View);