import React from "react";
import {FormControlLabel, Switch, TextField, Button, Typography, Link} from "@material-ui/core";
import NotificationComponent from "../../../../components/Notification";
import {t} from "leadformsquizentities/translate";

const View = ({
                  disabledHandler,
                  data,
                  changeDataHandler,
                  saveDataHandler,
                  successSave
              }) => {

    return (

        <div>

            <FormControlLabel
                control={
                    <Switch
                        checked={true}
                        onChange={disabledHandler}
                    />
                }
                label={t("VK пиксель")}
            />
            <Link target="_blanc" href="https://leadforms.ru/instructions/vk">Смотреть инструкцию</Link>

            <TextField
                fullWidth
                onChange={changeDataHandler}
                value={data}
                placeholder="ID ПИКСЕЛЯ"
                InputProps={{
                    endAdornment: (<Button onClick={saveDataHandler}>сохранить</Button>)
                }}
            />

            {successSave &&
            <NotificationComponent shadowless type="feedback" message="Сохранено" variant="rounded" color="success"/>
            }
            <Typography>
                <small>Пример: VK-RTRG-356664-2oNBe</small><br/><br/>
                <small>
                    Leadforms отправляет следующие события:
                    <br/> - <strong>Посетитель открыл квиз</strong> leadforms_open
                    <br/> - <strong>Посетитель нажал на кнопку стартовой страницы</strong> leadforms_start
                    <br/> - <strong>Посетитель прошёл вопрос N</strong> leadforms_stepN
                    <br/> - <strong>Посетитель оставил контакты</strong> leadforms_finish
                </small>
            </Typography>


        </div>
    )
}


export default (View);
