import React from "react";
import {config} from "../../../../../_services";
import Block from "./Block";
import StandartButton from "./StandartButton";
import CustomButton from "./CustomButton";

import {
   Tabs,
   Tab,
   Paper,
   TextField,
   Button,
   Grid,
   FormControlLabel,
   Checkbox,
   Typography

} from "@material-ui/core";

import {
   FileCopy
} from "@material-ui/icons";


export default class Site extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            typeButtonQuiz: 'StandartButton',
            autoOpen: false
        };
        this.changeTypeButtonQuiz = this.changeTypeButtonQuiz.bind(this)
    }


  

    changeTypeButtonQuiz(e, value) {
        let newState = this.state
        newState.typeButtonQuiz = value
        this.setState(newState);
    }

    changeAutoOpen(e) {
        let newState = this.state
        newState.autoOpen = e.target.value
        this.setState(newState)
    }

    changeAutoOpenType(type) {
        let newState = this.state
        newState.autoOpen = type
        this.setState(newState)
    }


    render() {
        const {quizData} = this.props
        const color = this.state.background
        const code =
            `<!-- Leadforms script start -->
<script src="${config.scriptUrl}/v1.js" type="application/javascript"></script>
<script>
    document.addEventListener("DOMContentLoaded", function() {
        Leadforms.init({
            id: '${quizData.id}',
            autoOpen: ${this.state.autoOpen !== false && !this.state.autoOpen ? 0 : this.state.autoOpen}
        });
    });
</script>
<!-- Leadforms script end -->`.replace(/\s+/g, ' ').trim();




        return (
            <Grid>
                <Grid>
                    <br/>
                    <Typography variant="h6">1. Вставьте этот код в блок head в начале страницы</Typography>
                    <Grid container>
                        <Grid xs={12} sm={8} item>
                            <TextField 
                                fullWidth
                                multiline
                                variant="filled"
                                margin="dense"
                                InputProps={{
                                    readOnly: true
                                }}
                                rows={2} 
                                value={code} 
                            />

                            <Button onClick={this.props.copyToClipboard.bind(this, code)}> 
                                <FileCopy/> скопировать
                            </Button>
                        </Grid>
                         <Grid 
                            xs={12} 
                            sm={4} 
                            item 
                            container 
                            direction="column"
                            alignItems="flex-end"
                        >
                             <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={(this.state.autoOpen === false) ? false : true} 
                                        onChange={this.changeAutoOpenType.bind(this, this.state.autoOpen === false ? 0 : false)}
                                    />
                                }
                                label="Автооткрытие"
                              />
                            {this.state.autoOpen !== false && 
                                <React.Fragment>
                                <TextField 
                                    label="Показывать через (сек)"                                
                                    type="number" 
                                    onChange={this.changeAutoOpen.bind(this)}
                                    value={this.state.autoOpen} 
                                    InputProps={{
                                        step: 1,
                                        min: 0
                                    }}
                                />
                                </React.Fragment>
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid>
                    <br/><br/>
                    <Typography variant="h6">2. Добавьте квиз в нужное место</Typography>
                    <br/>
                    <Paper square>
                        <Tabs
                            value={this.state.typeButtonQuiz}
                             onChange={this.changeTypeButtonQuiz}   
                        >
                                    <Tab value="StandartButton" label="Коструктор кнопки"/>
                                    <Tab value="CustomButton" label="Своя кнопка"/>
                                    <Tab value="Block" label="Блоком на сайт"/>
                        </Tabs>
                   </Paper>
                    <br/><br/>

                    {this.state.typeButtonQuiz === 'Block' &&
                          <Block
                            copyToClipboard={this.props.copyToClipboard}
                            quizData={quizData}
                        />
                    }  
                    {this.state.typeButtonQuiz === 'StandartButton' &&
                          <StandartButton
                            copyToClipboard={this.props.copyToClipboard}
                            quizData={quizData}
                        />
                    }  
                    {this.state.typeButtonQuiz === 'CustomButton' &&
                          <CustomButton
                            copyToClipboard={this.props.copyToClipboard}
                            quizData={quizData}
                        />
                    }  

                    
                </Grid>

            </Grid>
        )
    }
}