import {compose, withState, withHandlers} from "recompose";
import {config} from "../../../../_services";
import {orderService} from "../../../../_api";
import View from "./View";
import connect from "react-redux/es/connect/connect";


const calculatePrice = (payType) => {
    switch (payType) {
        case 'unlimit_one':
            return config.robokassa.price_unlimit_one
        case 'unlimit_10':
            return config.robokassa.price_unlimit_10;
        default:
            return config.robokassa.price_unlimit_all;
    }
}


export default compose(
    connect(
        state => ({
            quizzes: state.quizzes.toJS().list
        })
    ),

    withState('payType', 'setPayType', 'unlimit_one'),
    withState('OutSum', 'setOutSum', (props) => calculatePrice(props.payType)),
    withState('MrchLogin', 'setMrchLogin', config.robokassa.mrh_login),
    withState('SignatureValue', 'setSignatureValue', ''),
    withState('isTest', 'setIsTest', config.robokassa.isTest),
    withState('InvoiceID', 'setInvoiceID', ''),
    withState('selectQuizId', 'setSelectQuizId', 0),

    withHandlers(() => {
        let form = null
        return {
            onRef: () => (ref) => (form = ref),
            changePayTypeHandler: props => (e) => {
                props.setPayType(e.target.value)
                props.setOutSum(calculatePrice(e.target.value))
            },
            changeSelectQuizIdHandler: props => (e) => {
                props.setSelectQuizId(e.target.value)
            },
            payHandler: props => () => {
                orderService.getOrder({
                    mrh_login: config.robokassa.mrh_login,
                    price: props.OutSum,
                    type: props.payType,
                    isTest: props.isTest,
                    quizId: props.selectQuizId
                }).then(function (r) {
                    window.location.replace(r.payUrl);
                    props.setSignatureValue(r.crc)
                    props.setInvoiceID(r.order.id)
                }).then(function () {
                    form.submit()
                });
            }
        }
    })
)(View);
