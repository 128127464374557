import React from "react";
import {Select, MenuItem, FormControl, InputLabel, Input} from "@material-ui/core";
import {compose, withHandlers, withState} from "recompose";

const variantHandler = (handler,e) => {
    handler({operation: 'eq', value:  e.target.value})
}


const AnswersInputs = ({question, handler, condition}) =>
    <FormControl fullWidth>
        <InputLabel>выбран ответ</InputLabel>
        <Select onChange={variantHandler.bind(this, handler)} fullWidth value={condition ? condition.value : null}>
            {question.answers.map((answer, key) =>
                <MenuItem key={key} value={answer.id}>{answer.title}</MenuItem>
            )}
            {question.other && <MenuItem key={'other'} value={'другое'}>{'другое'}</MenuItem>}
        </Select>
    </FormControl>

const AnswersTextAreaView = ({onChangeHandler, onBlurHandler, value}) =>
    <FormControl fullWidth>
        <InputLabel>содержит</InputLabel>
        <Input  placeholder={'введите слово'} onBlur={onBlurHandler} onChange={onChangeHandler} fullWidth value={value}/>
    </FormControl>

const AnswersTextArea = compose(
    withState('value', 'setValue', (props) => props.condition ? props.condition.value : null),
    withHandlers({
        onBlurHandler: props => () => {
            props.handler({operation: 'like', value:  props.value})
        },
        onChangeHandler: props => (e) => {
            props.setValue(e.target.value)
        }
    })
)
(AnswersTextAreaView);

export default ({question, handler, condition}) => {
    switch (question.type) {
        case 'answers':
        case 'images':
        case 'select':
            return <AnswersInputs question={question} handler={handler} condition={condition}/>;
        case 'input':
            return <AnswersTextArea  handler={handler} condition={condition}/>;
        default:
            return null;
    }
}