import {Map} from 'immutable'
import {createReducer} from 'redux-act'

import {
    deleteQuizAnswer,
    setQuizAnswers,
    signOutSuccess
} from '../_actions'


const entityToMap = (data) => {
    return new Map(data)
}

const defaultState = entityToMap({});

export const quizzesAnswers = createReducer((on) => {
    on(setQuizAnswers, (state, data) => {
        let items = {}
        data.answers.forEach(function(answer){
            items[answer.id] = answer;
        })
        return state.set(data.quizId,  {items: items, count: data.meta.count, activePage: data.meta.page, limit: data.meta.limit});
    })
    on(deleteQuizAnswer, (state, data) => {
        let newItems = state.get(data.quizId).items;
        delete newItems[data.answerId];
        return state.set(data.quizId,  {items: newItems});
    })

    on(signOutSuccess, () => defaultState)

}, defaultState)




