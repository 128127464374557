import React from "react";
import {FormControlLabel, Switch, TextField, Button, Typography, Link} from "@material-ui/core";
import {t} from "leadformsquizentities/translate";
import NotificationComponent  from "../../../../components/Notification";
const View = ({
                  disabledHandler,
                    data,
                  changeDataHandler,
                  saveDataHandler,
                  successSave
}) => { 

    return (

    <div>

        <FormControlLabel
            control={
                <Switch
                    checked={true}
                    onChange={disabledHandler}
                />
            }
            label={t("U-ON Travel")}
        />
        <Link target="_blanc" href="https://leadforms.ru/instructions/u-on_travel">Смотреть инструкцию</Link>
            <TextField
                    fullWidth
                    onChange={changeDataHandler} 
                    value={data} 
                    placeholder="API-ключ"
                    InputProps={{
                        endAdornment: (<Button onClick={saveDataHandler}>сохранить</Button>)
                    }}
                />

        {successSave &&
        <NotificationComponent  shadowless type="feedback" message="Сохранено" variant="rounded" color="success"/>
        }
                 <Typography>
                <small>Пример: D383LRl6jdl3k4ct0J2N</small><br/><br/>
                <small>
                    Настройки -> Интеграции -> API / WebHooks -> Ваш API-ключ
                </small>
                 </Typography>

        
    </div>
)}


export default (View);
