import {answerService, quizzesService} from '../_api';
import {setQuizData, cpCompleteLoading, cpAddError, cpStartLoading, cpAddMessage, updateQuestion} from './';
import {validateAnswer} from '../_services';
import arrayMove from "array-move";
import {needRemoveConditions} from "../_services/branchQuestionService";
import {getQuestionById, getQuestionIndexById} from "../_services/questionService";

export const uploadImageAnswer = (answerId, answerData, file) => (dispatch) =>{
    dispatch(cpStartLoading());
    quizzesService.uploadFile(file)
        .then(
            res => {
                answerData.image  = {server: res.server, url: "images/" + res.name};
                dispatch(updateAnswer(answerId, answerData));
                dispatch(cpCompleteLoading());
            },
            error => {
                dispatch(cpAddError(error));
                dispatch(cpCompleteLoading());
            }
        );
};

export const addAnswer = (answerData) => (dispatch, getState) => {
    const quizData = getState().quizData.toJS();
    const quizState = getState().quizState;
    const question = quizData.questions[quizState.activeKeyItem];

    answerData = validateAnswer(answerData);

    answerService.add(quizData.id, question.id, answerData).then(
        newAnswer => {
            const quizData = getState().quizData.toJS();
            quizData.questions[quizState.activeKeyItem].answers.push(newAnswer);
            dispatch(setQuizData(quizData));
        },
        error => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    );
};

export const updateAnswer = (answerId, answerData) => (dispatch, getState) => {
    const quizData = getState().quizData.toJS();
    const quizState = getState().quizState;
    const question = quizData.questions[quizState.activeKeyItem];

    answerData = validateAnswer(answerData);
    let indexAnswer = null
    question.answers.forEach((val, index) => {
        if(val.id === answerId) indexAnswer = index
    })

    answerService.update(quizData.id, question.id, answerId, answerData).then(
        updateAnswer => {
            quizData.questions[quizState.activeKeyItem].answers[indexAnswer] = updateAnswer;
            dispatch(setQuizData(quizData));
        },
        error => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    );
}

export const sortAnswer = (newIndex, oldIndex) => (dispatch, getState) => {
    dispatch(cpStartLoading());
    const quizData = getState().quizData.toJS()
    const quizState = getState().quizState
    const question = quizData.questions[quizState.activeKeyItem];

    question.answers = arrayMove(question.answers, oldIndex, newIndex);

    let answerIdsBySort = [];
    question.answers.forEach(answer => {
        answerIdsBySort.push(answer.id)
    });
    quizData.questions[quizState.activeKeyItem] = question;




    answerService.sort(quizData.id, question.id, answerIdsBySort).then(
        () => {
            dispatch(setQuizData(quizData));
            dispatch(cpCompleteLoading());
        },
        error => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    );
}


export const deleteAnswer = (answerId) => (dispatch, getState) => {
    const quizData = getState().quizData.toJS()
    const quizState = getState().quizState
    const question = quizData.questions[quizState.activeKeyItem];
    let indexAnswer = null
    question.answers.forEach((val, index) => {
        if(val.id === answerId) indexAnswer = index
    })

    answerService.remove(quizData.id, question.id, answerId).then(
        () => {
            const quizData = getState().quizData.toJS()
            quizData.questions[quizState.activeKeyItem].answers.splice(indexAnswer, 1)
            dispatch(setQuizData(quizData));
            return quizData;
        },
        error => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    ).then(quizData => {
        needRemoveConditions(quizData).forEach((questionConditions, questionId) => {
            let question = getQuestionById(questionId);
            question.displayConditions = questionConditions;
            quizData.questions[getQuestionIndexById(questionId)] = question;
            dispatch(updateQuestion(questionId, question));
            dispatch(cpAddMessage('Все условия показа, связанные с удаляемым вопросом были удалены'));
        });
    });;
}
