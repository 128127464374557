import React from "react";

import {Dialog, DialogTitle, DialogContent, Button, Grid, DialogActions, TextField, Checkbox, FormControlLabel} from "@material-ui/core";

const View = ({
    showModalAdditionalUser, closeModalAdditionalUser, haveSite, changeSiteStatus, handleSubmitUpdateUser

}) => (
   <Dialog
        open={showModalAdditionalUser}
        onClose={closeModalAdditionalUser}  
    >
      <DialogTitle>Дополните свой профиль, и получите  10 бесплатных заявок</DialogTitle>
      <DialogContent>
         <form  onSubmit={handleSubmitUpdateUser}>
        <TextField margin="normal" fullWidth required placeholder="имя"  name="name"/>
        <TextField margin="normal" fullWidth required placeholder="телефон" name="phone"/>
        {haveSite && <TextField  margin="normal" fullWidth required placeholder="ваши сайты (через запятую), куда вы хотите подключить квиз-форму"  name="site"  />}
        {!haveSite && <TextField margin="normal" fullWidth  rows="2" multiline  required placeholder="напишите статус разработки сайта, куда вы хотите подключить квиз-форму" name="site" />}
         <FormControlLabel
         fullWidth
        control={
          <Checkbox
            checked={!haveSite}
            onChange={changeSiteStatus}
          
          />
        }
        label="еще нет сайта"
      />
      <Grid item>
       <Button  variant="contained" type="submit" color="primary">Получить заявки</Button>
       </Grid>
       </form>
      
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModalAdditionalUser}  >Закрыть</Button>
      </DialogActions>
    </Dialog>



);



export default (View);