import React from "react";
import FullScreenDialog from '../../../../../components/FullScreenDialog';
import {FormControlLabel, Switch, TextField, Button, Typography, Link} from "@material-ui/core";
import {t} from "leadformsquizentities/translate";
import NotificationComponent  from "../../../../components/Notification";
const View = ({
                  disabledHandler,
                    data,
                  changeDataHandler,
                  saveDataHandler,
                  instructionData,
                  closeInstructionHandler,
                  openInstructionHandler,
                  successSave


}) => { 

    const instructionImage = instructionData ? require(`../../../../img/instruction_analytic/${instructionData}.png`) : null
    return (

    <div>

        <FormControlLabel
            control={
                <Switch
                    checked={true}
                    onChange={disabledHandler}
                />
            }
            label={t("Яндекс.Метрика")}
        />
         <Link target="_blanc" href="https://leadforms.ru/instructions/yandexmetrika">Смотреть инструкцию</Link>

            <TextField
                    fullWidth
                    onChange={changeDataHandler} 
                    value={data} 
                    placeholder="Идентификатор отслеживания" 
                    InputProps={{
                        endAdornment: (<Button onClick={saveDataHandler}>сохранить</Button>)
                    }}
                />

        {successSave &&
        <NotificationComponent  shadowless type="feedback" message="Сохранено" variant="rounded" color="success"/>
        }
                 <Typography>
                <small>Пример: 46119039</small><br/>
                <small>Leadforms отправляет в ваш счётчик Яндекс.Метрики цели: <br/>
                    - <strong>Посетитель открыл квиз</strong> (leadforms_open) <br/>
                    - <strong>Посетитель нажал на кнопку стартовой страницы</strong> (leadforms_start) <br/>
                    - <strong>Посетитель прошёл вопрос N</strong> (leadforms_stepN) <br/>
                    - <strong>Посетитель оставил контакты</strong> (leadforms_finish).

                    <br/>Вы можете настроить определение этих целей в цели Яндекс Метрике.</small>
                <br/><br/>
                <p>Как настроить цели прохождения квиза в Яндекс Метрике:</p>
                <ol>
                    <li>
                        <a href="https://metrika.yandex.ru/" target="_blank">В Яндекс Метрике</a> выберите счётчик, и перейдите в раздел <strong>Настройка</strong> на вкладку <strong>Цели</strong> и
                        <br/>
                        нажмите <strong>Добавить цель</strong>
                        <br/>
                        <small style={{cursor: 'pointer'}} onClick={openInstructionHandler.bind(this, 'ya-1')}>пример</small>
                    </li>
                    <li>Ведите название цели (<span className="font-italic">Посетитель открыл квиз</span> или <span>Посетитель оставил контакты</span>),
                        <br/>
                        выберите Тип условия - <strong>JavaScript-событие</strong>,  и введите идентификатор вашей цели
                        <br/>
                        (<span>leadforms_open</span> или <span className="font-italic">leadforms_finish</span>),
                        <br/>затем нажмите  <strong>Добавить цель</strong>
                        <br/>
                        <small style={{cursor: 'pointer'}} onClick={openInstructionHandler.bind(this, 'ya-2-1')}>пример 1</small>
                        &nbsp;
                        <small style={{cursor: 'pointer'}} onClick={openInstructionHandler.bind(this, 'ya-2-2')}>пример 2</small>
                    </li>
                </ol>
                 </Typography>

                {instructionData && 
                     <FullScreenDialog modalTitle={'Назад'} handleClose={closeInstructionHandler}>
                         <img style={{'max-width': '100%'}} src={String(instructionImage)} />
                    </FullScreenDialog>
                }
        
    </div>
)}


export default (View);
