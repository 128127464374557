import {quizzesService} from '../_api';
import {cpAddError, cpCompleteLoading, cpStartLoading, setQuizData, updateQuizData} from './';

export const uploadImage = (file, callback) => (dispatch, getState) =>{
    dispatch(cpStartLoading());
    quizzesService.uploadFile(file)
        .then(
            res => {
                callback(res)
                dispatch(cpCompleteLoading());
            },
            error => {
                dispatch(cpAddError(error))
                dispatch(cpCompleteLoading());
            }
        );
}

export const uploadAssistantAvatar = (file) => (dispatch, getState) =>{
    dispatch(cpStartLoading());
    quizzesService.uploadFile(file)
        .then(
            res => {
                let quizData = getState().quizData.toJS()
                quizData.info.assistant.avatar = {server: res.server, url: "images/" + res.name}
                dispatch(updateQuizData(quizData))
                dispatch(cpCompleteLoading());
            },
            error => {
                dispatch(cpAddError(error));
                dispatch(cpCompleteLoading());
            }
        );
}

export const uploadFavicon = (file) => (dispatch, getState) =>{
    dispatch(cpStartLoading());
    quizzesService.uploadFile(file)
        .then(
            res => {
                let quizData = getState().quizData.toJS()
                quizData.info.startPage.favicon = {server: res.server, url: "images/" + res.name}
                dispatch(updateQuizData(quizData))
                dispatch(cpCompleteLoading());
            },
            error => {
                dispatch(cpAddError(error));
                dispatch(cpCompleteLoading());
            }
        );
}


export const removeAmocrm = (quizId) => (dispatch, getState) =>{
    dispatch(cpStartLoading());
    quizzesService.removeAmocrm(quizId).then(
        res => {
            quizzesService.getQuiz(quizId).then(
                quizData => {
                    quizData.notifications.amocrm = {enabled: false}
                    dispatch(updateQuizData(quizData))
                    dispatch(cpCompleteLoading());
                },
                // error => dispatch(failure(error))
            );

        },
        error => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    );
}



