import {compose, withState, withHandlers, lifecycle} from 'recompose';
import { connect } from 'react-redux';
import View from './View';
import {updateQuizData, removeAmocrm} from "../../../../../_actions";
import React from "react";
import {testAmocrm} from "../../../../../_api/crmService";
import {Chip} from "@material-ui/core";
import {values as valuesAdditionalFieldLeadForms} from "../../../../../entities/Integrations/AdditionalFieldLeadForms";
import {getQuestionIndexById} from '../../../../../_services/questionService'
import {config} from "../../../../../_services";
import {quizzesService} from "../../../../../_api";

const checkConnection = (props) => {
    props.setAmoCrmLoading(true);

    testAmocrm(props.quizData.id).then(
        (r) => {
            if(!r.error_code) {
                props.setAmoCrmAccountName(r.name)
                let pipelines = {};
                Object.values(r.pipelines).forEach((pipeline) => {
                    pipelines[pipeline.id] = {id: pipeline.id, name: pipeline.name};
                });
                props.setPipelines(pipelines);

                let users = {};
                Object.values(r.users).forEach((user) => {
                    users[user.id] = {id: user.id, name: user.name};
                });
                props.setUsers(users);

                let amoCrmAdditionalFieldLis = {};
                r.custom_fields.forEach((field) => {
                    amoCrmAdditionalFieldLis[field.id] = {id: field.id, name: field.name};
                });
                props.setAmoCrmAdditionalFieldList(amoCrmAdditionalFieldLis);
            }

            props.setAmoCrmMessage(
                (r.error_code) ? <Chip
                        variant="outlined"
                        color="red"
                        label={`Ошибка: ${r.message}`}
                    /> : null);

            props.setAmoCrmLoading(false);
        },
        () => {
            props.setAmoCrmMessage('Ошибка');
            props.setAmoCrmLoading(false);
        }
    );
}
export default compose(
    connect(
        state => ({
            quizData: state.quizData.toJS(),
            websocketPrivateChannel: state.websocket.toJS().privateChannel,
            userInfo: state.userInfo.toJS(),
            amoCrmData: state.quizData.toJS().notifications.amocrm
        }),
        { updateQuizData, removeAmocrm}
    ),
    withState("amoCrmAdditionalFieldList", "setAmoCrmAdditionalFieldList", null),
    withState("additionalFieldLeadforms", "setAdditionalFieldLeadforms", 0),
    withState("additionalFieldAmoCrm", "setAdditionalFieldAmoCrm", 0),

    withState("amoCrmAccountName", "setAmoCrmAccountName", null),
    withState("amoCrmMessage", "setAmoCrmMessage", null),
    withState("amoCrmLoading", "setAmoCrmLoading", false),
    withState("pipelines", "setPipelines", null),
    withState("users", "setUsers", null),

    withHandlers({
        onChangeAdditionalFieldLeadforms: props => (e) => {
            let r;
            if (valuesAdditionalFieldLeadForms[e.target.value]) {
                r = valuesAdditionalFieldLeadForms[e.target.value];
            } else {
                const questionIndex = getQuestionIndexById(e.target.value)
                r = {name: `вопрос №${questionIndex} ${props.quizData.questions[questionIndex].title}`, value: e.target.value, type: 'question'}
            }
            props.setAdditionalFieldLeadforms(r)
        },
        deleteAdditionalFieldHandler: props => (deletedKey) => {
            let amoCrmData = props.amoCrmData;
            amoCrmData.additionalFields.splice(deletedKey, 1);

            let quizData = props.quizData
            quizData.notifications.amocrm = amoCrmData
            props.updateQuizData(quizData)
        },
        addAdditionalFieldHandler: props => () => {
            let amoCrmData = props.amoCrmData;
            if(undefined === amoCrmData.additionalFields) {
                amoCrmData.additionalFields = [];
            }
            amoCrmData.additionalFields.push({
                amoCrm: props.additionalFieldAmoCrm,
                leadforms: props.additionalFieldLeadforms
            })
            props.setAdditionalFieldLeadforms(0);
            props.setAdditionalFieldAmoCrm(0);

            let quizData = props.quizData
            quizData.notifications.amocrm = amoCrmData
            props.updateQuizData(quizData)
        },
        changeAmoCrmData: (props) => (key, value) => {
            let amoCrmData = props.amoCrmData
            amoCrmData[key] = value;
            let quizData = props.quizData
            quizData.notifications.amocrm = amoCrmData
            props.updateQuizData(quizData)

        },
        authAmo: (props) => {
            var w = 750;
            var h = 580;
            var dual_screen_left = window.screenLeft !== undefined ? window.screenLeft : 0;
            var dual_screen_top = window.screenTop !== undefined ? window.screenTop : 0;

            var width = window.innerWidth
                ? window.innerWidth
                : (document.documentElement.clientWidth ? document.documentElement.clientWidth : window.innerWidth);

            var height = window.innerHeight
                ? window.innerHeight
                : (document.documentElement.clientHeight ? document.documentElement.clientHeight : window.innerHeight);

            var left = ((width / 2) - (w / 2)) + dual_screen_left;
            var top = ((height / 2) - (h / 2)) + dual_screen_top;

            let user = JSON.parse(localStorage.getItem('user'));
            const url = `https://www.amocrm.ru/oauth?state=${JSON.stringify({quizId: props.quizData.id, token: user.token})}&client_id=${config.amoCrmClientId}&mode=post_message`;
            var new_window = window.open(url, 'РџСЂРµРґРѕСЃС‚Р°РІРёС‚СЊ РґРѕСЃС‚СѓРї РґР»СЏ РёРЅС‚РµРіСЂР°С†РёРё', 'scrollbars, status, resizable, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

            if (window.focus) {
                new_window.focus();
            }
        },
        removeAmoAccount: (props) => {
            props.removeAmocrm(props.quizData.id)
            props.setAmoCrmAdditionalFieldList(null)
            props.setAdditionalFieldAmoCrm(null)
            props.setAmoCrmAccountName(null)
            props.setAmoCrmMessage(null)
        }

    }),
    lifecycle({
        componentDidMount() {
            if(this.props.quizData.amocrm_code){
                checkConnection(this.props);
            }
            const channel = this.props.websocketPrivateChannel.channel(`private-App.User.${this.props.userInfo.id}`);
            const callBack =  (data) => {
                quizzesService.getQuiz(data.quizId).then(
                    quizData => {
                        quizData.notifications.amocrm.enabled = true;
                        this.props.updateQuizData(quizData);
                        checkConnection(this.props);
                    },
                    // error => dispatch(failure(error))
                );
            };
            channel.listen('.App\\Events\\AccessTokenAmoCrmToQuiz', callBack.bind(this));
        }
    })




)(View);


