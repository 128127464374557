import {compose, withHandlers} from 'recompose';
import {connect} from 'react-redux';

import { cpResetError, cpResetMessage } from '../../../_actions';

import LayoutView from './LayoutView';


export default compose(
    connect(
        state => ({
            userInfo: state.userInfo.toJS(),
            errorMessage: state.cpState.toJS().error,
            message: state.cpState.toJS().message
        }),
        {cpResetError, cpResetMessage}
    ),
    withHandlers({
        resetErrorHandler: props => () => {
            props.cpResetError();
        },
        resetMessageHandler: props => () => {
            props.cpResetMessage();
        }
    })
)(LayoutView);

