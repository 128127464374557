import { authHeader } from '../_services';
import {requestApi} from './requestApi';

export const testBitrix24 = (data) => {
    const requestOptions = {
        method: 'POST',
        headers:  authHeader({'Content-Type': 'application/json'}),
        body: JSON.stringify(data)
    };
    return requestApi(`test/bitrix24`, requestOptions);
}

export const testAmocrm = (quizId) => {
    const requestOptions = {
        method: 'GET',
        headers:  authHeader({'Content-Type': 'application/json'}),
    
    };
    return requestApi(`test/amoCrm/${quizId}`, requestOptions);
}

export const getUnisenderData = (key) => {
    const requestOptions = {
        method: 'GET',
        headers:  authHeader({'Content-Type': 'application/json'}),

    };
    return requestApi(`get_unisender_data/${key}`, requestOptions);
}

