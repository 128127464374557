import React from "react";
import {
    Typography,
    Button,
    withStyles,
    Grid,
    Card,
    CardContent,
    CardActions,
    FormControl,
    Select,
    MenuItem, InputLabel, TextField

} from "@material-ui/core";
import FullScreenDialog from '../../../../components/FullScreenDialog';
import {Visibility as VisibilityIcon, Check as CheckIcon} from "@material-ui/icons";
import PreviewQuiz from "../../../components/PreviewQuiz";



const View = ({nameCreatedQuiz, createdQuizId, setNameCreatedQuiz, setCreatedQuizId, createQuizHandler, open, closeHandler, templates, categoryTemplates, ChangeCategoryTemplatesHandler, classes, isLoading, PreviewQuizHandler, previewQuizId}) => (

    !open ? null : <FullScreenDialog modalTitle={'Новый квиз'} handleClose={() => {setCreatedQuizId(false);closeHandler();}}>

        {false !== createdQuizId ? <>
                <FormControl outlined>
                    <InputLabel shrink >
                        Название квиза
                    </InputLabel>
                    <TextField
                        autoFocus={true}
                        margin="normal"
                        fullWidth
                        value={nameCreatedQuiz}
                        placeholder="Название квиза"
                        onChange={(e) => setNameCreatedQuiz(e.target.value)}
                        variant="outlined"
                        helperText='Внутренне название квиза. Его увидите только вы в разделе "Мои квизы"'
                        InputProps={{
                            endAdornment: (<Button variant="contained" color={'primary'} disabled={!nameCreatedQuiz} onClick={createQuizHandler}>Создать</Button>)
                        }}
                    />
                </FormControl>

            </>
            : <>
        <Grid item sm={3}>
            <Typography className={classes.typeTitle}>Пустой шаблон</Typography>
            <Button variant="contained" onClick={() => setCreatedQuizId(null)}>Начать</Button>
        </Grid>
        <Grid item sm={9}>
            <Grid container>
                <Grid item sm={6}>
                    <Typography className={classes.typeTitle}>Готовые шаблоны</Typography>
                </Grid>
                <Grid item sm={6}>
                    <FormControl fullWidth>
                        <Select onChange={ChangeCategoryTemplatesHandler} fullWidth value={categoryTemplates}>
                            {Object.keys(templates).map((key) =>
                                <MenuItem key={key} value={key}>{key}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container>
            {(templates !== null && !isLoading) && templates[categoryTemplates].map((template) =>
                <Grid  md={6} className={classes.cardContainer}>
                <Card>
                    <img className={classes.cardMedia} src={`//${template.image.server}/blog-w337-h160-cscale/images/${template.image.url}`}/>
                    <CardContent>
                        <Typography variant="body2" component="p">
                            {template.title}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" onClick={() => {setCreatedQuizId(template.id);setNameCreatedQuiz(template.title)}}>
                            Выбрать
                            <CheckIcon/>
                        </Button>
                        <Button variant="contained" onClick={PreviewQuizHandler.bind(this, template.id)}>
                            Посмотреть
                            <VisibilityIcon/>
                        </Button>
                    </CardActions>
                </Card>
                </Grid>
            )}
            </Grid>
        </Grid>

        <PreviewQuiz previewQuizId={previewQuizId} onClose={PreviewQuizHandler.bind(this, false)} />
        </>}

    </FullScreenDialog>


);

const styles = theme => ({
    cardMedia: {
        width: '100%'
    },
    cardContainer: {
        padding: 10
    },
    typeTitle: {
        fontSize: 20,
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    }
});

export default withStyles(styles)(View);