import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {config} from '../../../_services';

const View = ({ classes, previewQuizId, isNewTheme, onClose}) => (
    !previewQuizId ? null :
    <Grid  
        container 
        justify="center"
        alignItems="center"
        className={classes.container} onClick={onClose}>
        <Grid className={isNewTheme ? classes.iframeNewContainer : classes.iframeContainer}>
            <iframe  scrolling="auto" frameBorder="0" src={`${config.quizUrl}/default/${previewQuizId}?withoutCloseHandler=true`}/>
            <Close className={classes.icon}/>
        </Grid>
    </Grid>
);

const styles = theme => ({
    icon:{
        position: 'absolute',
        color: '#7d7d7d',
        top: -35,
        right: -35,
        fontSize: 35,
        '@media(max-width: 1023px)':{
            top: -7,
            right: -7,
        },
    },
    iframeContainer:{
        position: 'relative',
        maxWidth: 1200,
        width: '100vw',
        minHeight: '100vh',
        '@media(min-width: 1024px)':{
            maxWidth: 1024,
            width: '80%',
            maxHeight: '75vh',
            minHeight: 560
        },
        '@media(min-width: 1201px)':{
            minHeight: 590
        },
        '@media(min-width: 1440px)':{
            height: 650
        },
        '& iframe': {
            width: '100%',
            height: '100%',
            position: 'absolute'
        }
    },
    iframeNewContainer: {
        position: 'relative',
        width: 1248,
        height: 640,
        '@media(max-width: 1260px)':{
            height: 560,
            width: 1092
        },
        '@media(max-width: 1100px)':{
            height: 400,
            width: 780
        },
        '& iframe': {
            borderRadius: 6,
            width: '100%',
            height: '100%',
            position: 'absolute'
        }
    },
    container: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'rgba(25,25,25,0.89)',
        position: 'fixed',
        zIndex: 9999,
     

    }
});

export default withStyles(styles)(View);
