import React from "react";
import {
    Grid,
    withStyles
} from "@material-ui/core";


const View = ({children, classes}) => (

    <Grid className={classes.container}>
        {children}
    </Grid>

);


const styles = theme => ({
    container: {
        position: 'relative',
        height: 850,
        width: '100%',
         '@media(min-width: 960px)':{
            height: 570,
        }

    },
});
export default withStyles(styles)(View);