import {QuizStateInterface} from "leadformsquizentities/QuizStateInterface";
import {SIGN_OUT_SUCCESS} from '../_actions'

const defaultState: QuizStateInterface = {
    loading: true,
    errorMessage: null,
    type: 'start',
    isPossibleSendClientForm: false,
    clientData: {
        answers: {},
        contacts: {}
    },
    contactsValidate: {
        phone: null,
        email: null,
        name: null
    },
    changeStepLoading: false,
    sendedClientForm: false,
    activeKeyItem: 0,
    serialActiveKeyItem: 1,
    form: {
        buttonPrevDisabled: true,
        buttonNextDisabled: false
    },
    discountOneStep: false,
    discountSeek: false,
    discount: null,
    additionalIsOpen: false,
    privacyAgreement: true,
    enabledResultIndexes: []
};

export const INIT_QUIZ_STATE = 'INIT_QUIZ_STATE';
export const SET_QUIZ_STATE_ITEM = 'SET_QUIZ_STATE_ITEM';

export const quizStateReducer = (state: QuizStateInterface = defaultState, action: QuizStateActionTypes): QuizStateInterface => {
    switch (action.type) {
        case INIT_QUIZ_STATE:
            return action.quizState;
        case SET_QUIZ_STATE_ITEM:
            let newState: QuizStateInterface = {...state};
            (newState as any)[action.key] = action.item as any;
            return newState;
        case SIGN_OUT_SUCCESS:
            return defaultState;
        default:
            return state;
    }
};

interface SetQuizStateActionInterface {
    type: typeof INIT_QUIZ_STATE,
    quizState: QuizStateInterface
}

export function initQuizSateAction(quizState: QuizStateInterface): SetQuizStateActionInterface {
    return {
        type: INIT_QUIZ_STATE,
        quizState: quizState
    }
}

interface SetQuizStateItemActionInterface {
    type: typeof SET_QUIZ_STATE_ITEM,
    key: keyof QuizStateInterface,
    item: any
}

interface SignOutActionInterface {
    type: typeof SIGN_OUT_SUCCESS
}


export function setQuizStateIem(key: keyof QuizStateInterface, item: any): SetQuizStateItemActionInterface {
    return {
        type: SET_QUIZ_STATE_ITEM,
        key: key,
        item: item
    }
}

export type QuizStateActionTypes = SetQuizStateActionInterface|SetQuizStateItemActionInterface|SignOutActionInterface;
