
import {compose, withState, withHandlers, lifecycle} from 'recompose';
import { connect } from 'react-redux';

import View from './View';
import {updateQuizData} from "../../../../../_actions";
import React from "react";


export default compose(
    connect(
        state => ({
            quizData: state.quizData.toJS(),
        }),
        { updateQuizData}
    ),
    withState('admitadData', 'setAdmitadData', props => (props.quizData.notifications.admitad ?? {enabled: false})),
    withState('showAdditional', 'setShowAdditional', false),
    withHandlers({
        changeNotificationCheckedHandler: props => () => {
            const admitadData = {...props.admitadData, enabled:!props.admitadData.enabled}
            props.setAdmitadData(admitadData);
            props.quizData.notifications.admitad = admitadData
            props.updateQuizData(props.quizData)
        },
        changeAdmitadData: (props) => (key, value) => {
            let admitadData = props.admitadData
            admitadData[key] = value
            props.setAdmitadData(admitadData);

            if(["domain", "token", "user", "country"].indexOf(key) !== -1){
                props.setShowAdditional(false);
            } else {
                let quizData = props.quizData
                quizData.notifications.admitad = props.admitadData
                props.updateQuizData(quizData)
            }
        }
    })
)(View);


