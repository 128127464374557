
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import View from './View';
import {addWebHook, removeWebHook} from "../../../../../_actions";
import React from "react";


export default compose(
    connect(
        state => ({
            quizData: state.quizData.toJS(),
            webHookList: state.quizData.toJS().notifications.webHooks,
        }),
        { addWebHook, removeWebHook }
    ),
    withHandlers({
        addWebHookHandler: props => (e) => {
            e.preventDefault()
            const data = new FormData(e.target);
            props.addWebHook(data.get('url'), props.quizData.id);
            e.target.reset()
        },
        removeWebhook: props => (webHookId) => {
            props.removeWebHook(webHookId, props.quizData.id);
        }
    })


)(View);