import React from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    Link,
    makeStyles,
    MenuItem,
    Select,
    Switch,
    TextField
} from "@material-ui/core";
import {t} from "leadformsquizentities/translate";

const View = ({
                  disableEnabled,
                  apiKey,
                  selectedItem,
                  changeDataHandler,
                  saveDataHandler,
                  showSaveButton,
                  loading,
                  message,
                  unisenderList,
                  showAdditional,
                  changeSelectedItem,
                  changeTagsHandler,
                  tags,
                  doubleOptin,
                  changeDoubleOptin


}) => {
    const classes = useStyles();
   return (

    <div>

        <FormControlLabel
            control={
                <Switch
                    checked={true}
                    onChange={disableEnabled}
                />
            }
            label={t("Unisender")}
        />
         <Link target="_blanc" href="https://leadforms.ru/instructions/unisender">Смотреть инструкцию</Link>
        <TextField
                fullWidth
                onChange={changeDataHandler}
                value={apiKey}
                placeholder="API KEY"
                InputProps={showSaveButton ? {
                    endAdornment: (<Button onClick={saveDataHandler}>сохранить</Button>)
                } : {}}
            />
            <div>
                <br/><br/>
                {loading && <p>Загрузка...</p>}
                {!loading && message &&
                <>{message}</>
                }
                <br/><br/>
                {showAdditional &&
                <>
                    <FormControl variant="filled"  className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                            Список
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            fullWidth
                            value={selectedItem}
                            onChange={(e) => changeSelectedItem(e.target.value)}
                            id="demo-simple-select-filled"
                        >
                            {null !== unisenderList && unisenderList.map((item) =>
                                <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <br/><br/>
                    <FormControl variant="filled"  className={classes.formControl}>
                        <TextField
                            fullWidth
                            onChange={changeTagsHandler}
                            value={tags}
                            placeholder="Метки, через запятую"
                        />
                    </FormControl>
                    <br/><br/>
                    <FormControl variant="filled"  className={classes.formControl}>
                        <InputLabel>
                            Подтверждение подписки
                        </InputLabel>
                        <Select
                            fullWidth
                            value={doubleOptin}
                            onChange={(e) => changeDoubleOptin(e.target.value)}
                        >
                            <MenuItem key={0} value={0}>Включено</MenuItem>
                            <MenuItem key={3} value={3}>Выключено</MenuItem>
                            <MenuItem key={4} value={4}>Включено только для новых контактов</MenuItem>
                        </Select>
                    </FormControl>
                </>

                }
            </div>
    </div>
)}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
    }
}));

export default (View);
