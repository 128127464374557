import React from "react";
import {Router, Route, Redirect} from "react-router-dom";
import {history} from "../_services/history";
import {eventSuccessPayPage, eventFailPayPage} from '../_services/events';
import LoginPage from "./pages/LoginPage";
import LoginTelegramPage from "./pages/LoginTelegramPage";
import RegisterPage from "./pages/RegisterPage";
import ResetPassword from "./pages/ResetPassword";
import RequestPassword from "./pages/RequestPassword";
import Layout from "./components/Layout";
import {compose, lifecycle} from "recompose";
import {connect} from "react-redux";
import {CircularProgress, withStyles, CssBaseline} from "@material-ui/core";
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import themes, {overrides} from "./themes";
import {store} from '../_services'
import {cpAddError, cpAddMessage, userActions} from '../_actions'

const theme = createMuiTheme({
    ...themes.default,
    ...overrides,
    typography: {
        useNextVariants: true,
    }
});

const styleLoading = theme => ({
    loadingContainer: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    loadingProgress: {
        marginLeft: 'calc(50vw - 43px)',
        marginTop: 'calc(50vh - 43px)'
    }

})

const LoadingView = ({classes}) =>
    <div className={classes.loadingContainer}>
        <CircularProgress className={classes.loadingProgress} size={86}/>
    </div>


const Loading = withStyles(styleLoading)(LoadingView)


const PrivateRouteView = ({component,  isAuthenticated, appLoading, ...rest}) => {
    return (
        <Route
            {...rest} render={props => (
      localStorage.getItem('user') ? ( (!isAuthenticated || appLoading ) ?
          <Loading/>  : React.createElement(component, props)
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    )}/>)
}

const PrivateRoute = compose(
    connect(
        state => ({
            isAuthenticated: state.userInfo.toJS().isEmpty ? false : true,
            appLoading: state.cpState.toJS().loading
        })
    )
)(PrivateRouteView);

const PublicRoute = ({component, ...rest}) =>
        <Route
            {...rest} render={props => (
      localStorage.getItem('user') ? (
        <Redirect
          to={{
            pathname: '/app',
          }}
        />
      ) : (
        React.createElement(component, props)
      )
    )}
        />
    ;


const AppView = (props) => (

    <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
            <PublicRoute exact path="/login" component={LoginPage}/>
            <PublicRoute exact path="/registrations" component={LoginTelegramPage}/>
            <PublicRoute exact path="/registrations/email" component={RegisterPage}/>
            <PublicRoute exact path="/request-password" component={RequestPassword}/>
            <PublicRoute exact path="/reset-password" component={ResetPassword}/>
            <PrivateRoute path="/app" component={Layout}/>
            <Route path="/success_pay" render={props =>  {eventSuccessPayPage(); store.dispatch(cpAddMessage('Оплата прошла успешно')); return <Redirect to={{pathname: '/app'}}/>}}/>
            <Route path="/fail_pay" render={props =>  {eventFailPayPage(); store.dispatch(cpAddError('Оплата не прошла')); return <Redirect to={{pathname: '/app'}}/>}}/>

            <Route path="/" exact render={() => <Redirect to="/app" />}/>
        </Router>

    </MuiThemeProvider>
);

export default compose(
    connect(
        state => ({}),
        (dispatch) => {
            return {
                dispatch
            }
        }
    ),
    lifecycle({
        componentDidMount() {
            if(localStorage.getItem('user')) this.props.dispatch(userActions.getUserInfo())
        }
    })
)(AppView);


