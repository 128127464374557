import { quizzesService } from '../_api';
import { setQuizData, setQuizzesList, setQuizAnswers, cpCompleteLoading, cpStartLoading, cpAddError, setOneToQuizzesList, deleteOneQuizzesList} from './';
import { validations, history } from '../_services';
import {eventCreateQuiz, eventUpdateQuiz} from '../_services/events';

export const quizzesActions = {
    getAnswers,
    deleteAnswer
};

export const updateQuizData = (quizData) => dispatch => {
    quizData = validations(quizData);
    dispatch(setQuizData(quizData));
    quizzesService.updateQuiz(quizData.id, quizData).then(
                user => {
                    eventUpdateQuiz();
                    return quizData
                },
                error => {
                    dispatch(cpAddError(error));
                    dispatch(cpCompleteLoading());
                }
            );
}

export const createQuiz = (copyQuizId, nameCreatedQuiz) => dispatch => {
    dispatch(cpStartLoading());
    quizzesService.createQuizz(copyQuizId).then(
        (data) => {
            data.info.name = nameCreatedQuiz;
            dispatch(updateQuizData(data))

            eventCreateQuiz();
            dispatch(setOneToQuizzesList(data));
            dispatch(setQuizData(data));
            history.push(`/app/quizzes/edit/${data.id}`);
            dispatch(cpCompleteLoading());
        },
        (error) => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    )
}

function getAnswers(quizId, page = 0) {
    return dispatch => {
        dispatch(cpStartLoading())
        quizzesService.getLeads(quizId, page)
            .then(
                answersData => {
                    dispatch(setQuizAnswers(quizId, answersData.data, answersData.meta));
                    dispatch(cpCompleteLoading());
                }
            );
    };
}

function deleteAnswer(quizId, answerId, page) {
    return dispatch => {
        quizzesService.deleteLead(quizId, answerId).then(
           () => dispatch(quizzesActions.getAnswers(quizId, page))
        );
    };
}

export const getQuizList = () => dispatch => {
    dispatch(cpStartLoading())
    quizzesService.getAll()
        .then(
            quizzes => {
                dispatch(setQuizzesList(quizzes));
                dispatch(cpCompleteLoading());
            },
            (error) => {
                dispatch(cpAddError(error));
                dispatch(cpCompleteLoading());
            }
        );
}

export const deleteQuiz = (quizId) => dispatch => {
    dispatch(cpStartLoading());
    quizzesService.deleteQuiz(quizId).then(
        () => {
            dispatch(deleteOneQuizzesList(quizId));
            dispatch(cpCompleteLoading());
        },
        (error) => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    )
}

export const addWebHook = (url, quizId) => (dispatch, getState) => {
    quizzesService.addWebHook(url, quizId).then(
        (webHook) => {
            let quizData = getState().quizData.toJS()
            quizData.notifications.webHooks.push(webHook)
            dispatch(setQuizData(quizData))
            dispatch(cpCompleteLoading());
        },
        (error) => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    )
}

export const removeWebHook = (webHookId, quizId) => (dispatch, getState) => {
    quizzesService.removeWebHook(quizId, webHookId).then(
        () => {
            let quizData = getState().quizData.toJS();
            quizData.notifications.webHooks = quizData.notifications.webHooks.filter((webHook) => {
                return webHook.id !== webHookId;
            });
            dispatch(setQuizData(quizData));
            dispatch(cpCompleteLoading());
        },
        (error) => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    )
}

export const downloadLeadsReport = (quizId) => dispatch => {
    dispatch(cpStartLoading());
    quizzesService.downloadLeadsReport(quizId).then(
        () => {
            dispatch(cpCompleteLoading());
        },
        (error) => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    )
}

export const removeTelegramAccount = (telegramUserId, quizId) => (dispatch, getState) => {
    quizzesService.removeTelegramAccount(quizId, telegramUserId).then(
        () => {
            let quizData = getState().quizData.toJS();
            quizData.notifications.telegramUsers = quizData.notifications.telegramUsers.filter((telegramUser) => {
                return telegramUser.id !== telegramUserId;
            });
            dispatch(setQuizData(quizData));
            dispatch(cpCompleteLoading());
        },
        (error) => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    )
}
