import {compose, lifecycle, withHandlers, withState} from 'recompose';
import { connect } from 'react-redux';
import {addAnswer, updateQuizData, copyQuestion, sortQuestion,  updateQuestion, deleteQuestion, addQuestion, cpAddMessage} from "../../../../_actions";
import {t} from "leadformsquizentities/translate";
import View from './View';
import {setQuizStateIem} from '../../../../_reducers/quizState.reducer'

export default compose(
    connect(
        (state) => {
            const quizData = state.quizData.toJS();
            const quizState = state.quizState;
            const questionActive = quizData.questions[quizState.activeKeyItem];
            return {
                quizData: quizData,
                quizState: quizState,
                color: quizData.info.design.colors.main,
                hasQuestionActiveText: !!questionActive.text,
                hasDiscount: !!quizData.info.discount,
                background: quizData.info.design.colors.main,
                questionActive: questionActive,
                isSliderMultiple: !!questionActive.sliderOptions.isMultiple,
                isNoRequiredQuestion: !!questionActive.isNoRequired,
                isNewTheme: !!quizData.isNewTheme,
                isQuizBot: !!quizData.isQuizBot,
        }},
        {addAnswer, updateQuizData, copyQuestion, sortQuestion, updateQuestion, deleteQuestion, addQuestion, setQuizStateIem, cpAddMessage}
    ),
    withState('hasConsultant', 'changeHasConsultant', (props) => props.quizData.info.assistant.name && props.quizData.info.assistant.title ? true : false),
    withState('colorOpen', 'setColorOpen', false),
    withState('branchQuestionIndex', 'setBranchQuestionIndex', null),
    withState('isOpenEditDiscount', 'setIsOpenEditDiscount', false),
    withHandlers({
        changeSliderMultipleHandler: props => () => {
            let questionData = props.questionActive
            questionData.sliderOptions.isMultiple = !questionData.sliderOptions.isMultiple
            props.updateQuestion(props.questionActive.id, questionData);
        },
        changeIsNoRequiredQuestionHandler: props => () => {
            let questionData = props.questionActive
            questionData.isNoRequired = !questionData.isNoRequired
            props.updateQuestion(props.questionActive.id, questionData);
        },
        addQuestionHandler: props =>  (e) => {
            const newQuestion = {
                multySelect:false,
                options: {placeholder: null},
                other: false,
                text: '',
                title: '',
                type: "answers",
                typeItem: "question"
            };
            props.addQuestion(newQuestion);
        },
        quizStateToQuestionHandler: props =>  (key) => {
            props.setQuizStateIem('activeKeyItem', key)
        },
        deleteQuestionHandler: props =>  (questionId) => {
            if(props.quizData.questions.length <= 1){
                props.cpAddMessage('Вы не можете удалить последний вопрос');
                return;
            }
            props.deleteQuestion(questionId);
        },
        copyQuestionHandler: props => () => {
            props.copyQuestion(props.questionActive.id);
        },
        changeHasConsultantHandler: props => () => {
            props.quizData.info.assistant.name = (props.hasConsultant) ? null : t('Имя')
            props.quizData.info.assistant.title = (props.hasConsultant) ? null : t('должность')
            props.quizData.info.assistant.avatar = null
            
            props.changeHasConsultant(!props.hasConsultant);
            props.updateQuizData(props.quizData);
        },
        changeHasQuestionActiveTextHandler: props => () => {
            let questionData = props.questionActive
            questionData.text = (props.hasQuestionActiveText) ? null : t('Подсказка')
            props.updateQuestion(props.questionActive.id, questionData);
        },
        changeHasDiscount: props => () => {
            if(!props.hasDiscount){
                props.quizData.info.discount =  {type: 'melting', value: "0"}
                props.setQuizStateIem('discount', "0")
            }else{
                props.quizData.info.discount =  false
            }
            props.updateQuizData(props.quizData);
        },
        onSortEndHandler: (props) => ({newIndex, oldIndex}) => {
            if(newIndex == oldIndex) return;
            props.sortQuestion(newIndex, oldIndex);
        },
        handleChangeComplete: props => (color) => {
            props.quizData.info.design.colors.main = color.hex
            props.updateQuizData(props.quizData);
        },
        changeColorOpenHandler: props =>  (e) => {
            e.preventDefault()
            props.setColorOpen(!props.colorOpen)
        },
        changeOtherHandler: props => () => {
            let questionData = props.questionActive
            questionData.other = !questionData.other
            props.updateQuestion(props.questionActive.id, questionData);
        },
        changeMultySelectHandler: props => () => {
            let questionData = props.questionActive
            questionData.multySelect = !questionData.multySelect
            props.updateQuestion(props.questionActive.id, questionData);
        },
        setBranchQuestionIndexdHandler: props => (questionIndex, e) => {
            e.stopPropagation();
            props.setBranchQuestionIndex(questionIndex);
        },
        changeTypeQuestionHandler: props => (e) => {
            let questionData = props.questionActive
            questionData.type = e.target.value
            props.updateQuestion(props.questionActive.id, questionData);
        },
        changeTypeImageHandler: props => (e) => {
            let questionData = props.questionActive
            questionData.typeImage = e.target.value
            props.updateQuestion(props.questionActive.id, questionData);
        },
        addAnswerHandler: props => () => {
            let answerData = {
                image: null,
                title: ''
            };
            props.addAnswer(answerData);
        },
        changeDiscountType: (props) => (e, type) => {

            let newQuizData = props.quizData
            if(type){
                if(!newQuizData.info.discount.type){
                    newQuizData.info.discount = {type: type}
                }else{
                    newQuizData.info.discount.type = type
                }
                if(newQuizData.info.discount.type == 'melting'){
                    props.setQuizStateIem('discount', (newQuizData.info.discount.value) ? newQuizData.info.discount.value : false)
                }
            }else {
                newQuizData.info.discount = false
            }
            props.updateQuizData(newQuizData);
        },
        changeDiscountValue: (props) => (e) => {
            let newQuizData = props.quizData
            newQuizData.info.discount.value = e.target.value
            props.updateQuizData(newQuizData);

            if(newQuizData.info.discount.type == 'melting'){
                props.setQuizStateIem('discount', (newQuizData.info.discount.value) ? newQuizData.info.discount.value : false)
            }
        },
        changeDiscountCurrency: (props) => (e) => {
            let newQuizData = props.quizData
            newQuizData.info.discount.currency = e.target.value
            props.updateQuizData(newQuizData);
        }

    }),
    lifecycle({
        componentWillReceiveProps(nextProps) {
            if (!this.props.hasDiscount && nextProps.hasDiscount && !this.props.isOpenEditDiscount){
                this.props.setIsOpenEditDiscount(true)
            }

        }
    })
)(View);
