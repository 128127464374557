import { handleResponse, handleResponseNoLogout } from './handleResponse';
import {config} from '../_services';
import 'whatwg-fetch';

export function requestApi(path, requestOptions) {
    requestOptions.credentials = 'include';
    return fetch(`${config.apiUrl}/${path}`, requestOptions).then(handleResponse).catch(
        error => {
            return Promise.reject(typeof error === 'string' ? error : 'Неизвестная ошибка');
        }
    )
}

export function requestApiNoLogout(path, requestOptions) {
    requestOptions.credentials = 'include';
    return fetch(`${config.apiUrl}/${path}`, requestOptions).then(handleResponseNoLogout).catch(
        error => {
            return Promise.reject(typeof error === 'string' ? error : 'Неизвестная ошибка');
        }
    )
}
