import { compose, withState, withHandlers} from 'recompose';
import { connect } from 'react-redux';
import View from './View';
import {updateQuizData} from "../../../../../_actions";
import React from "react";


export default compose(
    connect(
        state => ({
            quizData: state.quizData.toJS(),
        }),
        { updateQuizData}
    ),
    withState('data', 'setData', props => (props.quizData.info.analytic.facebook)),
    withState('instructionData', 'setInstructionData', null),
    withState('successSave', 'setSuccessSave', false),
    withHandlers({
        disabledHandler: props => () => {
            props.quizData.info.analytic.facebook = null
            props.setData(null)
            props.updateQuizData(props.quizData)
            props.handleClose()

        },
        closeInstructionHandler: props => () => {
            props.setInstructionData()
        },
        openInstructionHandler: props => (data) => {
            props.setInstructionData(data)
        },
        saveDataHandler: (props) => (e) => {
            e.preventDefault()
            props.quizData.info.analytic.facebook = props.data
            props.updateQuizData(props.quizData)
            props.setSuccessSave(true)
        },
        changeDataHandler: props => (e) => {
            props.setData(e.target.value)
            props.setSuccessSave(false)
        }

    }),




)(View);


