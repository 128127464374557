import {compose, withState, withHandlers, lifecycle} from 'recompose';
import { connect } from 'react-redux';
import View from './View';
import {getQuizList,  deleteQuiz} from "../../../_actions";
export default compose(
    connect(
        state => ({
            quizzes: state.quizzes.toJS(),
            quizList: state.quizzes.toJS().list
        }),
        {
            getQuizList,
            deleteQuiz
        }
    ),
    withState('openCreateQuizModal', 'setOpenCreateQuizModal', false),
    withState('openModalDeleteQuiz', 'setOpenModalDeleteQuiz', false),
    withState('quizIdForDelete', 'setQuizIdForDelete', null),
    withHandlers({
        handleOpenModalDeleteQuiz: props => (value) => {
            props.setQuizIdForDelete(value);
            props.setOpenModalDeleteQuiz(value ? true : false);
        },
        handleChangeOpenCreateQuizModal: props => () => {
            props.setOpenCreateQuizModal(props.openCreateQuizModal ? false : true );
        },
        handleSubmitDeleteQuiz: props => () => {
            props.setOpenModalDeleteQuiz(false);
            props.setQuizIdForDelete(null);
            props.deleteQuiz(props.quizIdForDelete);
        }
    }),
    lifecycle({
        componentDidMount() {
            if(!this.props.quizzes.isLoading) {
                this.props.getQuizList();
            }
        }
    })
)(View);

