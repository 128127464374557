
import {compose, lifecycle, withHandlers, withState} from 'recompose';
import { connect } from 'react-redux';
import View from './View';
import {removeTelegramAccount, setQuizData} from "../../../../../_actions";
import {quizzesService} from "../../../../../_api";

import React from "react";

export default compose(
    connect(
        state => ({
            quizData: state.quizData.toJS(),
            userInfo: state.userInfo.toJS(),
            telegramAccounts: state.quizData.toJS().notifications.telegramUsers,
            websocketPrivateChannel: state.websocket.toJS().privateChannel,
        }),
        { removeTelegramAccount, setQuizData }
    ),
    withState("сodeTelegramForNotify", "setCodeTelegramForNotify", null),
    withState("timer", "setTimer", null),
    withState("timerId", "setTimerId", null),
    withHandlers({
        removeTelegramAccount: props => (telegramUserId) => {
            props.removeTelegramAccount(telegramUserId, props.quizData.id);
        },
        getСodeTelegramForNotify: props => () => {
            quizzesService.getСodeTelegramForNotify(props.quizData.id).then((data) => {
                props.setCodeTelegramForNotify(data.code);

                let timeout = data.timeout;

                const timer = () => {
                    if(timeout <= 0){
                        props.setCodeTelegramForNotify(null);
                        props.setTimer(null);
                        clearInterval(timerId);
                        return;
                    }
                    timeout = timeout - 1;
                    props.setTimer(timeout);
                }

                let timerId = setInterval(timer.bind(this), 1000);
                props.setTimerId(timerId)



            });
        },
    }),
    lifecycle({
        componentDidMount() {
            const channel = this.props.websocketPrivateChannel.channel(`private-App.User.${this.props.userInfo.id}`);
            const callBack =  (data) => {
                let quizData = this.props.quizData;
                quizData.notifications.telegramUsers.push(data.telegramUser);
                this.props.setQuizData(quizData);
                this.props.setCodeTelegramForNotify(null);
                this.props.setTimer(null);
                if(null !== this.props.timerId){
                    clearInterval(this.props.timerId);
                }
            };
            channel.listen('.App\\Events\\AttachTelegramUserToQuiz', callBack.bind(this));
        },
        componentWillUnmount() {
            if(null !== this.props.timerId){
                clearInterval(this.props.timerId);
            }
        }
    })


)(View);