import {QuizStart} from "newkitleadforms";
import {Start} from "oldkitleadforms";
import React from "react";
import {connect} from "react-redux";
import {QuizStartInterface} from "leadformsquizentities/QuizStart";
import LayoutAdmin from "../LayoutAdmin";
import QuizContainer from "../QuizContainer";
import {updateQuizData} from "../../../../_actions";
import {store} from "../../../../_services";

const View = ({isNewTheme, isQuizBot, dataQuiz, colorTheme}: { isQuizBot: boolean, colorTheme: string, isNewTheme: boolean, dataQuiz: QuizStartInterface}) => {

    if (!isNewTheme) {
        return <LayoutAdmin>
            <QuizContainer color={colorTheme}><Start
                data={dataQuiz}
                isAdmin={true}
                nextQuestionHandler={() => {}}
            /></QuizContainer>
        </LayoutAdmin>
    }
    return (
        <div style={{background: 'white', borderRadius: 6, overflow: 'hidden',
            width: isQuizBot ? 375 : '100%', height: isQuizBot ? 550 : 'auto'
        }}>
            <QuizStart
                data={dataQuiz}
                nextQuestionHandler={() => {}}
                isAdmin={true}
            />
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const quizData = state.quizData.toJS();
    const logo = quizData.info.startPage.logo;
    const backgroundImage = quizData.info.startPage.bg;

    const colorTheme = true !== quizData.isEmpty ? quizData.info.design.colors.main : '';

    const startPage = quizData.info.startPage;
    const isNewTheme = quizData.isNewTheme;


    const dataQuizStart: QuizStartInterface = {
        name: startPage.name ? startPage.name : undefined,
        logo: logo ? logo : undefined,
        legal: startPage.legal ? startPage.legal : undefined,
        phone: startPage.phone ? startPage.phone : undefined,
        title: startPage.title ? startPage.title : undefined,
        subTitle: startPage.subtitle ? startPage.subtitle : undefined,
        buttonCaption: startPage.buttonText,
        backgroundImage: backgroundImage ? backgroundImage : (isNewTheme ? {} : undefined),
    }

    return {
        colorTheme: colorTheme,
        dataQuiz: dataQuizStart,
        buttonNextCaption: quizData.info.form.button,
        isNewTheme: isNewTheme,
        isQuizBot: quizData.isQuizBot
    };
};

export default connect(
    mapStateToProps
)(View);
