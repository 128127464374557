import React from "react";
import {
    withStyles,
    Paper
} from "@material-ui/core";
import Color from "color";

const Index = ({color, children, classes}) => {
    return (
        <Paper  className={classes.container}>
            {children}
        </Paper>
    );
}

const styles = theme => ({
    '@keyframes leftRightEffect': {
        '0%': { opacity: 0},
        '100%': { opacity: 1}
    },
	container:{
        animationName: '$leftRightEffect',
        animationDuration: '1s',
		height: '100%',
		overflow: 'hidden',
		position: 'relative',
        display: 'flex'
	}

});
export default withStyles(styles)(Index);
