import React from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControlLabel,
    Grid,
    Switch,
    Typography,
    withStyles
} from "@material-ui/core";
import ResultContainer from './ResultContainer';
import {AddCircle as AddIcon, Settings as SettingsIcon} from "@material-ui/icons";
import BranchResult from "../../../components/BranchResult";
import classnames from "classnames";

const View = ({

                  results,
                  changeEnableResult,
                  changeAfterQuestion,
                  addResultItemHandler,
                  branchResultIndex,
                  setBranchResultIndexHandler,
                  isNewTheme,
                  classes,
                  isQuizBot
              }) => (
    (!results || !results.enabled) ?

        <Grid
            justify="center"
            container
            alignItems="stretch"
        >
            <Card className={classes.cardStartPageDisabled}>
                <CardHeader title="Результаты"/>
                <CardContent>
                    <Typography variant="body2" component="p">
                        {/*Стартовая страница нужна, чтобы замотивировать пользователя пройти*/}
                        {/*тест.<br/>Также, вы можете открывать квиз по прямой ссылке или привязать к нему домен, и*/}
                        {/*тогда стартовая страница будет служить полноценным Landing Page.*/}
                    </Typography>
                </CardContent>
                <CardActions>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={false}
                                onChange={changeEnableResult}
                            />
                        }
                        label={'Включить'}
                    />
                </CardActions>
            </Card>
        </Grid>
        : <Grid justify="center" container>

            {(!isNewTheme) ?
            <Card>
                <CardContent>
                    <Typography variant="body2" component="p">
                        Для этого дизайна результаты не поддерживаются
                    </Typography>
                </CardContent>
                </Card>
            : <><Grid container
                      className={classes.topPanel}
                      direction="row"
                      justify="space-between"
                      alignItems="flex-end"
        >


            {results.items.length === 1 && <Button color="primary" className={classes.button} onClick={() => {
                setBranchResultIndexHandler(0)
            }}>
                <SettingsIcon/>
                <Typography>Настройки</Typography>
            </Button>}
        </Grid>
              <Grid className={classnames(classes.content, isQuizBot && 'quiz_bot')}>
                <ResultContainer/>
                <div style={{width: '100%'}}>
                    <div item style={{paddingTop: 5}}>
                        <Button className={classes.buttonAddAnswer} onClick={addResultItemHandler}>
                            <AddIcon/>
                            <span>Добавить результат</span>
                        </Button>
                    </div>
                </div>
            </Grid>
        </>}
            <Grid
                container
                direction="column"
                justify="space-between"
                className={classes.rightPanel}
            >
                <Grid>
                    <FormControlLabel fullWidth
                                      control={
                                          <Switch
                                              classes={{
                                                  root: classes.root,
                                                  switchBase: classes.switchBase,
                                                  thumb: classes.thumb,
                                                  track: classes.track,
                                                  checked: classes.checked,
                                              }}
                                              checked={true}
                                              onChange={changeEnableResult}
                                          />
                                      }
                                      label={'Показывать результаты'}
                    />
                    <FormControlLabel fullWidth
                                      control={
                                          <Switch
                                              classes={{
                                                  root: classes.root,
                                                  switchBase: classes.switchBase,
                                                  thumb: classes.thumb,
                                                  track: classes.track,
                                                  checked: classes.checked,
                                              }}
                                              checked={results.afterQuestion}
                                              onChange={changeAfterQuestion}
                                          />
                                      }
                                      label={'Перед контактами'}
                    />
                </Grid>
            </Grid>
            {branchResultIndex !== null && <BranchResult closeHandler={() => setBranchResultIndexHandler(null)}
                                                         resultIndex={branchResultIndex}/>}

        </Grid>


);

const styles = theme => ({
    content: {
        maxWidth: 1024,
        width: '100%',
        '@media(min-width: 1201px)': {
            width: 'calc(100% - 150px)',
        },
        '&.quiz_bot': {
            width: 'auto'
        }
    },
    rightPanel: {
        padding: 10,
        width: '100%',
        '@media(min-width: 1201px)': {
            width: 150
        },
        '& span,p': {
            fontSize: '13px !important'
        }
    },
    checkbox: {
        paddingTop: 0,
        paddingBottom: 0
    },
    checkboxControl: {
        marginTop: -10
    },
    buttonAddAnswer: {
        cursor: 'pointer',
        float: 'left',
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        '& svg': {
            fontSize: 30,
            marginRight: 10
        }
    }
});

export default withStyles(styles)(View);
