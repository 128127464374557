import React from "react";
import {
    Typography,
    FormControl,
    Button,
    Input,
    withStyles,
    FormControlLabel,
    Link
} from "@material-ui/core";

import {Delete as DeleteIcon} from "@material-ui/icons";

const View = ({
                  addWebHookHandler,
                  removeWebhook,
                  webHookList,
                  classes
}) => (
    <FormControl>
        <Link target="_blank" href='https://leadforms.ru/instructions/webhooks'>Инструкция</Link>
        <br/>
        <Typography>
            Вы можете оповещать сторонние приложения о поступивших заявках. Для этого укажите URL, на который будет<br/>
            отправлен WebHook.
        </Typography>
        {webHookList.map((webHook) =>
            <FormControlLabel
                control={<DeleteIcon className={classes.deleteIcon} onClick={removeWebhook.bind(this, webHook.id)}/>}
                label={webHook.url}
            />
        )}
        <form onSubmit={addWebHookHandler}>
            <Input placeholder="url" name="url" required/>
            <Button className={classes.button} variant="contained" type="submit">добавить</Button>
        </form>
    </FormControl>
)

const styles = theme => ({
    button: {
        marginLeft: 5
    },
    deleteIcon: {
        marginLeft: 12,
        marginRight: 12
    }
});

export default withStyles(styles)(View);