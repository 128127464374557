import React from "react";
import {

    Typography,
    FormControl,
    Button,
    Input,
    InputLabel,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    withStyles

} from "@material-ui/core";

import {

    Delete as DeleteIcon

} from "@material-ui/icons";

const View = ({
    changeSelfEmailActiveHandler,
    addEmailNotificationsHandler,
    changeNotificationCheckedHandler,
    removeEmail,
    selfEmailIsActive,
    selfEmail,
    emailList,
    classes
}) => (
    <FormControl>
        <FormLabel component="legend">Отправлять заявки по Email на</FormLabel>
        <FormGroup>
            <FormControlLabel
                control={<Checkbox checked={selfEmailIsActive} onChange={changeSelfEmailActiveHandler} />}
                label={selfEmail}
            />

                {emailList.map((email) =>
                    <FormControlLabel
                        control={<DeleteIcon className={classes.deleteIcon} onClick={removeEmail.bind(this, email)} />}
                        label={email}
                    />
                )}
            </FormGroup>
            <form onSubmit={addEmailNotificationsHandler}>
                    <Input placeholder="Email" type="email" name="email" required/>
                   <Button className={classes.button} variant="contained" type="submit">добавить</Button>
            </form>
</FormControl>
)

const styles = theme => ({
    button: {
        marginLeft: 5
    },
    deleteIcon: {
        marginLeft: 12,
         marginRight: 12
    }
});

export default  withStyles(styles)(View);