import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './_services';
import App from './cp/App';
import 'core-js/stable';
import 'react-app-polyfill/ie9';

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('app')
);
