import React from "react";
import {
    withStyles,
    Typography,
    Grid,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio
} from "@material-ui/core";
import {Delete as DeleteIcon} from "@material-ui/icons";
import FullScreenDialog from '../../../components/FullScreenDialog';

import DisplayCondition from './DisplayCondition';

const View = ({
                  closeHandler,
                  questionIndex,
                  title,
                  questionsByCondition,
                  displayConditions,
                  newConditionHandler,
                  updateConditionHandler,
                  deleteConditionHandler,
                  typeIfFewConditions,
                  handleChangeTypeIfFewConditions,
                  classes
              }) => (

    <FullScreenDialog modalTitle={`Условия показа (#${questionIndex + 1} ${title})`} handleClose={closeHandler}>
        <Grid container>
            <Grid item xs={12} sm={6}>
                <Typography variant="h6">Добавьте условие</Typography>
                <DisplayCondition key={'new'} condition={null} byNewCondition={true} handler={newConditionHandler}
                                  questionsByCondition={questionsByCondition}/>
                {(displayConditions.length > 0) &&
                [<Typography className={classes.titleList} variant="h6">Список условий</Typography>,
                displayConditions.map((condition, conditionIndex) =>

                    <Grid container>
                        <Grid item xs={10}>
                            <DisplayCondition key={conditionIndex}
                                              handler={updateConditionHandler.bind(this, conditionIndex)}
                                              condition={condition}
                                              questionsByCondition={questionsByCondition}/>
                        </Grid>
                        <Grid item xs={2}
                              container
                              justify="center"
                              alignItems="center"
                        >
                            <DeleteIcon onClick={deleteConditionHandler.bind(this, conditionIndex)}/>
                        </Grid>
                    </Grid>
                )]
                }
            </Grid>
            <Grid item xs={12} sm={6}>
                {(displayConditions.length >= 2) &&
                <FormControl className={classes.formTypeIfFewConditions} component="fieldset">
                    <FormLabel component="legend">Показывать вопрос, если выполнены</FormLabel>
                    <RadioGroup
                        value={typeIfFewConditions}
                        onChange={handleChangeTypeIfFewConditions}
                    >
                        <FormControlLabel value="and" control={<Radio/>} label="все условия"/>
                        <FormControlLabel value="or" control={<Radio/>} label="хотя бы одно"/>
                    </RadioGroup>
                </FormControl>}
            </Grid>
        </Grid>
    </FullScreenDialog>
);

const styles = theme => ({
    titleList: {
        marginBottom: 20
    },
    formTypeIfFewConditions: {
        paddingLeft: 20
    }
});

export default withStyles(styles)(View);