import React from "react";
import {
    Grid,
    Typography,
    withStyles,
    Button,
    Tabs,
    Tab,
    Fade,
    CssBaseline,
    Card, DialogContent,DialogActions, Dialog
} from "@material-ui/core";
import Footer from "../../components/Footer";
import {Link} from 'react-router-dom';
import logo from "../../img/logo.png";
const image1 = require(`../../img/instruction_telegram/1.png`)
const image2 = require(`../../img/instruction_telegram/2.png`)
const Login = ({
                   classes,
                   handleGetCode,
                   сodeTelegram,
                   errorMessage,
                   changeModalOpenHandler,
                   isModalOpen,
                   timer
               }) => (
    <Grid container className={classes.container}>
        <CssBaseline/>
        <div className={classes.formContainer}>
            <Card className={classes.form}>

                <img src={logo} alt="logo" className={classes.logotypeImage}/>
                <Tabs
                    value={0}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Telegram" classes={{root: classes.tab}}/>
                    <Tab component={Link} to="/login" label="Email" classes={{root: classes.tab}}/>
                </Tabs>

                <React.Fragment>
                    <Fade in={errorMessage}>
                        <Typography color="secondary" className={classes.errorMessage}>
                            {errorMessage}
                        </Typography>
                    </Fade>
                    <div className={classes.formButtons}>
                        {(сodeTelegram && timer) &&
                        <Typography>
                            <Typography> Ваш код: <strong>{сodeTelegram}</strong></Typography>
                            <small> Код истекает через <strong>{timer}</strong> секунд</small>
                        </Typography>
                        }

                        {!сodeTelegram &&

                        <Button
                            onClick={handleGetCode}
                            variant="contained"
                            color="primary"

                            size="large"
                        >
                            Получить код
                        </Button>}
                    </div>
                    <br/> <br/>
                    <Typography>
                        <Typography>Что бы ввести полученный код, подпишитесь на нашего бота <a href={'tg://resolve?domain=LeadFormsBot'}>@LeadFormsBot</a> <br/><small style={{cursor: 'pointer'}} onClick={changeModalOpenHandler}>(инструкция по авторизации)</small></Typography>
                    </Typography>
                </React.Fragment>


            </Card>
            <div className={classes.footerAbsolute}>
                <Footer/>
            </div>
        </div>
        <Dialog
            open={isModalOpen}
            onClose={changeModalOpenHandler}
        >
            <DialogContent>
                <Typography>
                    <ol>
                        <li>Зайдите в Telegram</li>
                        <li>Найдите и подпишитесь на бота <strong><a href={'tg://resolve?domain=LeadFormsBot'}>@LeadFormsBot</a></strong> <img className={classes.instructionImage} src={String(image1)}/><br/><br/></li>
                        <li>Нажмите на кнопку "ПОЛУЧИТЬ КОД" (на странице входа)<br/><br/></li>
                        <li>Введите комманду <strong>YOUR_CODE</strong>, где YOUR_CODE - полученый вами код <br/><br/></li>
                        <li>При успешном вводе, на странице входа произойдет автоматический вход в систему <img className={classes.instructionImage} src={String(image2)}/></li>
                    </ol>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={changeModalOpenHandler}>Закрыть</Button>
            </DialogActions>
        </Dialog>
    </Grid>
);

const styles = theme => ({
    container: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0
    },
    logotypeContainer: {
        backgroundColor: theme.palette.primary.main,
        width: "60%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "50%"
        },
        [theme.breakpoints.down("md")]: {
            display: "none"
        }
    },
    logotypeImage: {
        width: 165,
        marginBottom: 32
    },

    formContainer: {
        width: "40%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "50%"
        },
        textAlign: 'center'
    },
    form: {
        width: 420,
        padding: 50
    },
    tab: {
        fontWeight: 400,
        fontSize: 18
    },
    greeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(4)
    },
    subGreeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(2)
    },
    creatingButtonContainer: {
        marginTop: theme.spacing(2.5),
        height: 46,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    errorMessage: {
        textAlign: "center"
    },
    formButtons: {
        width: "100%",
        marginTop: theme.spacing(4),
        justifyContent: "space-between",
        alignItems: "center"
    },
    forgetButton: {
        textTransform: "none",
        fontWeight: 400
    },
    loginLoader: {
        marginLeft: theme.spacing(4)
    },
    footerAbsolute: {
        position: "absolute",
        bottom: theme.spacing(2)
    },
    instructionImage: {
        maxWidth: '100%'
    }
});

export default withStyles(styles, {withTheme: true})(Login);
