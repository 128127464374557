
import {Map} from 'immutable'
import {createReducer} from 'redux-act'

import {
    signOutSuccess, setUserInfo
} from '../_actions'
import {config} from "../_services";

import Pusher from "pusher-js"
import Echo from 'laravel-echo';

const entityToMap = (data) => {
    return new Map(data)
}

const optionsForPrivateChannel = (token) => {return {
    broadcaster: 'pusher',
    key: config.pusherKey,
    cluster: 'eu',
    forceTLS: true,
    authEndpoint: config.pusherAuthEndpoint,
    auth: {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
        },
    }
}};

const defaultState = entityToMap({privateChannel: null, publicChannel: null});

export const websocket = createReducer((on) => {
    on(setUserInfo, (state, payload) => {
        let privateChannel = null;
        if(null === state.toJS().privateChannel){
            const user = JSON.parse(localStorage.getItem('user'));
            privateChannel = new Echo(optionsForPrivateChannel(user.token));
            const channel = privateChannel.channel(`private-App.User.${user.id}`);
        }
        return entityToMap({privateChannel: privateChannel, publicChannel: null})
    })

    on(signOutSuccess, (state) => {
        if(null !== state.toJS().privateChannel){
            state.toJS().privateChannel.disconnect();
        }
        return entityToMap({privateChannel: null,publicChannel: null})
    })

}, defaultState)




