import 'whatwg-fetch';
import {handleResponse, handleResponseNoLogout} from './handleResponse';
import {authHeader, config, store} from '../_services';
import {requestApi} from "./requestApi";


export const quizzesService = {
    getAll,
    getLeads,
    deleteLead,
    updateQuiz,
    uploadFile,
    getQuiz,
    sendClientForm,
    createQuizz,
    deleteQuiz,
    getQuizzesCategoties,
    addWebHook,
    removeWebHook,
    downloadLeadsReport,
    removeTelegramAccount,
    getСodeTelegramForNotify,
    getСodeTelegramForLogin,
    quizIsLoad,
    removeAmocrm
};

function getQuiz(quizId) {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${config.apiUrl}/quizzes/${quizId}`, requestOptions).then(handleResponse)
}

function quizIsLoad(quizId) {

    function getParameterByName(name) {
        const url = document.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const data = {
       url:  null !== getParameterByName('href') ? getParameterByName('href') : window.location.href
    };
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {'Content-Type': 'application/json'}
    };

    return fetch(`${config.apiUrl}/quizzes/${quizId}/load`, requestOptions).then(handleResponse)
}

function sendClientForm(data) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {'Content-Type': 'application/json'}
    };
    return fetch(`${config.apiUrl}/leads`, requestOptions).then(handleResponse);
}


function uploadFile(file) {

    let headers = authHeader();
    var data = new FormData()
    data.append('file', file)

    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: data
    };
    return requestApi(`files/upload`, requestOptions)
}


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/quizzes/`, requestOptions).then(handleResponse)
}


function getLeads(quizId, page = 0) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const limit = 30;
    return fetch(`${config.apiUrl}/quizzes/${quizId}/leads?limit=${limit}&page=${page}`, requestOptions).then(handleResponse)
}

function downloadLeadsReport(quizId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/quizzes/${quizId}/leads/report`, requestOptions).then((response) => {
        if (response.status !== 200) {
            const error = 'Неизвестная ошибка';
            return Promise.reject(error);
        }
        return response.blob();
    },() => {
        const error = 'Неизвестная ошибка';
        return Promise.reject(error);
    }).then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "filename.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
        });
}


function deleteLead(quizId, leadId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/quizzes/${quizId}/leads/${leadId}`, requestOptions).then(handleResponse)
}

function deleteQuiz(quizId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/quizzes/${quizId}`, requestOptions).then(handleResponse)
}


function createQuizz(copyQuizId) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({copyQuizId: copyQuizId})
    };

    return fetch(`${config.apiUrl}/quizzes`, requestOptions).then(handleResponse)
}


function updateQuiz(quizId, data) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}/quizzes/${quizId}`, requestOptions).then(handleResponse)
}


function getQuizzesCategoties() {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${config.apiUrl}/quizzes_categories`, requestOptions).then(handleResponse)
}


function addWebHook(url, quizId) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({url: url})
    };
    return fetch(`${config.apiUrl}/quizzes/${quizId}/web_hooks`, requestOptions).then(handleResponse)
}

function removeWebHook(quizId, webHookId) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'DELETE',
        headers: headers
    };
    return fetch(`${config.apiUrl}/quizzes/${quizId}/web_hooks/${webHookId}`, requestOptions).then(handleResponse)
}

function removeTelegramAccount(quizId, telegramUserId) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'DELETE',
        headers: headers
    };
    return fetch(`${config.apiUrl}/quizzes/${quizId}/telegram/${telegramUserId}`, requestOptions).then(handleResponse)
}

function getСodeTelegramForNotify(quizId) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(`${config.apiUrl}/quizzes/${quizId}/telegram/get_code`, requestOptions).then(handleResponse)
}

function getСodeTelegramForLogin() {
    // let headers = authHeader();
    // headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'GET',
        // headers: headers
    };
    return fetch(`${config.apiUrl}/telegram/get_login_code`, requestOptions).then(handleResponseNoLogout)
}


function removeAmocrm(quizId) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'DELETE',
        headers: headers
    };
    return fetch(`${config.apiUrl}/quizzes/${quizId}/remove_amo`, requestOptions).then(handleResponseNoLogout)
}

