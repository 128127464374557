import React from "react";
import {Help} from '@material-ui/icons';
import {withStyles, Tooltip, Typography} from "@material-ui/core";
import {tooltips} from '../../_services'
const View = ({classes, keyItem}) =>
    <Tooltip title={<React.Fragment>
            <Typography color="inherit">{tooltips[keyItem]}</Typography>
          </React.Fragment> }>
        <Help className={classes.icon}/>
    </Tooltip>
;

const styles = theme => ({
    icon: {
        fontSize: 15
    }
});
export default withStyles(styles)(View);