
import {compose, withState, withHandlers} from "recompose";
import View from "./View";
import { userService } from '../../../_api';
import Url from "url"
import {history} from "../../../_services";

export default compose(
    withState("loading", "setLoading", false),
    withState("error", "setError", ""),
    withState("password", "setPassword", ""),
    withHandlers({
        changePasswordhandler: props => (val) => {
               props.setPassword(val.target.value)   
               if(props.error) props.setError("")
        },
        handleSubmit: props => () => {
            props.setLoading(true)
            if(props.error) props.setError("")
            const url = Url.parse(window.location.href, true)
            userService.resetPassword(props.password, url.query.access_token).then(
                request => {
                    history.push('/')
                    props.setLoading(false)
                },
                error => {
                    props.setLoading(false)    
                    props.setError(error)
                },
            );

        }
    })
)((View));
