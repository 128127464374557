
import {compose, withHandlers, withState} from 'recompose';
import { connect } from 'react-redux';

import View from './View';
import {cpSetShowModalAdditionalUser, updateUser} from "../../../_actions";

export default compose(
    connect(
        (state) => ({
            showModalAdditionalUser: state.cpState.toJS().showModalAdditionalUser,
            userInfo: state.userInfo.toJS()
        }),
        {cpSetShowModalAdditionalUser, updateUser}
    ),
    withState('haveSite', 'setHaveSite', true),
    withHandlers({
        closeModalAdditionalUser: props => () => {
            props.cpSetShowModalAdditionalUser(false);
        },
        changeSiteStatus: props => () => {
            props.setHaveSite(!props.haveSite);
        },
        handleSubmitUpdateUser: props => (e) => {
            e.preventDefault();
            const data = new FormData(e.target);
            if(data.get('name')) props.userInfo.info.name = data.get('name')
            if(data.get('phone')) props.userInfo.info.phone = data.get('phone')
            if(data.get('site')) props.userInfo.info.site = data.get('site')
            props.updateUser(props.userInfo);
        }
    })
)(View);

