import {Map} from "immutable";
import {createReducer} from "redux-act";
import {setUserInfo, signOutSuccess} from "../_actions";

const entityToMap = (data) => {
    return new Map(data)
}

const defaultState = entityToMap({isEmpty: true})

export const userInfo = createReducer((on) => {
    on(setUserInfo, (state, payload) => {
        return entityToMap(payload)
    })
    on(signOutSuccess, (state, error) => {
        return defaultState
    })

}, defaultState)




