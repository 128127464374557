import React from "react";
import {Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography} from "@material-ui/core";
export default class ModalDeleteAnswer extends React.Component {
    render(){

        const answer = this.props.answer
        return (
            <Dialog  open={true} onClose={this.props.closeModalDeleteAnswer}>
                <DialogTitle>Удалить эту заявку?</DialogTitle>
                <DialogContent>
                    {answer.contacts && answer.contacts.name && <Typography><b>Имя</b>: {answer.contacts.name}</Typography>}
                    {answer.contacts && answer.contacts.email && <Typography><b>Email</b>: {answer.contacts.email}</Typography>}
                    {answer.contacts && answer.contacts.phone && <Typography><b>Телефон</b>: {answer.contacts.phone}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.deleteAnswer} color="primary">
                        Удалить
                    </Button>
                    <Button onClick={this.props.closeModalDeleteAnswer} color="primary">
                        Нет
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}