import React from "react";
import {
    Grid,
    Typography,
    Divider,
    ListItemSecondaryAction,
    List,
    ListItem,
    Tabs,
    Tab,
    FormControl,
    InputLabel,
    Input,
    Button

} from "@material-ui/core";
import PageTitle from "../../components/PageTitle";
import {withStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import PayByLead from './PayByLead'
import PayUnlimited from './PayUnlimited'
import WhiteLabel from './WhiteLabel'

const BalanceView = ({
    userInfo,
    tableState,
    tableStateChangeHandler,
    attachPromoCode, 
    classes
}) => (
    <React.Fragment>

        <Grid justify="center" container>
            <Grid container item lg={8} md={8} sm={10} xs={12}>
                <Grid item xs={12}>
                    <PageTitle title="Оплата и Баланс"/>
                </Grid>
                {(userInfo.readOnly.unlimit_one.length > 0 || userInfo.readOnly.unlimit_all || userInfo.readOnly.unlimit_10) &&
                <Grid item lg={8} md={8} sm={8} xs={12} className={classes.card}>
                    <Card>
                        <CardContent>
                            <Typography>
                                Подключенные программы
                            </Typography>
                            <List dense>
                                    {userInfo.readOnly.unlimit_all &&
                                    <ListItem >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                    >
                                        <Grid item>
                                            <Typography variant="h6" component="h3">
                                                Безлимит++ для всех квизов
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <ListItemSecondaryAction>
                                                <Typography variant="h6" component="h4">
                                                    {userInfo.readOnly.unlimit_all.dateFinish}
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </Grid>
                                    </Grid>
                                    </ListItem>
                                    }
                                {userInfo.readOnly.unlimit_10 &&
                                <ListItem >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                    >
                                        <Grid item>
                                            <Typography variant="h6" component="h3">
                                                Безлимит+ для 10 квизов
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <ListItemSecondaryAction>
                                                <Typography variant="h6" component="h4">
                                                    {userInfo.readOnly.unlimit_10.dateFinish}
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                }
                                    {Object.keys(userInfo.readOnly.unlimit_one).map((key) => {
                                            const program = userInfo.readOnly.unlimit_one[key]
                                            return (
                                                [<ListItem >
                                            <Grid
                                                container
                                                direction="row"
                                                justify="space-between"
                                            >
                                                <Grid item>
                                                    <Typography variant="h6" component="h3">
                                                        Безлимит для квиза #{program.quizId}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <ListItemSecondaryAction>
                                                        <Typography variant="h6" component="h4">
                                                            до {program.dateFinish}
                                                        </Typography>
                                                    </ListItemSecondaryAction>
                                                </Grid>
                                            </Grid>
                                            </ListItem>,
                                            <Divider />]
                                            )})}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>}
                <Grid item lg={4} md={4} sm={4} xs={12} className={classes.card}>
                    <Card>
                        <CardContent>
                            <Typography>
                                Осталось
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {userInfo.readOnly.balance} заявок
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={8} md={8} sm={8} xs={12} className={classes.card}>
                    <Card>
                        <CardContent>
                            <Tabs
                                value={tableState}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                onChange={tableStateChangeHandler}
                                className={classes.tab}
                            >
                                <Tab label="ЗА ЗАЯВКУ"/>
                                <Tab label="БЕЗЛИМИТ"/>
                                <Tab label="ПРОМОКОД"/>
                            </Tabs>

                            {tableState === 0 &&
                            <PayByLead/>
                            }
                            
                            {tableState === 1 &&
                                <PayUnlimited/>
                            }
                            {tableState === 2 &&
                            <Typography component="div">
                                <Typography>
                                    Используйте промокод<br/><br/>
                                </Typography>
                                <form onSubmit={attachPromoCode}>
                                    <FormControl fullWidth>
                                        <InputLabel>Промокод</InputLabel>
                                        <Input name="code" placeholder="Введите промокод"/>
                                    </FormControl>
                                    <Grid item>
                                        <Button  type="submit" variant="contained">
                                            ПРИМЕНИТЬ
                                        </Button>
                                    </Grid>
                                </form>
                            </Typography>
                            }
                        </CardContent>
                    </Card>
                    <br/>
                    <WhiteLabel/>
                </Grid>



            </Grid>
        </Grid>
    </React.Fragment>
);

const styles = theme => ({
    tab: {
        marginBottom: 20
    },
    card:{
        padding: theme.spacing(2)
    }
});

export default  withStyles(styles)(BalanceView);