import { quizzesService } from '../_api';
import {getQuestionById} from './../_services/questionService';
import urlParse from 'url-parse';
import {setQuizStateIem} from '../_reducers/quizState.reducer'

export const quizStateSubscribers = {
    handlerChangeForm,
    handlerChangeProgress,
    handlerSendClientForm,
    handlerChangeDiscountIncreasing,
    handlerChangeDiscountOneStep
}

function handlerSendClientForm(store, dontSend = false){

    const state = store.getState()
    const quizState = state.quizState
    const quizData = state.quizData.toJS()

    if(!quizState.finish || !quizState.loading || quizState.sendingClientForm) return
    if(dontSend){
        store.dispatch(setQuizStateIem('loading', false))
        return
    }
    
    store.dispatch(setQuizStateIem('sendingClientForm', true))
    let answers = []

    for (let key in quizState.clientData.answers){
        if(quizState.clientData.answers[key]){
            const question = getQuestionById(key);
            answers.push({
                q: question.title,
                questionId: question.id,
                a: quizState.clientData.answers[key]
            })
        }
    }

    let utm = {}
    try {
        utm = JSON.parse(getParameterByName('utm'))
    } catch(e) {

    }



    const href = null !== getParameterByName('href') ? getParameterByName('href') : window.location.href;
    const cookiesString = null !== getParameterByName('cookies') ? getParameterByName('cookies') : document.cookie;
    const urlParsed =  urlParse(href);
    const query =  urlParsed.query;



    let data = {
        quizId: quizData.id,
        answers2: answers,
        contacts: quizState.clientData.contacts,
        extra:{
            href: href,
            ref: null !== getParameterByName('ref') ? getParameterByName('ref') : document.referrer,
            utm:  getUtms(query),
            cookies:  getCookies(cookiesString),
        }
    }

    function getCookies(cookieString) {
        function getCookie(name, cookieString) {
            var matches = cookieString.match(new RegExp(
                "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
            ));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        }

        var t = ["roistat_visit", "roistat_marker", "roistat_marker_old", "roistat_call_tracking", "roistat_param1", "roistat_param2", "roistat_emailtracking_email", "lp_tracker_id", "lptracker_visitor_id", "lptracker_view_id", "_ga", "_userid", '_ym_uid'];
        var cookies = {};
        for (var i = 0; i < t.length; i++) {
            var e = t[i];
            let cookieItem = getCookie(e, cookieString);
            if (undefined !== cookieItem) {
                cookies[e] = cookieItem;
            }
        }
        return cookies;
    }

    function getUtms(query) {
            var vars = query.substring(1).split("&");
            var query_string = {};
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                var key = pair[0];
                var value = pair[1];
                if ("utm_" == key.substr(0, 4)) {
                    if (typeof query_string[key] == "undefined") {
                        query_string[key] = value;
                    } else if (typeof query_string[key] == "string") {
                        var arr = [query_string[key], value];
                        query_string[key] = arr;
                    } else {
                        query_string[key].push(value);
                    }
                }

            }
            return query_string;
    }

    function getParameterByName(name) {
        const url = document.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    if(quizState.discount && quizData.info.discount){
        data.extra.discount = (quizState.discount) ? `${quizState.discount}${(quizData.info.discount.type == 'increasingPercent') ? '%' : (quizData.info.discount.currency ? quizData.info.discount.currency : "₽")}` : false
    }


    quizzesService.sendClientForm(data)
        .then(
           function () {
               store.dispatch(setQuizStateIem('loading', false));
               store.dispatch(setQuizStateIem('sendingClientForm', false));

               if(quizData && quizData.info && quizData.info.analytic.ga.id){
                   window.postMessage({
                       type: 'google',
                       action: 'event',
                       eventName: 'leadforms_finish',
                       id: quizData.info.analytic.ga.id,
                       quizId: quizData.id
                   }, "*")
               }

               if(quizData && quizData.info && quizData.info.analytic.metrika.id){
                   window.postMessage({
                       type: 'yandex',
                       action: 'event',
                       eventName: 'leadforms_finish',
                       id: quizData.info.analytic.metrika.id,
                       quizId: quizData.id
                   }, "*")
               }

               if(quizData && quizData.info && quizData.info.analytic.vk){
                   window.postMessage({
                       type: 'vk',
                       action: 'event',
                       eventName: 'leadforms_finish',
                       id: quizData.info.analytic.vk,
                       quizId: quizData.id
                   }, "*")
               }

               if(quizData && quizData.info && quizData.info.analytic.facebook){
                   window.postMessage({
                       type: 'facebook',
                       action: 'event',
                       eventName: 'leadforms_finish',
                       id: quizData.info.analytic.facebook,
                       quizId: quizData.id
                   }, "*")
               }

               if(quizData && quizData.info && quizData.info.form.redirect){
                   let dom = window.parent ? window.parent : window;
                   dom.location.replace(quizData.info.form.redirect + ((quizData.id === 617) ? '?email=' + data.contacts.email : ''));
               }
           },
            function () {
                store.dispatch(setQuizStateIem('loading', false))
                store.dispatch(setQuizStateIem('error', true))
                store.dispatch(setQuizStateIem('errorMessage', 'errorMessage'))
                store.dispatch(setQuizStateIem('sendingClientForm', false))
            }
    );



}


function handlerChangeForm(store, isClient = false) {

    const state = store.getState()
    const quizState = state.quizState
    const quizData = state.quizData.toJS()


    if(quizState.loading) return;

    const questionActive = quizData.questions[quizState.activeKeyItem]

    const ifHasAnswers = questionActive && (questionActive.answers.length || (questionActive.other && questionActive.type == 'answers') || questionActive.type == 'input')
        ? (!quizState.clientData.answers[questionActive.id] || (quizState.clientData.answers[questionActive.id] && !Object.values(quizState.clientData.answers[questionActive.id]).length))
        : false

    const isNoRequired = !! quizData.questions[quizState.activeKeyItem].isNoRequired;

    const logicNoAnswer = isClient &&
        quizState.activeKeyItem >= 0
        && ifHasAnswers
        && !quizState.contacts
        && !isNoRequired;


    const form = {
        buttonPrevDisabled: (quizState.activeKeyItem > 0 || (quizState.contacts && quizData.questions.length) || quizState.finish) ? false : true,
        buttonNextDisabled: (quizState.activeKeyItem > quizData.questions.length - 1 || quizState.finish
            || logicNoAnswer
        ) ? true : false
    }

    if(form.buttonPrevDisabled != quizState.form.buttonPrevDisabled || form.buttonNextDisabled != quizState.form.buttonNextDisabled)
        store.dispatch(setQuizStateIem('form', form))


}

function handlerChangeProgress(store) {
    const state = store.getState()
    const quizState = state.quizState
    const quizData = state.quizData.toJS()


    if(quizState.loading) return;
    const progress = (quizData.questions.length) ? Math.round(100 * quizState.activeKeyItem / quizData.questions.length) : null
    if (progress != quizState.progress) 
        store.dispatch(setQuizStateIem('progress', progress))
}

function handlerChangeDiscountIncreasing(store) {
    const state = store.getState()
    const quizState = state.quizState
    const quizData = state.quizData.toJS()


    if(quizState.loading) return;
    if(!quizData.info.discount || !quizData.info.discount.value  ||  ['increasing', 'increasingPercent'].indexOf(quizData.info.discount.type) == -1) return;

    const discount = Math.round(quizData.info.discount.value * (quizState.activeKeyItem + 1 + ((quizState.contacts || quizState.finish) ? 1 : 0)) / (quizData.questions.length + 1))
    if (discount != quizState.discount)
        store.dispatch(setQuizStateIem('discount', discount))
}

function handlerChangeDiscountOneStep(store) {
    const state = store.getState()
    const quizState = state.quizState
    const quizData = state.quizData.toJS()


    if(quizState.loading) return;
    if(!quizData.info.discount || !quizData.info.discount.value  || ['increasing', 'increasingPercent'].indexOf(quizData.info.discount.type) == -1) return;

    const discountOneStep = Math.round(quizData.info.discount.value  / (quizData.questions.length + 1))
    if (discountOneStep != quizState.discountOneStep)
        store.dispatch(setQuizStateIem('discountOneStep', discountOneStep))
}





