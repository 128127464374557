import React from "react";
import {FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField} from "@material-ui/core";
import {t} from "leadformsquizentities/translate";

const View = ({
                  onChangeLanguage,
                  language,
                  notAutoNextStep,
                  onChangeNotAutoNextStep,
                  onChangeNameQuiz,
                  name
              }) => (
    <div>
        <FormControl fullWidth>
            <InputLabel shrink >
                Название квиза
            </InputLabel>
            <TextField
                margin="normal"
                fullWidth
                value={name}
                placeholder="Название квиза"
                onChange={onChangeNameQuiz}
                variant="filled"
            />
        </FormControl>
        <br/><br/>
        <FormControl>
            <InputLabel shrink htmlFor="age-label-placeholder">
                Язык
            </InputLabel>
            <Select onChange={onChangeLanguage} fullWidth value={language}>
                <MenuItem value={"russian"}>
                    русский
                </MenuItem>
                <MenuItem value={"english"}>
                    английский
                </MenuItem>
                <MenuItem value={"turkish"}>
                    турецкий
                </MenuItem>
            </Select>
        </FormControl>
        <br/><br/>
        <FormControl>
            <FormControlLabel
                control={
                    <Switch
                        checked={!notAutoNextStep}
                        onChange={onChangeNotAutoNextStep}
                    />
                }
                label={t("Переключать на следующий вопрос при выборе ответа")}
            />
        </FormControl>
    </div>
)


export default (View);
