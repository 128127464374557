import React from "react";
import {
   Button,
   TextField,
   InputProps,
   FormLabel,
   withStyles,
   RadioGroup,
   FormControlLabel,
   Radio,
   FormGroup,
   Checkbox,
   Grid,
   Typography
} from "@material-ui/core";

import {
   FileCopy
} from "@material-ui/icons";

import { TwitterPicker } from 'react-color';

import screen from '../../../../../img/screen.png'
import screenMobile from '../../../../../img/screen_mobile.png'
import  '../../../../../style.css'
import  '../../../../../PreviewFixedButton.css'
var Color = require('color');



const getCodePreviewFixed  = function(props){
       let classes = `PreviewFixedButton Leadforms__button ${(props.rounded) ? 'Leadforms__button_rounded ' : ''}${(props.shadow) ? 'Leadforms__button_shadow ' : ''}${(props.blicked) ? 'Leadforms__button_blicked ' : ''}${(props.fixed) ? 'Leadforms__button_fixed ' : ''}${(props.float) ? `is-${props.float}` : ''}`

        return (
            `<div class="button-elector__preview_fixed"
                >
                    <div class="button-elector__preview_screen">
                        <img src="${String(screen)}"/>
                            <button class="${classes}"> 
                                <svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 434.168 434.168" xml:space="preserve" class="icon-quiz ${(props.float) ? `is-${props.float}` : ''}"><g><path d="M332.318,230.196V34.967H40.93v364.235h134.038c9.616,0,17.483,7.867,17.483,17.483s-7.867,17.483-17.483,17.483H23.446c-9.616,0-17.483-7.867-17.483-17.483V17.483C5.963,7.867,13.831,0,23.446,0h326.354c9.616,0,17.483,7.867,17.483,17.483v212.713c0,9.616-7.867,17.483-17.483,17.483S332.318,239.812,332.318,230.196z M422.357,272.739c-7.285-6.411-18.357-5.828-24.768,1.457l-95.867,106.648l-48.079-46.331c-6.993-6.702-18.066-6.411-24.768,0.583s-6.411,18.066,0.583,24.768l61.191,58.86c3.205,3.205,7.576,4.954,12.238,4.954c0.291,0,0.291,0,0.583,0c4.662-0.291,9.324-2.331,12.238-5.828l107.814-120.052C430.224,290.222,429.641,279.15,422.357,272.739z M268.212,101.986H110.863c-9.616,0-17.483,7.867-17.483,17.483s7.867,17.483,17.483,17.483h157.349c9.616,0,17.483-7.867,17.483-17.483S277.828,101.986,268.212,101.986z M285.696,215.627c0-9.616-7.867-17.483-17.483-17.483H110.863c-9.616,0-17.483,7.867-17.483,17.483c0,9.616,7.867,17.483,17.483,17.483h157.349C277.828,233.11,285.696,225.243,285.696,215.627z M110.863,291.388c-9.616,0-17.483,7.867-17.483,17.483c0,9.616,7.867,17.483,17.483,17.483h46.622c9.616,0,17.483-7.867,17.483-17.483c0-9.616-7.867-17.483-17.483-17.483H110.863z"></path></g></svg>
                                <span>${props.buttonText}</span>
                            </button>
                    </div>
                    <div class='button-elector__preview_mobile'>
                        <img src="${String(screenMobile)}"/>
                            <button class="is-mobile ${classes}"> 
                                <svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 434.168 434.168" xml:space="preserve" class="icon-quiz ${(props.float) ? `is-${props.float}` : ''}"><g><path d="M332.318,230.196V34.967H40.93v364.235h134.038c9.616,0,17.483,7.867,17.483,17.483s-7.867,17.483-17.483,17.483H23.446c-9.616,0-17.483-7.867-17.483-17.483V17.483C5.963,7.867,13.831,0,23.446,0h326.354c9.616,0,17.483,7.867,17.483,17.483v212.713c0,9.616-7.867,17.483-17.483,17.483S332.318,239.812,332.318,230.196z M422.357,272.739c-7.285-6.411-18.357-5.828-24.768,1.457l-95.867,106.648l-48.079-46.331c-6.993-6.702-18.066-6.411-24.768,0.583s-6.411,18.066,0.583,24.768l61.191,58.86c3.205,3.205,7.576,4.954,12.238,4.954c0.291,0,0.291,0,0.583,0c4.662-0.291,9.324-2.331,12.238-5.828l107.814-120.052C430.224,290.222,429.641,279.15,422.357,272.739z M268.212,101.986H110.863c-9.616,0-17.483,7.867-17.483,17.483s7.867,17.483,17.483,17.483h157.349c9.616,0,17.483-7.867,17.483-17.483S277.828,101.986,268.212,101.986z M285.696,215.627c0-9.616-7.867-17.483-17.483-17.483H110.863c-9.616,0-17.483,7.867-17.483,17.483c0,9.616,7.867,17.483,17.483,17.483h157.349C277.828,233.11,285.696,225.243,285.696,215.627z M110.863,291.388c-9.616,0-17.483,7.867-17.483,17.483c0,9.616,7.867,17.483,17.483,17.483h46.622c9.616,0,17.483-7.867,17.483-17.483c0-9.616-7.867-17.483-17.483-17.483H110.863z"></path></g></svg>
                            </button>
                    </div>
                </div>`
                )

}


const Preview  = ({background, fixed, code,  rounded, shadow, blicked, float, buttonText}) => {
        const style = {'--Leadforms-button-alpha-color': background, '--Leadforms-button-color': background, '--Leadforms-button-text-color': Color(background).isDark() ? "#ffffff" : "#000000"}
        return(
            <div className="content" style={style} dangerouslySetInnerHTML={{__html: (fixed) ? getCodePreviewFixed({buttonText, background, fixed, rounded, shadow, blicked, float}): code}}/>
        )
}



const View = ({
    copyToClipboard,
    changeRounded,
    changeShadow,
    changeBlicked,
    changeFixed,
   fixed,
   blicked,
   shadow,
   rounded,
   float,
   background,
   changeButtonText,
   buttonText,
   changeFloat,
   handleChangeColor,
   preview,
   code,
    classes
}) => (

    <Grid container>
         <Grid item sm={6}>
        <Typography variant="h6">Создайте кнопку с помощью конструктора</Typography>
        <br/><br/>
        <FormGroup>
         <TextField
         fullWidth
                label="Текст кнопки"
                value={buttonText}
                onChange={changeButtonText}
               placeholder="Текст кнопки"
              />
              </FormGroup>
              <br/>
                     
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox checked={rounded} onChange={changeRounded}/>
                            }
                            label="Закругленная"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox checked={shadow} onChange={changeShadow}/>
                            }
                            label="С тенью"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox checked={blicked} onChange={changeBlicked}/>
                            }
                            label="С бликом"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox checked={fixed} onChange={changeFixed}/>
                            }
                            label="Фиксированная"
                          />
                        </FormGroup>

               
                            {fixed &&
                                <RadioGroup
                                  row
                                  value={float}
                                  onChange={changeFloat}
                              >
                                  <FormControlLabel value="left" control={<Radio/>}
                                                    label={`Слева`}/>
                                  <FormControlLabel value="right" control={<Radio/>}
                                                    label={`Справа`}/>
                              </RadioGroup>
                            }
                       <br/>
                        <TwitterPicker
                            color={ background }
                            onChangeComplete={handleChangeColor }
                        />
                        <br/><br/>
                   </Grid>

                    <Grid align="center" item sm={6} >
                        <Typography variant="h6" fontWeight="600"  >Предпросмотр</Typography>
                        <br/><br/>
                         <Preview background={background} buttonText={buttonText} fixed={fixed} code={code} float={float} rounded={rounded} shadow={shadow} blicked={blicked}/>
                    </Grid>
             

         
           <FormLabel>Скопируйте этот код и установите в то место, где должна быть кнопка открытия квиза</FormLabel>
           <TextField
                    fullWidth
                    multiline
                    variant="filled"
                    margin="dense"
                    value={code.replace(/\s+/g,' ').trim()}
                    InputProps={{
                        readOnly: true
                    }}
                />
          <Button variant="contained"  onClick={copyToClipboard.bind(this, code)}><FileCopy/> скопировать</Button>
    </Grid>
)

const styles = theme => ({
  copy:{
    cursor: 'pointer'
   }
});

export default  withStyles(styles)(View);












