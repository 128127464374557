import {QuizQuestion, DialogQuestion} from "newkitleadforms";
import React from "react";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {QuizQuestionInterface, AnswerDataTypes} from "leadformsquizentities/QuizQuestion";
import {store} from "../../../../_services";
import {Questions as QuizQuestionContainerOld} from "oldkitleadforms";
import LayoutAdmin from "../LayoutAdmin";
import QuizContainer from "../QuizContainer";
import {t} from "leadformsquizentities/translate";
import {updateQuizData, updateQuestion} from "../../../../_actions";

const View = ({isQuizBot, isNewTheme, dataQuiz, answerHandler, colorTheme}: {isQuizBot: boolean, colorTheme: string, isNewTheme: boolean, dataQuiz: QuizQuestionInterface, answerHandler: Function}) => {
    if (!isNewTheme) {
        return  <LayoutAdmin><div style={{background: 'white', width: '100%', height: '100%'}}>
            <QuizContainer color={colorTheme}>
            <QuizQuestionContainerOld
                key={dataQuiz.activeQuestionNumber}
                quizData={dataQuiz}
                nextQuestionHandler={()=>{}}
                prevQuestionHandler={()=>{}}
                answerHandler={(answerKey: number|string, value: any) => answerHandler(dataQuiz.question.id, answerKey, value)}
                buttonNextCaption={t('Далее')}
                isAdmin={true}
            /></QuizContainer></div></LayoutAdmin>
    }

    if (isQuizBot) {
        return (
            <div style={{background: 'white',  borderRadius: 6, overflow: 'hidden', width: 375,  height: 550, margin: 'auto'}}>
                <DialogQuestion
                    key={dataQuiz.activeQuestionNumber}
                    data={dataQuiz}
                    nextQuestionHandler={()=>{}}
                    prevQuestionHandler={()=>{}}
                    answerHandler={answerHandler}
                    buttonNextCaption={t('Далее')}
                    isAdmin={true}
                /></div>);
    }
    return (
        <div style={{background: 'white', width: '100%', borderRadius: 6, overflow: 'hidden'}}>
        <QuizQuestion
            key={dataQuiz.activeQuestionNumber}
            data={dataQuiz}
            nextQuestionHandler={()=>{}}
            prevQuestionHandler={()=>{}}
            answerHandler={answerHandler}
            buttonNextCaption={t('Далее')}
            isAdmin={true}
        /></div>);
}


const getDiscount = () => {
    const quizState = store.getState().quizState;
    const quizData = store.getState().quizData.toJS();

    if(quizData.info.discount){
        if (quizData.info.discount.type === 'melting') {
            const currency = quizData.info.discount.currency ? quizData.info.discount.currency : "₽";
            return {saleAmount: {value: `${quizState.discount}`.replace(/\B(?=(\d{3})+(?!\d))/g, " "), mark: currency}};
        }
        if(quizData.info.discount.type === 'increasing'){
            const currency = quizData.info.discount.currency ? quizData.info.discount.currency : "₽";
            return {saleForStep: {value: `${quizState.discountOneStep}`.replace(/\B(?=(\d{3})+(?!\d))/g, " "), mark: currency}, saleAmount: {value: `${quizState.discount}`.replace(/\B(?=(\d{3})+(?!\d))/g, " "), mark: currency}};
        }
        if(quizData.info.discount.type === 'increasingPercent'){
            return {saleForStep: {value: `${quizState.discountOneStep}`, mark: '%'}, saleAmount: {value: `${quizState.discount}`, mark: '%'}};
        }
    }

    return undefined;
}

const mapStateToProps = (state: any) => {

    const quizState = state.quizState;
    const quizData = state.quizData.toJS();
    const questionActive = quizData.questions[quizState.activeKeyItem];
    const isNewTheme = quizData.isNewTheme;
    const progress = Math.round(100 * quizState.activeKeyItem / (quizData.questions.length + (isNewTheme ? 1 : 0)));
    const colorTheme = true !== quizData.isEmpty ? quizData.info.design.colors.main : '';
    const sale = getDiscount();

    const dataQuiz: QuizQuestionInterface = {
        sale: getDiscount(),
        activeQuestionNumber: quizState.activeKeyItem + 1,
        serialQuestionNumber: quizState.activeKeyItem + 1,
        countQuestion: quizData.questions.length + 1,
        manager: quizData.info.assistant.name ?  {
            name: quizData.info.assistant.name,
            job: quizData.info.assistant.title,
            avatarImage: quizData.info.assistant.avatar ? quizData.info.assistant.avatar : undefined
        }: undefined,
        title: quizData.info.title,
        tipText: questionActive.text,
        question: {id: questionActive.id, title: questionActive.title, answersData: getAnswers(questionActive, quizState.clientData.answers[questionActive.id])},
        isDisabledPrevButton: true,
        isDisabledNextButton: true,
        progress: progress,
        additionalIsOpen: true && (quizData.info.assistant.name || questionActive.text || sale),
        // hasBonus: quizData.info.form.extra,
        hasBonus: false,
        tagText: undefined,
        whitelabel: !!quizData.whitelabel,
        isNoRequired: !! questionActive.isNoRequired,
        isMultiple: true === questionActive.multySelect
    };

    return {
        isQuizBot: !!quizData.isQuizBot,
        colorTheme: colorTheme,
        dataQuiz: dataQuiz,
        isNewTheme: isNewTheme
    }
};

const getAnswers = (questionActive: any, questionAnswers: any): AnswerDataTypes => {
    switch (questionActive.type) {
        case 'answers':
            if(true === questionActive.multySelect) {
                return {
                    items: questionActive.answers.map((answer: any) => {return {key: answer.id, value: answer.title, checked: (questionAnswers && questionAnswers[answer.id]) ? true : false}}),
                    type: 'checkbox',
                    hasOther: !!questionActive.other
                }
            } else {
                return {
                    items: questionActive.answers.map((answer: any) => {return {key: answer.id, value: answer.title, checked: (questionAnswers && questionAnswers[answer.id]) ? true : false}}),
                    type: 'radio',
                    hasOther: !!questionActive.other
                }
            }
            break;
        case 'input':
            const placeholder = questionActive.options.placeholder ?  questionActive.options.placeholder : t('Введите ответ');
            return {
                type: 'input',
                placeholder: placeholder,
                value: questionActive.options.placeholder
            }
        case 'images':
            return {
                type: 'image',
                typeImage: questionActive.typeImage ? questionActive.typeImage : 'default',
                items: questionActive.answers.map((answer: any) => {return {key: answer.id, value: answer.title, image: answer.image ? {server: answer.image.server, url: answer.image.url} : null, checked: (questionAnswers && questionAnswers[answer.id]) ? true : false}}),
                isMultiple: true === questionActive.multySelect
            }
        case 'select':
            return {
                type: 'select',
                items: questionActive.answers.map((answer: any) => {return {key: answer.id, value: answer.title, checked: (questionAnswers && questionAnswers[answer.id]) ? true : false}}),
                placeholder: questionActive.options.placeholder ? questionActive.options.placeholder : t('Выберите нужный вариант')
            }
        case 'date':
            return {
                type: 'date',
                dateFrom: questionAnswers ? questionAnswers.dateFrom : undefined,
                dateTo: questionAnswers ? questionAnswers.dateTo : undefined,
                isMultiple: !!questionActive.multySelect
            }
        case 'slider':
            const maxValue = +questionActive.sliderOptions.maxValue;
            const minValue = +questionActive.sliderOptions.minValue;

            const middle = (maxValue + minValue)/2;

            const values: [number, number]|[number]= (true === questionActive.sliderOptions.isMultiple) ?
            [Math.round((minValue + middle)/2 ), Math.round((maxValue + middle)/2)] :
            [Math.round(middle)];

            return {
                type: 'slider',
                max: maxValue,
                min: minValue,
                isMultiple: true === questionActive.sliderOptions.isMultiple,
                values: values
            }
        default:
        case 'select':
            return {
                type: 'select',
                placeholder: questionActive.options.placeholder,
                items: questionActive.answers.map((answer: any) => {return {key: answer.id, value: answer.title}}),
            }
    }


}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        answerHandler: (questionId: number, answerKey: number|string, value: any) => {
            const quizState = store.getState().quizState;
            const quizData = store.getState().quizData.toJS();
            const questionActive = quizData.questions[quizState.activeKeyItem];

            switch (questionActive.type) {
                case 'input':
                    questionActive.options.placeholder = value;
                    break;
                case 'slider':
                    if (answerKey === 'second') questionActive.sliderOptions.maxValue = value;
                    if (answerKey === 'first') questionActive.sliderOptions.minValue = value;
                    break;

                default:
            }
            store.dispatch(updateQuestion(questionActive.id, questionActive));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(View);
