import React from "react";
import {
    Grid,
    Typography,
    IconButton,
    CardActions,
    Card,
    CardContent,
} from "@material-ui/core";

import {Link} from "react-router-dom";

import {
    SupervisorAccount,
    Settings,
    Delete,
    FileCopy
} from '@material-ui/icons';
import {withStyles} from "@material-ui/core/styles";



const View = ({quiz,  handleOpenModalDeleteQuiz, createQuizHandler, classes}) => (
    <Grid item lg={6} md={6} sm={6} xs={12} className={classes.container}>
        <Card>
            <CardContent>
                <div className={classes.cardContent}>
                    <Typography color="textSecondary">
                        #{quiz.id}
                    </Typography>
                    <Typography noWrap variant="h5" component="h4" color="textPrimary">
                        {quiz.info.name}
                    </Typography>
                    {quiz.blocked && <div className={classes.blocked}><Typography>
                        заблокирован за неуплату
                    </Typography></div>}
                </div>
            </CardContent>
            <CardActions>
                <IconButton title="Заявки" component={Link} to={`/app/quizzes/answers/${quiz.id}`}>
                    <SupervisorAccount/>
                </IconButton>
                <IconButton title="Редактировать квиз" component={Link} to={`/app/quizzes/edit/${quiz.id}`}>
                    <Settings/>
                </IconButton>
                <IconButton title="Копировать квиз" onClick={createQuizHandler.bind(this, quiz.id)}>
                    <FileCopy/>
                </IconButton>
                <IconButton onClick={handleOpenModalDeleteQuiz.bind(this, quiz.id)} className={classes.close}
                            title="Удалить квиз">
                    <Delete/>
                </IconButton>
            </CardActions>
        </Card>
    </Grid>
);

const styles = theme => ({
    close: {
        marginLeft: 'auto'
    },
    container: {
        padding: theme.spacing(2)
    },
    blocked: {
        position: 'absolute',
        top: 5,
        right: 5,
        '& p': {
            color: 'red'
        }
    },
    cardContent: {
        position: 'relative'
    }
});

export default withStyles(styles)(View);