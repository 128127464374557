import {compose, lifecycle, withHandlers, withState} from 'recompose';
import { connect } from 'react-redux';
import {getDefaultPhoneMask} from '../../../../entities/Contacts/PhoneMask';
import View from './View';
import {updateQuizData} from "../../../../_actions";
import {t} from "leadformsquizentities/translate";
export default compose(
    connect(
        (state) => {
            const quizData = state.quizData.toJS();
            return {
                enabled: !!quizData.info.form.disabled,
                quizData: quizData,
                color: quizData.info.design.colors.main,
                contacts: quizData.info.form.contacts,
                hasExtra: quizData.info.form.extra,
                phoneMask: quizData.info.form.contacts.phone.mask ? quizData.info.form.contacts.phone.mask : getDefaultPhoneMask(),
                isQuizBot: quizData.isQuizBot
            }
        },
        {updateQuizData}
    ),
    withState('hasAdditionalText', 'changeHasAdditionalText', (props) => props.quizData.info.form.text ? true : false ),
    withState('isOpenPhoneMaskSettings', 'changeOpenPhoneMaskSettings', false),
    withHandlers({
        changeEnableContact: props => (keyContact, e) => {
            let quizData = props.quizData
            quizData.info.form.disabled = !quizData.info.form.disabled
            props.updateQuizData(quizData);
        },
        changeEnabledContactFieldHandler: props => (keyContact, e) => {
            let quizData = props.quizData
            quizData.info.form.contacts[keyContact].enabled = e.target.checked
            props.updateQuizData(quizData);
        },
        changeRequiredContactFieldHandler: props => (keyContact, e) => {
            let quizData = props.quizData
            quizData.info.form.contacts[keyContact].required = e.target.checked
            props.updateQuizData(quizData);
        },
        changeExtraHandler: props => () => {
            let quizData = props.quizData
            quizData.info.form.extra = (props.hasExtra) ? '' : t('Предложите клиенту бонус за ожидание результатов')
            props.updateQuizData(quizData);
        },
        changeAdditionalTextHandler: props => () => {
            let quizData = props.quizData
            quizData.info.form.text = (props.hasAdditionalText) ? '' : t('Убедите пользователя заполнить форму')
            props.changeHasAdditionalText(!props.hasAdditionalText)
            props.updateQuizData(quizData);
        },
        changePhoneMaskEnabled: props => () => {
            let quizData = props.quizData;
            if (!quizData.info.form.contacts.phone.mask) {
                quizData.info.form.contacts.phone.mask = getDefaultPhoneMask();
            }
            quizData.info.form.contacts.phone.mask.enabled = !quizData.info.form.contacts.phone.mask.enabled
            props.updateQuizData(quizData);
        }
    }),
    lifecycle({
        componentWillReceiveProps(nextProps) {
            if (this.props.hasAdditionalText && !nextProps.quizData.info.form.text)
                this.props.changeHasAdditionalText(false)
        }
    })
)(View);

