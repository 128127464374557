export class PhoneMask {
    public type: PhoneMaskType;
    public regexp: string | null;
    public phoneMaskCountries: PhoneCountriesInterface

    constructor(
        type: PhoneMaskType,
        regexp: string | null,
        phoneMaskCountries: PhoneCountriesInterface
    ) {
        this.type = type;
        this.regexp = regexp;
        this.phoneMaskCountries = phoneMaskCountries;
    }
}


export const getDefaultPhoneMask = () =>
    new PhoneMask(
        'country',
        null,
        {default: defaultCountryKey, ignored: [], only: [], preferred: []}
    );

export type PhoneMaskType = 'country' | 'mask';

export interface PhoneCountriesInterface {
    default: string
    ignored: string[]
    only: string[]
    preferred: string[]
};

const defaultCountryKey = 'ru';