export function authHeader(additional) {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        let headers = { 'Authorization': 'Bearer ' + user.token }
        if(additional){
            for (let key in additional){
                headers[key] = additional[key]
            }
        }
        return headers;
    } else {
        return (additional) ? additional : {};
    }
}