import {compose, lifecycle, withHandlers, withState} from 'recompose';
import {connect} from 'react-redux';
import View from './View';
import {updateQuizData} from '../../../../_actions';
import {t} from "leadformsquizentities/translate";

export default compose(
    connect(
        (state) => {
            const quizData = state.quizData.toJS();
            return {
            quizData: quizData,
            isNewTheme: !!quizData.isNewTheme,
            isQuizBot: !!quizData.isQuizBot
        }},
        {updateQuizData}
    ),

    withState('hasSubTitle', 'changeHasSubTitle', (props) => props.quizData.info.startPage.subtitle ? true : false),
    withState('hasTitle', 'changeHasTitle', (props) => props.quizData.info.startPage.title ? true : false),
    withState('hasPhone', 'changeHasPhone', (props) => props.quizData.info.startPage.phone ? true : false),
    withState('hasLegal', 'changeHasLegal', (props) => props.quizData.info.startPage.legal ? true : false),
    withState('hasRender', 'changeHasRender', (props) => props.quizData.info.startPage.bg ? true : false),
    withState('hasLogo', 'changeHasLogo', (props) => props.quizData.info.startPage.logo ? true : false),
    withState('hasName', 'changeHasName', (props) => props.quizData.info.startPage.name ? true : false),
    withState('hasTitlePage', 'changeHasTitlePage', (props) => props.quizData.info.startPage.pageTitle ? true : false),
    withState('hasFavicon', 'changeHasFavicon', (props) => props.quizData.info.startPage.favicon ? true : false),
    withHandlers({
        changeTitlePageHandler: props => () => {
            let quizData = props.quizData
            quizData.info.startPage.pageTitle = (props.hasTitlePage) ? '' : t('Title страницы')
            props.changeHasTitlePage(!props.hasTitlePage)
            props.updateQuizData(quizData);
        },
        changeHasFaviconHandler: props => () => {
            props.changeHasFavicon(!props.hasFavicon);
            if(true === props.hasFavicon){
                let quizData = props.quizData
                quizData.info.startPage.favicon = null
                props.updateQuizData(quizData);
            }
        },
        changeEnableStartPage: props => () => {
            props.quizData.info.startPage.enabled = !props.quizData.info.startPage.enabled
            props.updateQuizData(props.quizData);
        },
        changeTitleHandler: props => () => {
            let quizData = props.quizData
            quizData.info.startPage.title = (props.hasTitle) ? '' : t('Заголовок')
            props.changeHasTitle(!props.hasTitle)
            props.updateQuizData(quizData);
        },
        changeSubTitleHandler: props => () => {
            let quizData = props.quizData
            quizData.info.startPage.subtitle = (props.hasSubTitle) ? '' : t('Подзаголовок')
            props.changeHasSubTitle(!props.hasSubTitle)
            props.updateQuizData(quizData);
        },
        changePhoneHandler: props => () => {
            let quizData = props.quizData
            quizData.info.startPage.phone = (props.hasPhone) ? '' : t('Телефон')
            props.changeHasPhone(!props.hasPhone)
            props.updateQuizData(quizData);
        },
        changeLegalHandler: props => () => {
            let quizData = props.quizData
            quizData.info.startPage.legal = (props.hasLegal) ? '' : t('Юридическая информация')
            props.changeHasLegal(!props.hasLegal)
            props.updateQuizData(quizData);
        },
        changeRenderHandler: props => () => {
            let quizData = props.quizData
            quizData.info.startPage.bg = (props.hasRender) ? null : {server: '',  url: ''}
            props.changeHasRender(!props.hasRender)
            props.updateQuizData(quizData);
        },
            changeLogoHandler: props => () => {
            let quizData = props.quizData
            quizData.info.startPage.logo = (props.hasLogo) ? null : {server: '',  url: ''}
            props.changeHasLogo(!props.hasLogo)
            props.updateQuizData(quizData);
        },
            changeNameHandler: props => () => {
            let quizData = props.quizData
            quizData.info.startPage.name = (props.hasName) ? null : t('Название или слоган')
            props.changeHasName(!props.hasName)
            props.updateQuizData(quizData);
        },
    }),
    lifecycle({
        componentWillReceiveProps(nextProps) {
            if (this.props.hasSubTitle && !nextProps.quizData.info.startPage.subtitle)
                this.props.changeHasSubTitle(false)

            if (this.props.hasTitle && !nextProps.quizData.info.startPage.title)
                this.props.changeHasTitle(false)

            if (this.props.hasPhone && !nextProps.quizData.info.startPage.phone)
                this.props.changeHasPhone(false)

            if (this.props.hasLegal && !nextProps.quizData.info.startPage.legal)
                this.props.changeHasLegal(false)

            if (this.props.hasName && !nextProps.quizData.info.startPage.name)
                this.props.changeHasName(false)

            if (this.props.hasTitlePage && !nextProps.quizData.info.startPage.pageTitle)
                this.props.changeHasTitlePage(false)
        }
    })
)(View);

