import {compose, withHandlers} from 'recompose';
import View from './View';
import {connect} from "react-redux";
import {createQuiz} from "../../../../_actions";
export default compose(
    connect(
        state => ({}),
        {
            createQuiz
        }
    ),
    withHandlers({
        createQuizHandler: props => (copyQuizId) => {
            props.createQuiz(copyQuizId)
        },
    }),
)(View);

