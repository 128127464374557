import React from "react";
import {
    FormControlLabel,
    Switch,
    TextField,
    Button,
    Link,
    InputLabel,
    FormGroup,
    FormControl,
    Select, Chip, IconButton, withStyles, Input,
    RadioGroup, Radio, MenuItem
} from "@material-ui/core";
import {t} from "leadformsquizentities/translate";
import {values as valuesAdditionalFieldLeadForms} from "../../../../../entities/Integrations/AdditionalFieldLeadForms";
import {ArrowRightAlt, DeleteOutlined} from "@material-ui/icons";

const View = ({
                  testConnectionBitrix24,
                  message,
                  bitrix24Loading,
                  changebitrix24Data,
                  bitrix24Data,
                  changeNotificationCheckedHandler,
                  showAdditional,
                  additionalFieldLeadforms,
                  setAdditionalFieldLeadforms,
                  additionalFieldBitrix,
                  setAdditionalFieldBitrix,
                  addAdditionalFieldHandler,
                  deleteAdditionalFieldHandler,
                  classes
              }) => (

    <div>

        <FormControlLabel
            control={
                <Switch
                    checked={bitrix24Data.enabled}
                    onChange={changeNotificationCheckedHandler}
                />
            }
            label={t("Bitrix24")}
        />
        <Link target="_blanc" href="https://leadforms.ru/instructions/bitrix24">Смотреть инструкцию</Link>

        {bitrix24Data.enabled &&

        <div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <TextField
                    required
                    value={bitrix24Data.domain}
                    placeholder="Домен"
                    onChange={(e) => changebitrix24Data("domain", e.target.value)}
                    variant="filled"
                    helperText="Домен (Пример: b24-slsgct)"
                />
                <TextField
                    style={{width: 70}}
                    required
                    value={'.bitrix.'}
                    disabled
                    variant="filled"
                />
                <FormControl variant="filled">
                    <Select
                        variant="filled"
                        value={bitrix24Data.country ? bitrix24Data.country : 'ru'}
                        onChange={(e) => changebitrix24Data("country", e.target.value)}
                    >
                        <MenuItem value={'ru'}>ru</MenuItem>
                        <MenuItem value={'by'}>by</MenuItem>
                        <MenuItem value={'ua'}>ua</MenuItem>
                        <MenuItem value={'com'}>com</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <TextField
                margin="normal"
                required
                fullWidth
                value={bitrix24Data.token}
                placeholder="Код авторизации"
                onChange={e => changebitrix24Data("token", e.target.value)}
                variant="filled"
                helperText="Код авторизации  (Пример: 6bnlplhyu2znj2op)"
            />

            <TextField
                margin="normal"
                required
                fullWidth
                value={bitrix24Data.user}
                placeholder="ID пользователя"
                onChange={e => changebitrix24Data("user", e.target.value)}
                variant="filled"
                helperText="ID пользователя (Пример: 1)"
            />
            <br/><br/>
            <div>
                {bitrix24Loading && <p>Загрузка...</p>}
                {!bitrix24Loading && message &&
                <>{message}</>
                }
                {!bitrix24Loading && !message &&
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={testConnectionBitrix24}
                >
                    проверить подключение
                </Button>
                }
                <br/><br/>
            </div>
            {showAdditional &&
            <div>
                <hr/>
                <FormControl>
                    <InputLabel shrink>Тип отправки</InputLabel>
                    <br/>
                    <RadioGroup row value={bitrix24Data.isDeal ? true : false}>
                        <FormControlLabel value={true} control={<Radio />} label="сделка" onChange={() => changebitrix24Data("isDeal", true)}/>
                        <FormControlLabel value={false} control={<Radio />} label="лид"  onChange={() => changebitrix24Data("isDeal", false)}/>
                    </RadioGroup>
                </FormControl>
                <TextField
                    margin="normal"
                    fullWidth
                    value={bitrix24Data.title}
                    placeholder="Заголовок"
                    onChange={e => changebitrix24Data("title", e.target.value)}
                    variant="filled"
                    helperText="Необязательный параметр. Заголовок лида (Заявка с LeadForms)"
                />
                <br/><br/><br/>
                <InputLabel shrink>
                    Передавать дополнительные поля
                </InputLabel>
                <br/>
                <FormGroup row>
                    <FormControl className={classes.formControl}>
                        <Select
                            value={0 !== additionalFieldLeadforms ? additionalFieldLeadforms.value : 0}
                            onChange={(e) => setAdditionalFieldLeadforms(valuesAdditionalFieldLeadForms[e.target.value])}
                            native
                        >
                            <option value={0}>не выбрано</option>
                            {Object.values(valuesAdditionalFieldLeadForms).map(field =>
                                <option value={field.value}>{field.name}</option>
                            )}
                        </Select>
                    </FormControl>
                    <ArrowRightAlt className={classes.arrowRight}/>
                    <FormControl className={classes.formControl}>
                        <Input
                            value={additionalFieldBitrix}
                            onChange={(e) => setAdditionalFieldBitrix(e.target.value)}
                            placeholder={'UF_CRM_1517067347'}
                        />
                    </FormControl>
                    {(additionalFieldBitrix && 0 !== additionalFieldLeadforms) &&
                    <Button color={'primary'} size={'small'} variant={'outlined'} onClick={addAdditionalFieldHandler}>
                        добавить
                    </Button>
                    }
                </FormGroup>
                <br/>
                {bitrix24Data.additionalFields && bitrix24Data.additionalFields.map((additionalField, key) =>

                    <FormGroup row key={key}>
                        <FormControl className={classes.formControl}>
                            <Chip label={additionalField.leadforms.name}/>
                        </FormControl>
                        <ArrowRightAlt className={classes.arrowRight}/>
                        <FormControl className={classes.formControl}>
                            <Chip label={additionalField.bitrix}/>
                        </FormControl>
                        <IconButton onClick={() => deleteAdditionalFieldHandler(key)}>
                            <DeleteOutlined/>
                        </IconButton>
                    </FormGroup>
                )}
            </div>}
        </div>

        }
    </div>
)

const styles = (theme) => ({
    formControl: {
        marginTop: 10,
        marginRight: 10,
        marginLeft: 10,
        width: 220,
    },
    arrowRight: {
        marginTop: 10,
        color: theme.palette.secondary.light
    }
});

export default withStyles(styles)(View);
