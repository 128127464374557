import React from "react";
import FullScreenDialog from '../../../../../components/FullScreenDialog';
import {FormControlLabel, Switch, TextField, Button, Typography, Link} from "@material-ui/core";
import NotificationComponent  from "../../../../components/Notification";
import {t} from "leadformsquizentities/translate";
const View = ({
                  disabledHandler,
                  data,
                  changeDataHandler,
                  saveDataHandler,
                  instructionData,
                  closeInstructionHandler,
                  openInstructionHandler,
                  successSave
}) => { 

    const instructionImage = instructionData ? require(`../../../../img/instruction_analytic/${instructionData}.png`) : null
    return (
    <div>

                <FormControlLabel
                    control={
                        <Switch
                            checked={true}
                            onChange={disabledHandler}
                        />
                    }
                    label={t("Google Analytics")}
                />
                <Link target="_blanc" href="https://leadforms.ru/instructions/googleanalytics">Смотреть инструкцию</Link>
                <TextField
                    fullWidth
                    onChange={changeDataHandler} 
                    value={data} 
                    placeholder="Идентификатор отслеживания" 
                    InputProps={{
                        endAdornment: (<Button onClick={saveDataHandler}>сохранить</Button>)
                    }}
                />

                {successSave &&
                <NotificationComponent  shadowless type="feedback" message="Сохранено" variant="rounded" color="success"/>
                }
                <Typography>
                <small className="text-secondary">Пример: UA-111111111-1</small><br/>
                <small>Leadforms отправляет в ваш счётчик Google Analytics события:
                    <br/> - <strong>Посетитель открыл квиз</strong> (Action/Действие = leadforms_open, Category/Категория = leadforms)
                    <br/> - <strong>Посетитель нажал на кнопку стартовой страницы</strong> (Action/Действие = leadforms_start, Category/Категория = leadforms)
                    <br/> - <strong>Посетитель прошёл вопрос N</strong> (Action/Действие = leadforms_stepN, Category/Категория = leadforms)
                    <br/> - <strong>Посетитель оставил контакты</strong> (Action/Действие = leadforms_finish, Category/Категория = leadforms).
                    <br/>Вы можете настроить определение этих событий в цели Google Analytics.</small>
                <br/><br/>
                <p>Как настроить цели прохождения квиза в Google Analytics:</p>
                <ol>
                    <li><a href="https://analytics.google.com/analytics/web/" target="_blank">В Google Analytics</a> перейдите в Администратор / Цели. <br/><small style={{cursor: 'pointer'}} onClick={openInstructionHandler.bind(this, 'ga-1')}>пример</small></li>
                    <li>Нажмите <strong>Добавить цель</strong>, выберите <strong>Собственная</strong> <br/>в списке параметров и нажмите кнопку <strong>Далее</strong> <br/><small style={{cursor: 'pointer'}} onClick={openInstructionHandler.bind(this, 'ga-2')}>пример</small></li>
                    <li>
                        Введите название цели (<span>Посетитель открыл квиз</span> или <span className="font-italic">Посетитель оставил контакты</span>) и выберите Тип - <strong>Событие</strong>, затем нажмите <strong>Далее</strong>
                        <br/>
                        <small style={{cursor: 'pointer'}} onClick={openInstructionHandler.bind(this, 'ga-3-1')}>пример 1</small>
                        &nbsp;
                        <small style={{cursor: 'pointer'}} onClick={openInstructionHandler.bind(this, 'ga-3-2')}>пример 2</small>
                    </li>
                    <li>
                        Укажите поле <strong>Действие</strong> равное идентификатору события вашей цели (<span className="font-italic">leadforms_open</span> или <span className="font-italic">leadforms_finish</span>)  и поле <strong>Категория</strong> равное <span className="font-italic">leadforms</span>, затем нажмите  <strong>сохранить</strong>
                        <br/>
                        <small style={{cursor: 'pointer'}} onClick={openInstructionHandler.bind(this, 'ga-4-1')}>пример 1</small>
                        &nbsp;
                        <small style={{cursor: 'pointer'}} onClick={openInstructionHandler.bind(this, 'ga-4-2')}>пример 2</small>
                    </li>
                </ol>
                </Typography>

        {instructionData && 
             <FullScreenDialog modalTitle={'Назад'} handleClose={closeInstructionHandler}>
                <img style={{'max-width': '100%'}} src={String(instructionImage)} />
            </FullScreenDialog>
        }
    </div>
)}


export default (View);
