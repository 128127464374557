import {compose, withState, withHandlers} from "recompose";
import {connect} from "react-redux";
import View from "./View";
import { login, cpResetError } from '../../../_actions';
export default compose(
    connect(
        state => ({
            errorMessage: state.cpState.toJS().error,
            appLoading: state.cpState.toJS().loading
        }),
        {login, cpResetError}
    ),

    withState("loginValue", "setLoginValue", ""),
    withState("passwordValue", "setPasswordValue", ""),
    withHandlers({
        handleInput: props => (e, input = "login") => {
            if (props.errorMessage) {
                props.cpResetError();
            }
            if (input === "login") {
                props.setLoginValue(e.target.value);
            } else if (input === "password") {
                props.setPasswordValue(e.target.value);
            }
        },
        handleLoginButtonClick: props => () => {
            props.login(props.loginValue, props.passwordValue);
        }
    })
)(View);
