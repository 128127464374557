import {compose, withState, withHandlers, lifecycle} from 'recompose';
import { connect } from 'react-redux';
import View from './View';
import React from "react";

const titleValues = {
    email: 'Настройки Email',
    settings: 'Общие настройки',
    bitrix24: 'Настройки Bitrix24',
    amocrm: 'Настройки AmoCrm',
    metrika: 'Настройки Yandex Metrika',
    googleAnalytics: 'Настройки Google Analytics',
    webHooks: 'Настройки Webhooks',
    telegram: 'Настройки Telegram',
    roistat: 'Настройки Roistat',
    unisender: 'Настройки Unisender',
    uOnTravel: 'Настройки U-ON Travel',
    admitad: 'Настройки Admitad',
}

export default compose(
    connect(
        (state) => {
            const quizData = state.quizData.toJS();
            const hasEmail = quizData.notifications.selfEmail.active ||
                quizData.notifications.email.addresses.length >= 1;
            return {
                hasEmail: hasEmail,
                enabledAmoCrm: quizData.notifications.amocrm.enabled,
                enabledBitrix24: quizData.notifications.bitrix24.enabled,
                googleAnalyticsEnabled: quizData.info.analytic.ga.id ? true : false,
                metrikaEnabled: quizData.info.analytic.metrika.id ? true : false,
                webHooksCount: quizData.notifications.webHooks.length,
                facebookEnabled: quizData.info.analytic.facebook ? true : false,
                vkEnabled: quizData.info.analytic.vk ? true : false,
                telegramCount: quizData.notifications.telegramUsers.length,
                roistatEnabled: quizData.info.analytic.roistat ? true : false,
                unisenderEnabled: quizData.notifications.unisender && quizData.notifications.unisender.enabled && quizData.notifications.unisender.selectedItem && quizData.notifications.unisender.apiKey ? true : false,
                uOnTravelEnabled: quizData.notifications.uOnTravel ? true : false,
                enabledAdmitad: quizData.notifications.admitad && quizData.notifications.admitad.enabled,
            }
        }
    ),
    withState('modal', 'setModal', null),
    withState('modalTitle', 'setModalTitle', null),
    withHandlers({
        changeModal: (props) => (key, e) => {
            props.setModal(key);
            props.setModalTitle( titleValues[key] ? titleValues[key] : null);
        }
    })
)(View);


