import {answerService} from '../_api';
import {setQuizData, cpCompleteLoading, cpAddError, cpStartLoading,  updateQuizData} from './';
import arrayMove from "array-move";


export const addResult = (resultData) => (dispatch, getState) => {
    const quizData = getState().quizData.toJS()
    let key = 0;

    quizData.info.results.items.forEach((item)=> {
        if(item.key >= key) {
            key = item.key + 1;
        }
    });

    resultData.key = key;
    quizData.info.results.items.push(resultData);
    dispatch(updateQuizData(quizData));
};


export const sortResult = (newIndex, oldIndex) => (dispatch, getState) => {
    dispatch(cpStartLoading());
    const quizData = getState().quizData.toJS();
    const results = quizData.info.results;

    results.items = arrayMove( results.items, oldIndex, newIndex);

    quizData.info.results = results ;

    dispatch(updateQuizData(quizData));
    dispatch(cpCompleteLoading());
}


export const deleteResult = (resultKey) => (dispatch, getState) => {
    const quizData = getState().quizData.toJS()
    const results = quizData.info.results;
    let indexResult = null
    results.items.forEach((val, index) => {
        if(val.key === resultKey) indexResult = index
    })

    results.items.splice(indexResult, 1)
    quizData.info.results = results;
    dispatch(updateQuizData(quizData));
}

export const updateResult = (resultKey, resultData) => (dispatch, getState) => {
    const quizData = getState().quizData.toJS()
    const results = quizData.info.results;
    let indexResult = null
    results.items.forEach((val, index) => {
        if(val.key === resultKey) indexResult = index
    })

    results.items[indexResult] = resultData
    quizData.info.results = results;
    dispatch(updateQuizData(quizData));
}