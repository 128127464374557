import React from "react";
import {Button, Dialog, DialogActions, DialogContent, Typography, withStyles} from "@material-ui/core";


const View = ({classes, answer, closeAnswerFullInfo}) => (
    <Dialog open={true} onClose={closeAnswerFullInfo}>
        <DialogContent>
            {answer.contacts.name && <Typography><b>Имя</b>: {answer.contacts.name}</Typography>}
            {answer.contacts.email && <Typography><b>Email</b>: {answer.contacts.email}</Typography>}
            {answer.contacts.phone && <Typography><b>Телефон</b>: {answer.contacts.phone}</Typography>}
            {
                Object.values(answer.answers2).map((item) => {
                    return (
                        <Typography><b>{item.q}: </b>
                            {typeof item.a === 'object' ? Object.values(item.a).join(', ') : item.a  }
                        </Typography>
                    )
                }
            )}
            <Typography>
                <b>Время заявки:</b> {(answer.created) ? answer.created.toString() : null}
            </Typography>
            {answer.extra.discount &&
            <Typography>
                <b>Скидка:</b> {answer.extra.discount}
            </Typography>
            }
            {answer.extra.utm && Object.keys(answer.extra.utm).length > 0 &&
            <Typography>
                <b>UTM-метки:</b>&nbsp;
                {Object.keys(answer.extra.utm).map((key) => {
                        return (
                            <Typography><b>{key}: </b>
                                {answer.extra.utm[key]}
                            </Typography>
                        )
                    }
                )}
            </Typography>
            }

            {answer.extra.cookies && Object.keys(answer.extra.cookies).length > 0 &&
            <Typography>
                <b>Cookies:</b>&nbsp;
                {Object.keys(answer.extra.cookies).map((key) => {
                        return (
                            <Typography><b>{key}: </b>
                                {answer.extra.cookies[key]}
                            </Typography>
                        )
                    }
                )}
            </Typography>
            }
            {answer.extra.href && <Typography>
                <b>Url:</b> <a href={answer.extra.href} target="_blanc">{answer.extra.href}</a>
            </Typography>}
            {answer.extra.ref && <Typography>
                <b>Ref:</b> <a href={answer.extra.ref} target="_blanc">{answer.extra.ref}</a>
            </Typography>}
        </DialogContent>
        <DialogActions>
            <Button onClick={closeAnswerFullInfo} color="primary">
                закрыть
            </Button>
        </DialogActions>
    </Dialog>
)
const styles = theme => ({})

export default withStyles(styles)(View);

