import {compose, withHandlers, withState, lifecycle} from 'recompose';
import View from './View';
import { connect } from 'react-redux';

var Color = require('color');


const getCode = function(props){
                  const colorText = Color(props.background).isDark() ? "#ffffff" : "#000000";
                    let classes = `${(props.rounded) ? 'Leadforms__button_rounded ' : ''}${(props.shadow) ? 'Leadforms__button_shadow ' : ''}${(props.blicked) ? 'Leadforms__button_blicked ' : ''}${(props.fixed) ? 'Leadforms__button_fixed ' : ''}${(props.float && props.fixed) ? `Leadforms__button_fixed-${props.float}` : ''}`
                    const code  =
                    `<div class="Leadforms__container" >
                        <a class="Leadforms__button ${classes}" href="#popup:Leadforms_${props.quizData.id}" data-fixed-side="${(props.float && props.fixed) ? props.float : ''}" data-text-color="${colorText}" data-alpha-color="${props.background}" data-color="${props.background}"> 
                            ${props.buttonText}
                        </a>
                    </div>`;
                return code;
};



export default compose(
   connect(
        (state) => ({
            quizData: state.quizData.toJS()
        })
    ),
    withState('background', 'setBackground', '#00D084'),
    withState('buttonText', 'setButtonText', 'Пройти тест'),
    withState('preview', 'setPreview', ''),
    withState('rounded', 'setRounded', false),
    withState('shadow', 'setShadow', false),
    withState('blicked', 'setBlicked', false),
    withState('fixed', 'setFixed', false),
    withState('float', 'setFloat', 'left'),
   withState('code', 'setCode', (props) => getCode(props)),

    withHandlers({
        changeRounded: props => (e) => {
            props.setRounded(!props.rounded)
            props.setCode(getCode({...props, rounded:!props.rounded}));
        },
        changeShadow: props => (e) => {
            props.setShadow(!props.shadow)
            props.setCode(getCode({...props, shadow:!props.shadow}));
        },
        changeBlicked: props => (e) => {
            props.setBlicked(!props.blicked)
            props.setCode(getCode({...props, blicked:!props.blicked}));
        },
        changeFixed: props => (e) => {
            props.setFixed(!props.fixed)
            props.setCode(getCode({...props, fixed:!props.fixed}));
        },
        changeFloat: props => (e) => {
            props.setFloat(e.target.value)
            props.setCode(getCode({...props, float:e.target.value}));
        },
        changeButtonText: props => (e) => {
            props.setButtonText(e.target.value)
            props.setCode(getCode({...props, buttonText:e.target.value}));
        },
        handleChangeColor: props => color => {
             props.setBackground(color.hex)
             props.setCode(getCode({...props, background:color.hex}));
        }
    })
)(View);


