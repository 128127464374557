import {compose, withState, withHandlers} from "recompose";
import {config} from "../../../../_services";
import {orderService} from "../../../../_api";
import View from "./View";
import connect from "react-redux/es/connect/connect";

export default compose(
    connect(
        state => ({
            quizzes: state.quizzes.toJS().list,
            whiteLabels: state.userInfo.toJS().readOnly.whitelabels
        })
    ),

    withState('selectQuizId', 'setSelectQuizId', 0),
    withState('OutSum', 'setOutSum', config.robokassa.price_whitelabel),
    withState('MrchLogin', 'setMrchLogin', config.robokassa.mrh_login),
    withState('SignatureValue', 'setSignatureValue', ''),
    withState('isTest', 'setIsTest', config.robokassa.isTest),
    withState('InvoiceID', 'setInvoiceID', ''),

    withHandlers(() => {
        let form = null
        return {
            onRef: () => (ref) => (form = ref),
            changeSelectQuizIdHandler: props => (e) => {
                props.setSelectQuizId(e.target.value)
            },
            payHandler: props => () => {
                orderService.getOrder({
                    mrh_login: config.robokassa.mrh_login,
                    price: props.OutSum,
                    type: 'whitelabel',
                    isTest: props.isTest,
                    quizId: props.selectQuizId
                }).then(function (r) {
                    window.location.replace(r.payUrl);
                    props.setSignatureValue(r.crc)
                    props.setInvoiceID(r.order.id)
                }).then(function () {
                    form.submit()
                });
            }
        }
    }),
)(View);
