import React from 'react';
import {
    Grid,
    TextField,
    Tabs,
    Tab,
    Box,
    List,
    ListItemText,
    ListItem,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormHelperText
} from '@material-ui/core';
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {updateQuizData} from "../../../../_actions";
import {store} from '../../../../_services';
import {PhoneMask, PhoneMaskType, getDefaultPhoneMask} from '../../../../entities/Contacts/PhoneMask';
import {getCountries, getCountryCallingCode} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';

const exampleMasks = ['+7 (111) 111-11-11', '+38 (111) 111-11-11', '+375 (11) 111-11-11'];

const getCountryName = (countryKey: string) => {
    const keys = Object.keys(en);
    const values = Object.values(en);
    return (keys.indexOf(countryKey) !== -1) ? values[keys.indexOf(countryKey)] : null;
}

const View = (
    {phoneMask, changeRegexpHandler, changePhoneMaskType, changeCountryDefaultHandler, changePreferredCountriesHandler, changeOnlyCountriesHandler, changeIgnoredCountriesHandler}:
    {phoneMask: PhoneMask, changeRegexpHandler: (mask: string | null) => void, changePhoneMaskType: (type: PhoneMaskType) => void, changeCountryDefaultHandler: (country: string) => void, changePreferredCountriesHandler: (countries: string[]) => void, changeOnlyCountriesHandler: (countries: string[]) => void, changeIgnoredCountriesHandler: (countries: string[]) => void}

    ) => {
    return (
        <Grid>
            <Tabs color="primary" value={phoneMask.type}
                  onChange={(event: React.ChangeEvent<{}>, type: PhoneMaskType) => changePhoneMaskType(type)}>
                <Tab label="Выбор страны" value={'country'}/>
                <Tab label="Своя маска" value={'mask'}/>
            </Tabs>
            <br/>

            <Box hidden={phoneMask.type !== 'country'}>
                <Typography variant={'caption'}>Посетителю нужно будет выбрать страну и ввести номер телефона. Страна
                    определяется автоматически в зависимости от настроек браузера</Typography>

                <br/><br/>
                <FormControl fullWidth variant="filled">
                    <InputLabel shrink>
                        СТРАНА ПО-УМОЛЧАНИЮ
                    </InputLabel>
                    <Select
                        value={phoneMask.phoneMaskCountries.default.toUpperCase()}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => changeCountryDefaultHandler(e.target.value as string)}
                    >
                        {getCountries().map((countryKey: string) => (
                            <MenuItem key={countryKey}
                                      value={countryKey}>{getCountryName(countryKey)} +{getCountryCallingCode(countryKey)}</MenuItem>
                        ))}

                    </Select>
                </FormControl>
                <br/><br/>
                <FormControl fullWidth variant="filled">
                    <InputLabel shrink>
                        ВЫДЕЛЕННЫЕ СТРАНЫ
                    </InputLabel>
                    <Select
                        multiple
                        value={phoneMask.phoneMaskCountries.preferred.map((countryKey: string) => countryKey.toUpperCase())}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => changePreferredCountriesHandler(e.target.value as string[])}
                    >
                        {getCountries().map((countryKey: string) => (
                            <MenuItem key={countryKey}
                                      value={countryKey}>{`${getCountryName(countryKey)} +${getCountryCallingCode(countryKey)}`}</MenuItem>
                        ))}

                    </Select>
                    <FormHelperText>Выбранные страны будут показываться вверху списка</FormHelperText>
                </FormControl>
                <br/><br/>
                <FormControl fullWidth variant="filled">
                    <InputLabel shrink>
                        ПОКАЗЫВАЕМЫЕ СТРАНЫ
                    </InputLabel>
                    <Select
                        multiple
                        value={phoneMask.phoneMaskCountries.only.map((countryKey: string) => countryKey.toUpperCase())}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => changeOnlyCountriesHandler(e.target.value as string[])}
                    >
                        {getCountries().map((countryKey: string) => (
                            <MenuItem key={countryKey}
                                      value={countryKey}>{`${getCountryName(countryKey)} +${getCountryCallingCode(countryKey)}`}</MenuItem>
                        ))}

                    </Select>
                    <FormHelperText>Будут показываться только выбранные страны</FormHelperText>
                </FormControl>
                <br/><br/>
                <FormControl fullWidth variant="filled">
                    <InputLabel shrink>
                        ИГНОРИРУЕМЫЕ СТРАНЫ
                    </InputLabel>
                    <Select
                        multiple
                        value={phoneMask.phoneMaskCountries.ignored.map((countryKey: string) => countryKey.toUpperCase())}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => changeIgnoredCountriesHandler(e.target.value as string[])}
                    >
                        {getCountries().map((countryKey: string) => (
                            <MenuItem key={countryKey}
                                      value={countryKey}>{`${getCountryName(countryKey)} +${getCountryCallingCode(countryKey)}`}</MenuItem>
                        ))}

                    </Select>
                    <FormHelperText>Выбранные страны будут не будут показываться в списке</FormHelperText>
                </FormControl>
            </Box>
            <Box hidden={phoneMask.type !== 'mask'}>
                <TextField
                    variant="outlined"
                    placeholder={'+7 (111) 111-11-11'}
                    helperText={<Typography color={'textPrimary'} variant={'caption'}>Используйте <Typography
                        variant={'caption'} component="span" color={'primary'}>1</Typography> для подстановки
                        чисел</Typography>}
                    value={phoneMask.regexp}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => changeRegexpHandler(event.target.value)}
                />
                <br/><br/>
                <Typography variant={'h6'}>Готовые маски</Typography>
                <List>
                    {exampleMasks.map((mask: string) =>
                        <ListItem key={mask} button onClick={() => changeRegexpHandler(mask)}>
                            <ListItemText primary={mask}/>
                        </ListItem>
                    )}
                </List>
            </Box>
        </Grid>
    );
}

const mapStateToProps = (state: any) => {
    const phone = state.quizData.toJS().info.form.contacts.phone;
    const phoneMask = phone.mask ? phone.mask : getDefaultPhoneMask();
    return {
        phoneMask: phoneMask,
        quizData: state.quizData.toJS()
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        changePhoneMaskType: (type: PhoneMaskType) => {
            let quizData = store.getState().quizData.toJS()
            quizData.info.form.contacts.phone.mask.type = type;
            dispatch(updateQuizData(quizData));
        },
        changeRegexpHandler: (regexp: string | null) => {
            let quizData = store.getState().quizData.toJS()
            quizData.info.form.contacts.phone.mask.regexp = regexp;
            dispatch(updateQuizData(quizData));
        },
        changeCountryDefaultHandler: (country: string) => {
            let quizData = store.getState().quizData.toJS()
            quizData.info.form.contacts.phone.mask.phoneMaskCountries.default = country.toLowerCase();
            dispatch(updateQuizData(quizData));
        },
        changePreferredCountriesHandler: (countries: string[]) => {
            let quizData = store.getState().quizData.toJS()
            quizData.info.form.contacts.phone.mask.phoneMaskCountries.preferred = countries.map((country: string) => country.toLowerCase());
            dispatch(updateQuizData(quizData));
        },
        changeIgnoredCountriesHandler: (countries: string[]) => {
            let quizData = store.getState().quizData.toJS()
            quizData.info.form.contacts.phone.mask.phoneMaskCountries.ignored = countries.map((country: string) => country.toLowerCase());
            dispatch(updateQuizData(quizData));
        },
        changeOnlyCountriesHandler: (countries: string[]) => {
            let quizData = store.getState().quizData.toJS()
            quizData.info.form.contacts.phone.mask.phoneMaskCountries.only = countries.map((country: string) => country.toLowerCase());
            dispatch(updateQuizData(quizData));
        }
    }
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(View);


export default Container
