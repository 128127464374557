import {questionService, answerService} from '../_api';
import {
    setQuizData,
    cpCompleteLoading,
    cpAddError,
    cpStartLoading,
    cpAddMessage
} from './';
import { validateQuestion } from '../_services';
import arrayMove from "array-move";
import {needRemoveConditions} from '../_services/branchQuestionService';
import {getQuestionById, getQuestionIndexById} from '../_services/questionService';
import {setQuizStateIem} from '../_reducers/quizState.reducer'

export const addQuestion = (questionData) => (dispatch, getState) => {
    questionData = validateQuestion(questionData)
    const quizData = getState().quizData.toJS()
    questionService.add(quizData.id, questionData).then(
        newQuestion => {
            quizData.questions.push(newQuestion)
            dispatch(setQuizData(quizData))
            dispatch(setQuizStateIem('activeKeyItem', quizData.questions.length - 1))
        },
        error => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    );
}

export const updateQuestion = (questionId, questionData) => (dispatch, getState) => {
    questionData = validateQuestion(questionData)
    const quizData = getState().quizData.toJS()
    let indexQuestion = null
    quizData.questions.forEach((val, index) => {
        if(val.id === questionId) indexQuestion = index
    })
    questionService.update(quizData.id, questionId, questionData).then(
        updateQuestion => {
            quizData.questions[indexQuestion] = updateQuestion
            dispatch(setQuizData(quizData))
        },
        error => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    );
}

export const sortQuestion = (newIndex, oldIndex) => (dispatch, getState) => {
    dispatch(cpStartLoading());
    const quizData = getState().quizData.toJS();
    const quizState = getState().quizState;
    const activeKeyItem = arrayMove(Object.keys(quizData.questions), oldIndex, newIndex).indexOf(quizState.activeKeyItem.toString());

    quizData.questions = arrayMove(quizData.questions, oldIndex, newIndex);

    let questionIdsBySort = []
    quizData.questions.forEach(question => {
        questionIdsBySort.push(question.id)
    });

    questionService.sort(quizData.id, questionIdsBySort).then(
        () => {
            dispatch(setQuizData(quizData));
            dispatch(setQuizStateIem('activeKeyItem', activeKeyItem));
            dispatch(cpCompleteLoading());
            return quizData;
        },
        error => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    ).then(quizData => {
        needRemoveConditions(quizData).forEach((questionConditions, questionId) => {
            let question = getQuestionById(questionId);
            question.displayConditions = questionConditions;
            quizData.questions[getQuestionIndexById(questionId)] = question;
            dispatch(updateQuestion(questionId, question));
            dispatch(cpAddMessage('Все условия показа, связанные с удаляемым вопросом были удалены'));
        });
    });
}


export const deleteQuestion = (questionId) => (dispatch, getState) => {
    dispatch(cpStartLoading());
    let quizData = getState().quizData.toJS()
    const quizState = getState().quizState
    let indexQuestion = null
    quizData.questions.forEach((val, index) => {
        if(val.id === questionId) indexQuestion = index
    })

    questionService.remove(quizData.id, questionId).then(
        () => {
            quizData.questions.splice(indexQuestion, 1);
            dispatch(setQuizStateIem('activeKeyItem', (quizState.activeKeyItem > 0) ? quizState.activeKeyItem - 1 : 0))
            dispatch(setQuizData(quizData));
            dispatch(cpCompleteLoading());
            return quizData;
        },
        error => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    ).then(quizData => {
            needRemoveConditions(quizData).forEach((questionConditions, questionId) => {
                let question = getQuestionById(questionId);
                question.displayConditions = questionConditions;
                quizData.questions[getQuestionIndexById(questionId)] = question;
                dispatch(updateQuestion(questionId, question));
                dispatch(cpAddMessage('Все условия показа, связанные с удаляемым вопросом были удалены'));
            });
    });
}

export const copyQuestion = (questionId) => (dispatch, getState) => {
    dispatch(cpStartLoading());
    const quizData = getState().quizData.toJS();
    let indexQuestion = null;
    quizData.questions.forEach((val, index) => {
        if(val.id === questionId) indexQuestion = index
    });
    const copyQuestionData =  quizData.questions[indexQuestion];
    copyQuestionData.displayConditions = [];

    questionService.add(quizData.id, copyQuestionData).then(
        newQuestion => {
            // вставляем вопрос следующим после копируемого
            quizData.questions.splice(indexQuestion + 1, 0, newQuestion);

            dispatch(setQuizData(quizData));
            dispatch(setQuizStateIem('activeKeyItem', indexQuestion + 1));

            // сортируем вопросы в соответствии, как мы вставили новый вопрос, тк новый вопрос добавляется  в конец
            let questionIdsBySort = []
            quizData.questions.forEach(question => {
                questionIdsBySort.push(question.id)
            });
            questionService.sort(quizData.id, questionIdsBySort);

            // добавляем все ответы из копируемого вопроса
            copyQuestionData.answers.forEach((answer) => {
                answerService.add(quizData.id, newQuestion.id, answer).then(
                    newAnswer => {
                        quizData.questions[indexQuestion + 1].answers.push(newAnswer);
                        dispatch(setQuizData(quizData));
                    },
                    error => {
                        dispatch(cpAddError(error));
                    }
                );
            })
            dispatch(cpCompleteLoading());
        },
        error => {
            dispatch(cpAddError(error));
            dispatch(cpCompleteLoading());
        }
    );


};
