export const tooltips = {
    discountIncreasing: 'Пользователь получает фиксированную скидку в рублях за каждый ответ на вопрос. Укажите максимальный размер скидки за каждый вопрос рассчитается автоматически в зависимости от количества вопросолв',
    discountIncreasingPercent: 'Пользователь получает скидку в процентах за каждый ответ на вопрос. Укажите максимальный размер скидки за каждый вопрос рассчитается автоматически в зависимости от количества вопросолв',
    discountMelting: 'Пользователь получает скидку в зависимости от того как быстро он проходит квиз. Укажите максимальную скидку и мы автоматически будем снимать x рублей каждую секудну',
    startTitle: 'Заголовок отображается на самом виджете на стартовой странице',
    startSubTitle: 'Подзаголовок отображается под заголовком виджета,  для усиления описания',
    startLegal: 'Будет отображаться только на стартовой странице мелким шрифтом',
    startRender: 'Тематическое фото вашей услуги или товара, отображается на половину стартовой страницы ',
    startName: 'Наименование вашей компании. Можно со слоганом',
    contactRedirect: 'Переадресация после заполнения формы',
    sliderMultiple: 'два ползунка',
    startTitlePage: 'Показывать title страницы, когда квиз используется как отдельная страница',
    startFavicon: 'Показывать favicon, когда квиз используется как отдельная страница'
}