import React from "react";
import {
  withStyles,
  Badge as BadgeBase,
  Typography as TypographyBase
} from "@material-ui/core";
import { withTheme } from '@material-ui/styles';
import classnames from "classnames";



const getFontWeight = style => {
  switch (style) {
    case "light":
      return 300;
    case "medium":
      return 500;
    case "bold":
      return 600;
    default:
      return 400;
  }
};


const createStyled = (styles, options) => {
  const Styled = props => {
    const { children, ...other } = props;
    return children(other);
  };

  return withStyles(styles, options)(Styled);
};

const BadgeExtended = ({ classes, theme, children, colorBrightness, ...props }) => {
  const Styled = createStyled({
    badge: {

    }
  });

  return (
    <Styled>
      {styledProps => (
        <BadgeBase
          classes={{
            badge: classnames(classes.badge, styledProps.classes.badge)
          }}
          {...props}
        >
          {children}
        </BadgeBase>
      )}
    </Styled>
  );
};

export const Badge = withStyles(
  theme => ({
    badge: {
      fontWeight: 600,
      height: 16,
      minWidth: 16
    }
  }),
  { withTheme: true }
)(BadgeExtended);

const TypographyExtended = ({ theme, children, weight, size, colorBrightness, ...props }) => (
  <TypographyBase
    style={{
      fontWeight: getFontWeight(weight)
    }}
    {...props}
  >
    {children}
  </TypographyBase>
);

export const Typography = withTheme(TypographyExtended);

