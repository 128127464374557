import {compose, withHandlers} from 'recompose';
import {connect} from 'react-redux';
import View from './View';
import {branchQuestionService} from "../../../_services/branchQuestionService";
import {updateQuestion} from '../../../_actions';

export default compose(
    connect(
        (state, props) => {
            const quizData = state.quizData.toJS();
            const question = quizData.questions[props.questionIndex];
            return {
                quizData: quizData,
                title: question.title,
                displayConditions: question.displayConditions ? question.displayConditions : [],
                questionsByCondition: branchQuestionService.getPrevQusetionByQuestionId(props.questionIndex),
                typeIfFewConditions: question.typeIfFewConditions ? question.typeIfFewConditions : 'and',
                question: question
            }
        },
        {updateQuestion}
    ),
    withHandlers({
        newConditionHandler: props => (conditionData) => {
            if(!props.question.displayConditions) props.question.displayConditions = []
            props.question.displayConditions.push(conditionData)
            props.updateQuestion(props.question.id, props.question);
        },
        updateConditionHandler: props => (conditionIndex, conditionData) => {
            if(!props.question.displayConditions) props.question.displayConditions = []
            props.question.displayConditions[conditionIndex] = conditionData
            props.updateQuestion(props.question.id, props.question);
        },
        deleteConditionHandler: props => (conditionIndexForDelete) => {
            props.question.displayConditions = props.question.displayConditions.filter((displayCondition, conditionIndex) => conditionIndex !== conditionIndexForDelete);
            props.updateQuestion(props.question.id, props.question);
        },
        handleChangeTypeIfFewConditions: props => (e) => {
            props.question.typeIfFewConditions = e.target.value
            props.updateQuestion(props.question.id, props.question);
        }
    })
)(View);


