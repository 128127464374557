import {compose, withState, withHandlers, lifecycle} from 'recompose';
import { connect } from 'react-redux';
import View from './View';
import {createQuiz} from "../../../../_actions";
import {quizzesService} from "../../../../_api";

export default compose(
    connect(
        state => ({}),
        {
            createQuiz
        }
    ),
    withState('isLoading', 'setIsLoading', false),
    withState('templates', 'setTemplates', null),
    withState('categoryTemplates', 'setCategoryTemplates', 'Все категории'),
    withState('previewQuizId', 'setPreviewQuizId', false),
    withState('createdQuizId', 'setCreatedQuizId', false),
    withState('nameCreatedQuiz', 'setNameCreatedQuiz', ''),
    withHandlers({
        createQuizHandler: props => () => {
            props.createQuiz(props.createdQuizId, props.nameCreatedQuiz)
        },
        PreviewQuizHandler: props => (quizId) => {
           props.setPreviewQuizId(quizId)
        },   
        ChangeCategoryTemplatesHandler: props => (e) => {
           props.setCategoryTemplates(e.target.value)
        },  
    }),
    lifecycle({
        componentDidMount() {

            if(this.props.templates === null && !this.props.isLoading){
                this.props.setIsLoading(true)
                quizzesService.getQuizzesCategoties().then((r) => {

                    const data = []
                    const quizzesAll = []

                    r.data.forEach((category) => {
                        const quizzes = []    
                        category.quizzes.forEach((quiz) => {
                            quizzes.push(quiz)
                            quizzesAll.push(quiz)
                        })
                        data[category.name] = quizzes
                    })
                    data['Все категории'] = quizzesAll
                        this.props.setTemplates(data)
                        this.props.setIsLoading(false)
                }); 
            }
            
         
        }
    })
)(View);

