import React from "react";
import {withStyles, InputLabel, FormControl, Select, MenuItem} from "@material-ui/core";
import Answer from './Answer';

const View = ({
                  selectedQuestion,
                  changeQuestionIdHandler,
                  changeAnswerHandler,
                  condition,
                  questionsByCondition,
                  conditionIndex,
                  classes
              }) => (

    <FormControl className={classes.form} fullWidth key={conditionIndex}>
        <InputLabel>Показать, если в ответ на вопрос</InputLabel>
        <Select onChange={changeQuestionIdHandler} fullWidth value={selectedQuestion ? selectedQuestion.id : null}>
            {questionsByCondition.map((question, key) =>
                <MenuItem key={key} value={question.id}>#{key} {question.title}</MenuItem>
            )}
        </Select>
        {selectedQuestion && <Answer question={selectedQuestion} handler={changeAnswerHandler} condition={condition}/>}
    </FormControl>

);

const styles = theme => ({
    form:{
        marginBottom: 20
    }
});

export default withStyles(styles)(View);