import {compose, withHandlers, withState} from 'recompose';
import {TextField} from "@material-ui/core";
import React from "react";

const View = ({onChange, maxlength, onBlur, value, disabled, placeholder, onRef, variant, fullWidth = true, multiline, required, rowsMax, rows, label, type, min, max, className = null, endAdornment = null, startAdornment = null, inputComponent = 'input', helperText, error}) => (

    <TextField
        className={className}
        InputProps={{inputComponent: inputComponent, min: min, max: max, maxLength: maxlength, endAdornment: endAdornment, startAdornment: startAdornment}}
        variant={variant}
        fullWidth={fullWidth}
        multiline={multiline}
        rowsMax={rowsMax}
        rows={rows}
        inputRef={onRef}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        label={label}
        type={type}
        required={required}
        autoFocus={true}
        disabled={disabled}
        helperText={helperText}
        error={error}
    />
);

export default  compose(
    withState('value', 'setValue', (props) => props.value),
    withHandlers((props) => {
        let input;
        return {
            onRef:  () => props.inputRef ? props.inputRef : (ref) => (input = ref),
            onChange: props => (e) => {
                props.setValue(e.target.value)
                if(props.callBackOnChange){
                    props.callBackOnChange(e.target.value);
                }
            },
            onBlur: props => () => {
                let value = props.value;
                if(props.callBack){
                    value = props.callBack(value);
                    props.setValue(value);
                }
                if(props.blurHandler){
                    props.blurHandler();
                }
                if(props.min && (!props.min || props.min > props.value)){
                    value = props.min;
                    props.setValue(value);
                }
                props.onChangeHandler(value)
            },
        }
    })
)(View);


