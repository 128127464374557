import React from 'react';
import {
    withStyles,
    CssBaseline,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button
} from '@material-ui/core';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import quizList from '../../pages/quizList';
import { QuizzAnswersPage } from '../../pages/QuizzAnswersPage';
import balance from '../../pages/balance';
import QuizEdit  from '../../pages/EditQuiz';

import support from '../../pages/support';
import ModalSetAdditionalInfoUser from '../ModalSetAdditionalInfoUser';


const Layout = ({ classes, toggleSidebar, errorMessage, resetErrorHandler, message, resetMessageHandler}) => (
  <div className={classes.root}>
    <CssBaseline />
    <BrowserRouter>
      <React.Fragment>
        <Header />
        <div className={classes.content}>
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route  path="/app/instructions" component={support} />
            <Route  path="/app/quizzes/edit/:quizId" component={QuizEdit} />
            <Route  path="/app/quizzes/answers/:quizzId" component={QuizzAnswersPage} />
            <Route  path="/app/balance" component={balance} />
              <Route  path="/app/success_pay" component={balance} />
              <Route  path="/app/fail_pay" component={balance} />


            <Route exact path="/app/success_pay" component={balance} />
            <Route exact path="/app/fail_pay" component={balance} />
            <Route exact path="/app/quizzes" component={quizList} />
            <Route  path="/app" render={() => <Redirect to="/app/quizzes" />} />
          </Switch>
        </div>
        <Footer />
      </React.Fragment>
    </BrowserRouter>

    <Dialog
        open={errorMessage ? true : false}
        onClose={resetErrorHandler}
    >
      <DialogTitle>Ошибка</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {errorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetErrorHandler} color="secondary" autoFocus>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog
        open={message ? true : false}
        onClose={resetMessageHandler}
    >
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetMessageHandler} color="secondary" autoFocus>
          Ок
        </Button>
      </DialogActions>
    </Dialog>

    <ModalSetAdditionalInfoUser/>



  </div>
    
    
);

const styles = theme => ({
  root: {
    maxWidth: '100vw',
    overflowX: 'hidden',
  },
  content: {
    width: '100%',
    minHeight: `calc(100vh - 50px)`,
    paddingBottom: theme.spacing(4)
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  }
});

export default withStyles(styles)(Layout);
