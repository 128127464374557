import React from "react";
import {
   Button,
   TextField,
   InputProps,
   FormLabel,
   withStyles
} from "@material-ui/core";

import {
   FileCopy
} from "@material-ui/icons";

const View = ({
    copyToClipboard,
   quizData,
    classes
}) => { 
const link = `#popup:Leadforms_${quizData.id}`
const event = `onclick="Leadforms.showModal('${quizData.id}')`

    return (
        <React.Fragment>
            <FormLabel>Укажите у вашей кнопки ссылку</FormLabel>
            <TextField
                    fullWidth
                    value={link}
                    variant="filled"
                    margin="dense"
                    InputProps={{
                        readOnly: true,
                        endAdornment: (<Button  onClick={copyToClipboard.bind(this, link)}><FileCopy/> скопировать</Button>)
                    }}
                />
            <br/><br/>      
            <FormLabel>или событие</FormLabel>
            <TextField
                    fullWidth
                    value={event}
                    variant="filled"
                    margin="dense"
                    InputProps={{
                        readOnly: true,
                        endAdornment: (<Button onClick={copyToClipboard.bind(this, link)}><FileCopy/> скопировать</Button>)
                    }}
                />
        </React.Fragment>
)}

const styles = theme => ({

});

export default  withStyles(styles)(View);


