import React from "react";
import {
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Input,
    Button,
    Chip, Select, MenuItem, ListItem, ListItemSecondaryAction, List

} from "@material-ui/core";

import {config} from "../../../../_services";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
const View = ({
    countLeads,
    payHandler,
    MrchLogin,
    SignatureValue,
    InvoiceID,
    OutSum,
    isTest,
    onRef,
                  changeSelectQuizIdHandler,
                  selectQuizId,
                  quizzes,
                  whiteLabels

}) =>
    quizzes.length === 0 ? null :
        <Card>
            <CardContent>
                <Typography>
                    White label
                </Typography>

                {Object.keys(whiteLabels).map((key) =>
                    {
                        const program = whiteLabels[key]
                        return <Chip label={`квиз #${program.quizId} до ${program.dateFinish}`}/>
                    }
                )}

                <br/>
                <Typography variant="caption">
                    Для выбранных квизов будет отключена надпись "Сделано в LeadForms"
                </Typography>
                <br/>

                <FormControl fullWidth>
                    <Select onChange={changeSelectQuizIdHandler} fullWidth value={selectQuizId}>
                        <MenuItem value={0}>
                            <em>выберите квиз</em>
                        </MenuItem>
                        {Object.keys(quizzes).map((key) =>
                            <MenuItem key={key} value={key}>#{key} {quizzes[key].info.title}</MenuItem>
                        )}

                    </Select>
                </FormControl>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                >
                    <Grid item>
                        {selectQuizId != 0 && <Chip label={`${OutSum}₽, white label для квиза #${selectQuizId} ${quizzes[selectQuizId].info.title}`}/>}
                    </Grid>
                    <Grid item>
                        <Button disabled={!selectQuizId} onClick={payHandler} variant="contained">
                            ОПЛАТИТЬ
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
            <form action="https://merchant.roboxchange.com/Index.aspx" hidden method="post"
                  ref={onRef}>
                <input name="MrchLogin" type="hidden" value={MrchLogin}/>
                <input name="SignatureValue" type="hidden" value={SignatureValue}/>
                <input name="InvoiceID" type="hidden" value={InvoiceID}/>
                <input name="OutSum" type="hidden" value={OutSum}/>
                {isTest &&
                <input name="IsTest" type="hidden" value="1"/>
                }
            </form>
        </Card>

;



export default View;