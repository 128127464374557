import {compose, withState, withHandlers, lifecycle} from "recompose";
import { getByName } from '../../../_services/getParametrs';
import {connect} from "react-redux";

import View from "./View";
import {cpResetError, createUser} from "../../../_actions";

export default compose(
    connect(
        state => ({
            errorMessage: state.cpState.toJS().error,
            appLoading: state.cpState.toJS().loading
        }),
        {cpResetError, createUser}
    ),
    withState("loginValue", "setLoginValue", getByName('email') ? getByName('email') : ''),
    withState("passwordValue", "setPasswordValue", ""),
    withHandlers({
        handleInput: props => (e, input = "login") => {
            if (props.errorMessage) {
                props.cpResetError();
            }
            if (input === "login") {
                props.setLoginValue(e.target.value);
            } else if (input === "password") {
                props.setPasswordValue(e.target.value);
            }
        },
     
        handleRegisterButtonClick: props => () => {
            props.createUser(props.loginValue, props.passwordValue);
        }
    }),
    lifecycle({
        componentWillReceiveProps(nextProps) {
            if (!this.props.errorMessage && nextProps.errorMessage) {
                this.props.setPasswordValue("");
            }
        }
    })
)(View);
