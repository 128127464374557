import { combineReducers } from 'redux';
import { userInfo } from './userInfo.reducer';
import { quizzes } from './quizzes.reducer';
import { quizzesAnswers } from './quizzesAnswers.reducer';
import { quizStateReducer as  quizState} from './quizState.reducer';
import { quizData } from './quizData.reducer';
import { cpState } from './cpState.reducer';
import { websocket } from './websocket.reducer';

export const cpReducer = combineReducers({
    userInfo,
    quizzes,
    quizzesAnswers,
    quizState,
    quizData,
    cpState,
    websocket
});
