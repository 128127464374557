import React from "react";
import {
    withStyles,
    Dialog,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Grid
} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";

const View = ({classes, handleClose, children, modalTitle, fullScreen = true}) => (
    <Dialog fullScreen={fullScreen} open={true} onClose={handleClose} >
        <AppBar color="default" className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
                    <ArrowBack />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {modalTitle}
                </Typography>
            </Toolbar>
        </AppBar>
        <Grid justify="center" container>
            <Grid container  className={classes.container} item lg={8} md={8} sm={10} xs={12}>
                {children}
            </Grid>
        </Grid>
    </Dialog>
)

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        marginTop: theme.spacing(2)
    }
});
export default withStyles(styles)(View);