import React from "react";
import {connect} from "react-redux";
import {quizzesActions, getQuizList, downloadLeadsReport, cpSetIsAnswersQuizRoute, quizStateActions} from "../../../_actions";
import ModalAnswerFullInfo from "./ModalAnswerFullInfo"
import ModalDeleteAnswer from "./ModalDeleteAnswer"
import {
    Grid,
    Table,
    TablePagination,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Toolbar,
    Typography,
    Button,
    Chip,
    Link,
} from "@material-ui/core";
import {Link as LinkRouter} from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import {CloudDownload} from "@material-ui/icons";


class QuizzAnswersPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {answerFullInfo: null, deleteAnswer: null}
    }
    componentWillUnmount() {
        this.props.dispatch(cpSetIsAnswersQuizRoute(false))
    }
    componentDidMount() {
        this.props.dispatch(cpSetIsAnswersQuizRoute(true))
        if (this.props.quizState.loading || this.props.match.params.quizzId != this.props.quizData.id) {
            this.props.dispatch(quizStateActions.init(this.props.match.params.quizzId))
        }
        const {quizAnswers, quizzes} = this.props;
        if(!quizzes.list[this.props.match.params.quizzId])
            this.props.dispatch(getQuizList());
        if(!quizAnswers)
            this.props.dispatch(quizzesActions.getAnswers(this.props.match.params.quizzId));
    }

    handleChangePage(event, newPage){
        this.props.dispatch(quizzesActions.getAnswers(this.props.match.params.quizzId, newPage));
    }
    openAnswerFullInfo(answerId) {
        let newState = this.state
        newState.answerFullInfo = answerId
        this.setState(newState)
    }

    deleteAnswer(answerId) {
        let page = this.props.quizAnswers.page;
        if( this.props.quizAnswers.items.length === 1 && page !== 0) page = page - 1;

        this.props.dispatch(quizzesActions.deleteAnswer(this.props.match.params.quizzId, answerId, page));
        let newState = this.state
        newState.deleteAnswer = null
        this.setState(newState)
    }

    downloadLeadsReport(quizId) {
        this.props.dispatch(downloadLeadsReport(quizId));
    }

    closeAnswerFullInfo() {
        let newState = this.state
        newState.answerFullInfo = null
        this.setState(newState)
    }

    closeModalDeleteAnswer() {
        let newState = this.state
        newState.deleteAnswer = null
        this.setState(newState)
    }

    openModalDeleteAnswer(answerId) {
        let newState = this.state
        newState.deleteAnswer = answerId
        this.setState(newState)
    }


    renderAnswer(answer) {
        return (
            <TableRow key={answer.id}>
                <TableCell component="th" scope="row">
                    <b>{answer.id}</b>&nbsp;
                    {answer.isTest ? <Chip color='primary' size="small" label="тест"/> : null}{(!answer.payment) ? <Chip color='secondary' size="small" label="не оплачена"/> : null}
                </TableCell>
                <TableCell align="right">{(answer.created) ? answer.created.toString() : null}</TableCell>
                <TableCell align="right">{(answer.payment) ? answer.contacts.email : '--------'}</TableCell>
                <TableCell align="right">{(answer.payment) ? answer.contacts.phone : '--------'}</TableCell>
                <TableCell align="right">
                    {(answer.payment) ? <Button color="primary"  size="small" onClick={this.openAnswerFullInfo.bind(this, answer.id)}>Подробнее</Button> : '--------'}
                </TableCell>
                <TableCell align="right"><Button color="secondary"  size="small" onClick={this.openModalDeleteAnswer.bind(this, answer.id)}>Удалить</Button></TableCell>
            </TableRow>
        )
    }

    render() {
        const {quizAnswers, quizzes} = this.props;
        if ( !quizAnswers ||  !quizzes.isLoading) {
            return (null)
        }
        const quiz = quizzes.list[this.props.match.params.quizzId];
        return (
            <Grid justify="center" container>
                <Grid container item lg={8} md={8} sm={10} xs={12}>
                    <Grid item xs={12}>
                        <br/>
                    </Grid>
                    <Paper>
                        <Toolbar>
                            <Typography variant="h6" style={{width: '100%'}}>Ответы на квиз</Typography>
                            <Button  title="Скачать файл" onClick={this.downloadLeadsReport.bind(this, quiz.id)}>
                                <CloudDownload/>
                            </Button>
                        </Toolbar>
                    <Table>
                        <TableBody>
                            {Object.values(quizAnswers.items).sort(function (vote1, vote2) {
                                    if (vote1.id > vote2.id) return -1;
                                    if (vote1.id < vote2.id) return 1;
                                }).map((answer) =>
                                    this.renderAnswer(answer)
                                )
                            }
                        </TableBody>
                    </Table>
                        <TablePagination
                            rowsPerPageOptions={[quizAnswers.limit]}
                            rowsPerPage={quizAnswers.limit}
                            count={quizAnswers.count}
                            page={quizAnswers.activePage}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onChangePage={this.handleChangePage.bind(this)}
                        />
                    </Paper>
                </Grid>
                {this.state.answerFullInfo &&
                <ModalAnswerFullInfo
                    answer={this.props.quizAnswers.items[this.state.answerFullInfo]}
                    closeAnswerFullInfo={this.closeAnswerFullInfo.bind(this)}
                />
                }
                {this.state.deleteAnswer &&
                <ModalDeleteAnswer
                    answer={this.props.quizAnswers.items[this.state.deleteAnswer]}
                    deleteAnswer={this.deleteAnswer.bind(this, this.state.deleteAnswer)}
                    closeModalDeleteAnswer={this.closeModalDeleteAnswer.bind(this)}
                />
                }
            </Grid>
        )
    }
}

function mapStateToProps(state, props) {
    const quizzesAnswers = state.quizzesAnswers.toJS();
    const quizData = state.quizData.toJS();
    const quizState = state.quizState;
    const quizzes = state.quizzes.toJS();
    const quizAnswers = quizzesAnswers[props.match.params.quizzId] ? quizzesAnswers[props.match.params.quizzId] : null;
    return {
        quizAnswers,
        quizzes,
        quizState,
        quizData
    };
}

const connectedQuizzAnswersPage = connect(mapStateToProps)(QuizzAnswersPage);
export {connectedQuizzAnswersPage as QuizzAnswersPage};
