import React from "react";
import {
    Grid,
    Typography,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button,
    withStyles
} from "@material-ui/core";
import PageTitle from "../../components/PageTitle";
import QuizCard from "./QuizCard";
import Steps from "../../components/Steps";
import CreateQuizModal from './CreateQuizModal'

const View = ({quizList, openModalDeleteQuiz, handleOpenModalDeleteQuiz, handleSubmitDeleteQuiz, classes, openCreateQuizModal, handleChangeOpenCreateQuizModal}) => (
    <React.Fragment>

        <Grid justify="center" container>
            <Grid container item lg={8} md={10} sm={12} xs={12}>
                <Grid item xs={12}>
                    <PageTitle title="Мои квизы"/>
                </Grid>
                {Object.keys(quizList).map((key) =>
                    <QuizCard handleOpenModalDeleteQuiz={handleOpenModalDeleteQuiz} quiz={quizList[key]} key={key}/>
                )}

                {!Object.keys(quizList).length &&
                <div className="row">
                    <div className="col-12">
                        <Typography variant="h6">Добро пожаловать в LeadForms!</Typography>
                        <Typography>
                            LeadForms - это эффективный инструмент для привлечения лидов с сайта.Четыре простых шага,
                            чтобы сделать опрос, который захватывает, сегментирует и конвертирует ваших пользователей.
                        </Typography>
                        <Steps />
                        <br/><br/>
                    </div>
                </div>
                }
                <Grid item lg={6} md={6} sm={6} xs={12} className={classes.addQuizContainer} style={!Object.keys(quizList).length ? {margin: 'auto'} : null}>
                    <Card  onClick={handleChangeOpenCreateQuizModal}  className={classes.addQuizCard}>
                        <CardContent>
                            <Typography className={classes.addQuizTitle} color="textSecondary">
                                + Добавить новый квиз
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
        <CreateQuizModal  open={openCreateQuizModal}  closeHandler={handleChangeOpenCreateQuizModal}/>
        <Dialog
            open={openModalDeleteQuiz}
            onClose={handleOpenModalDeleteQuiz.bind(this, false)}
        >
            <DialogTitle>Удалить квиз</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Вы действительно хотите удалить квиз?<br/>
                    Восстановление будет невозможно
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOpenModalDeleteQuiz.bind(this, false)} color="primary">
                    Отмена
                </Button>
                <Button onClick={handleSubmitDeleteQuiz} color="secondary" autoFocus>
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>

    </React.Fragment>
);

const styles = theme => ({
    addQuizCard: {
        textAlign: 'center',
        padding: 40,
        background: theme.palette.background.light,
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        borderStyle: 'dotted'
    },
    addQuizTitle: {
        fontSize: 19,
        cursor: 'pointer'
    },
    addQuizContainer: {
        padding: theme.spacing(2)
    }
});

export default withStyles(styles)(View);