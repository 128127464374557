import {compose, withState, withHandlers} from "recompose";
import {config} from "../../../../_services";
import {orderService} from "../../../../_api";
import View from "./View";

const calculatePriceByCountLead = (countLead) => countLead * ((countLead >= 100) ? config.robokassa.price_base_2 : config.robokassa.price_base_1)

export default compose(
    withState('countLeads', 'setCountLeads', 10),
    withState('OutSum', 'setOutSum', calculatePriceByCountLead(10)),
    withState('MrchLogin', 'setMrchLogin', config.robokassa.mrh_login),
    withState('SignatureValue', 'setSignatureValue', ''),
    withState('isTest', 'setIsTest', config.robokassa.isTest),
    withState('InvoiceID', 'setInvoiceID', ''),

    withHandlers(() => {
        let form = null
        return {
            onRef: () => (ref) => (form = ref),
               
            changeCountLeadsHandler: props => (e) => {
                props.setCountLeads(e.target.value);
                props.setOutSum(calculatePriceByCountLead(e.target.value));
            },
            payHandler: props => () => {
                orderService.getOrder({
                    mrh_login: config.robokassa.mrh_login,
                    price: props.OutSum,
                    countLeads: props.countLeads,
                    type: 'base',
                    isTest: props.isTest
                }).then(function (r) {
                    window.location.replace(r.payUrl);
                    props.setSignatureValue(r.crc)
                    props.setInvoiceID(r.order.id)
                }).then(function () {
                    form.submit()
                });
            }
        }
    }),
)(View);
