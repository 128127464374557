import 'whatwg-fetch';
import {handleResponse} from './handleResponse';
import { authHeader, config } from '../_services';

export const questionService = {
    add,
    update,
    remove,
    sort
};


function add(quizId, data) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}/quizzes/${quizId}/questions`, requestOptions).then(handleResponse)
}

function update(quizId, questionId, data) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}/quizzes/${quizId}/questions/${questionId}`, requestOptions).then(handleResponse)
}

function remove(quizId, questionId) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'DELETE',
        headers: headers
    };

    return fetch(`${config.apiUrl}/quizzes/${quizId}/questions/${questionId}`, requestOptions).then(handleResponse)
}

function sort(quizId, questionIdsBySort) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({question_ids: questionIdsBySort})
    };
    return fetch(`${config.apiUrl}/quizzes/${quizId}/questions/sort`, requestOptions).then(handleResponse)
}



