import {store} from "./index";
import {getQuestionIndexById, getQuestionById} from "./questionService";


export const branchQuestionService = {
    getPrevQusetionByQuestionId: (questionIndex) => {
    	const questions = store.getState().quizData.toJS().questions;

        return questions.splice(0, questionIndex).filter((question) => {
            if((question.type === "answers" || question.type === "images") && question.answers.length === 0)
                return false;
            return true;
        });
    }
};

export const getConditionsRelatedToQuestion = (questionId) => {
    let conditions = [];
    store.getState().quizData.toJS().questions.forEach((question) => {
        if(question.displayConditions) question.displayConditions.forEach((condition, keyCondition) => {
            if(condition.questionId === questionId){
                if(!conditions[question.id]) conditions[question.id] = []
                conditions[question.id][keyCondition] = condition;
            }
        })
    });
    return conditions;
};

export const needRemoveConditions = (quizData) => {
    let conditions = [];
    quizData.questions.forEach((question, questionIndex) => {
        if(question.displayConditions) question.displayConditions.forEach((condition, keyCondition1) => {
            let issetAnswer = false;
            getQuestionById(condition.questionId).answers.forEach((answer) => {
                if(answer.id === condition.condition.value){
                    issetAnswer = true;
                }
            });

            if(
                (getQuestionIndexById(condition.questionId) === null) ||
                (getQuestionIndexById(condition.questionId) >= questionIndex) ||
                (condition.condition.operation === "eq" && !issetAnswer)
            ){


                conditions[question.id] = question.displayConditions.filter((condition, keyCondition2) => {
                    return keyCondition1 !== keyCondition2;
                });
            }
        })
    });
    return conditions;
}

export const checkConditionIsDone = (question, clientAnswers) => {

    const check = (displayCondition, clientAnswers ) => {
        if(displayCondition.condition.operation === 'eq'){
            if(clientAnswers[displayCondition.questionId] && clientAnswers[displayCondition.questionId][displayCondition.condition.value])
                return true;
        }else if(displayCondition.condition.operation === 'like'){
            if(clientAnswers[displayCondition.questionId] && clientAnswers[displayCondition.questionId]['custom'].indexOf(displayCondition.condition.value) !== -1 )
                return true;
        }
        return false;
    }

    if(!question.displayConditions || question.displayConditions.length === 0) return true;


    if(!question.typeIfFewConditions || question.typeIfFewConditions === 'and'){
        let r = true;
        question.displayConditions.forEach((displayCondition) => {
           if(check(displayCondition, clientAnswers) === false) {
               r = false;
           }
        });
        return r;
    }else if(question.typeIfFewConditions === 'or'){
        let r = false;
        question.displayConditions.forEach((displayCondition) => {
            if(check(displayCondition, clientAnswers)) {
                r = true;
            }
        });
        return r;
    }
    return false;
}
