import React from "react";
import {Edit} from '@material-ui/icons';
import {Tooltip,  withStyles} from "@material-ui/core";

const View = ({classes}) => (
    <Tooltip title="Редактировать">
        <Edit className={classes.icon} />
    </Tooltip>


);

const styles = theme => ({
    icon: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontSize: 15,
        marginLeft: 5,
     //    position: 'absolute',
     //    height: '100%',
    	// bottom: 0,
     //    right: -20
    }
});
export default withStyles(styles)(View);