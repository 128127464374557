
import {Map} from 'immutable'
import {createReducer} from 'redux-act'

import {
    setQuizData,
    signOutSuccess
} from '../_actions'
import {validateQuestion, validations} from "../_services";


const entityToMap = (data) => {
    return new Map(data)
}

const defaultState = entityToMap({isEmpty: true});

export const quizData = createReducer((on) => {
    on(setQuizData, (state, data) => {
        data = validations(data, data.info.language ? data.info.language : 'russian');
        data.questions = data.questions.map((question) => {
            return validateQuestion(question, data.info.language ? data.info.language : 'russian');
        });
        return entityToMap(data)
    })

    on(signOutSuccess, (state, error) => {
        return defaultState
    })


}, defaultState)




