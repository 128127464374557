
import {compose, withState, withHandlers} from "recompose";
import View from "./View";
import { userService } from '../../../_api';

export default compose(

    withState("success", "setSuccess", false),
    withState("loading", "setLoading", false),
    withState("error", "setError", ""),
    withState("email", "setEmail", ""),
    withHandlers({
        changeEmailhandler: props => (val) => {
               props.setEmail(val.target.value)   
               if(props.error) props.setError("")
        },
        handleSubmit: props => () => {
            props.setLoading(true)
            if(props.error) props.setError("")
            userService.requestResetPassword(props.email).then(
                request => {
                    props.setLoading(false)
                    props.setSuccess(true)
                },
                error => {
                    props.setLoading(false)    
                    props.setError(error)
                },
            );
        }
    })
)((View));
