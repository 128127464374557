export const eventRegistration  = function () {
    if(window.fbq) window.fbq('track', 'Registration');
}

export const eventCreateQuiz  = function () {
    if(window.fbq) window.fbq('track', 'createQuiz');
    if(window.VK)  window.VK.Retargeting.Event('createQuiz');
}

export const eventUpdateQuiz  = function () {

}

export const eventUserLogin  = function () {
    if(window.fbq) window.fbq('track', 'Login');
    if(window.VK)  window.VK.Retargeting.Event('Login');
}

export const eventUpdateUser  = function () {
    if(window.fbq) window.fbq('track', 'updateUser');
    if(window.VK)  window.VK.Retargeting.Event('updateUser');
}

export const eventCreateOrder  = function () {
    if(window.fbq) window.fbq('track', 'tryPay');
    if(window.VK)  window.VK.Retargeting.Event('tryPay');
}

export const eventSuccessPayPage= function () {
    if(window.fbq) window.fbq('track', 'successPay');
    if(window.VK)  window.VK.Retargeting.Event('successPay');
}

export const eventFailPayPage  = function () {
    if(window.fbq) window.fbq('track', 'failPay');
    if(window.VK)  window.VK.Retargeting.Event('failPay');
}



