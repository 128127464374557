import {compose, withState, withHandlers, lifecycle} from 'recompose';
import { connect } from 'react-redux';
import View from './View';
import {updateQuizData} from "../../../../../_actions";
import React from "react";
import {Chip} from "@material-ui/core";
import {getUnisenderData} from "../../../../../_api/crmService";


const unisenderInit =  (key, props) => {
    props.setLoading(true);
    getUnisenderData(key).then(
        (r) => {
            if(!r.error) {
                props.setShowAdditional(true);
                props.setUnisenderList(r.result)
            } else {
                props.quizData.notifications.unisender.selectedItem = null
                props.updateQuizData(props.quizData)
                props.setShowAdditional(false);
                props.setSelectedItem(null)
            }
            props.setMessage((r.error)
                ? <Chip
                    variant="outlined"
                    color="red"
                    label={`Ошибка: ${r.error}`}
                />
                : <Chip
                    variant="outlined"
                    label="Подключение установлено"
                    color="primary"
                />);

            props.setLoading(false);
        },
        () => {
            props.setMessage('Ошибка');
            props.setLoading(false);
        }
    );
}
export default compose(
    connect(
        state => ({
            quizData: state.quizData.toJS(),
            doubleOptin: state.quizData.toJS().notifications.unisender && state.quizData.toJS().notifications.unisender.doubleOptin ? state.quizData.toJS().notifications.unisender.doubleOptin : 0
        }),
        { updateQuizData}
    ),
    withState('apiKey', 'setApiKey', props => (props.quizData.notifications.unisender && props.quizData.notifications.unisender.apiKey ? props.quizData.notifications.unisender.apiKey : '')),
    withState('selectedItem', 'setSelectedItem', props => (props.quizData.notifications.unisender && props.quizData.notifications.unisender.selectedItem ? props.quizData.notifications.unisender.selectedItem : null)),
    withState('successSave', 'setSuccessSave', false),
    withState('showSaveButton', 'setShowSaveButton', false),
    withState("message", "setMessage", null),
    withState("loading", "setLoading", false),
    withState('showAdditional', 'setShowAdditional', false),
    withState('unisenderList', 'setUnisenderList', null),
    withState('tags', 'setTags', props => (props.quizData.notifications.unisender && props.quizData.notifications.unisender.tags) ? props.quizData.notifications.unisender.tags : ''),
    withHandlers({
        disableEnabled: props => () => {
            if (props.quizData.notifications.unisender) {
                props.quizData.notifications.unisender.enabled = false
                props.updateQuizData(props.quizData)
            }
            props.handleClose()
        },
        saveDataHandler: (props) => (e) => {
            e.preventDefault()
            props.quizData.notifications.unisender.apiKey = props.apiKey
            props.updateQuizData(props.quizData)
            props.setShowSaveButton(false)
            unisenderInit(props.apiKey, props)
        },
        changeDataHandler: props => (e) => {
            props.setApiKey(e.target.value)
            props.setShowSaveButton(true)
            props.quizData.notifications.unisender.apiKey = props.apiKey
            props.updateQuizData(props.quizData)
        },
        changeSelectedItem: props => (e) => {
            props.setSelectedItem(e)
            props.quizData.notifications.unisender.selectedItem = e
            props.updateQuizData(props.quizData)
        },
        changeTagsHandler: props => (e) => {
            props.setTags(e.target.value)
            props.quizData.notifications.unisender.tags = e.target.value
            props.updateQuizData(props.quizData)
        },
        changeDoubleOptin: props => (e) => {
            props.quizData.notifications.unisender.doubleOptin = e
            props.updateQuizData(props.quizData)
        },

    }),
    lifecycle({
        componentDidMount() {
            if (!this.props.quizData.notifications.unisender) {
                this.props.quizData.notifications.unisender = {
                    enabled: true,
                    apiKey: '',
                    tags: '',
                    doubleOptin: 0
                }
                this.props.updateQuizData(this.props.quizData)
            } else if (!this.props.quizData.notifications.unisender.enabled) {
                this.props.quizData.notifications.unisender.enabled = true;
                this.props.updateQuizData(this.props.quizData)
            }
            if(this.props.quizData.notifications.unisender && this.props.quizData.notifications.unisender.apiKey){
                unisenderInit(this.props.quizData.notifications.unisender.apiKey, this.props);
            }

        }
    })




)(View);


