import React from "react";
import {
    Grid,
    Typography,
    FormControl,
    Button,
    Chip,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    MenuItem

} from "@material-ui/core";

import {config} from "../../../../_services";

const View = ({
                  changePayTypeHandler,
                  payType,
                  payHandler,
                  MrchLogin,
                  SignatureValue,
                  InvoiceID,
                  OutSum,
                  isTest,
                  onRef,
                  selectQuizId,
                  changeSelectQuizIdHandler,
                  quizzes

              }) =>
    <div>
        <form action="https://merchant.roboxchange.com/Index.aspx" hidden method="post"
              ref={onRef}>
            <input name="MrchLogin" type="hidden" value={MrchLogin}/>
            <input name="SignatureValue" type="hidden" value={SignatureValue}/>
            <input name="InvoiceID" type="hidden" value={InvoiceID}/>
            <input name="OutSum" type="hidden" value={OutSum}/>
            {isTest &&
            <input name="IsTest" type="hidden" value="1"/>
            }
        </form>

            <FormControl fullWidth>
                <RadioGroup
                    aria-label="Gender"
                    name="gender1"
                    value={payType}
                    onChange={changePayTypeHandler}
                >
                    <FormControlLabel value="unlimit_one" control={<Radio/>}
                                      label={`Безлимит ${config.robokassa.price_unlimit_one}₽ / 1 квиз / 1 месяц`}/>

                    <FormControlLabel value="unlimit_10" control={<Radio/>}
                                      label={`Безлимит+ ${config.robokassa.price_unlimit_10}₽ / 10 квизов / 1 месяц`}/>
                    <FormControlLabel value="unlimit_all" control={<Radio/>}
                                      label={`Безлимит++ ${config.robokassa.price_unlimit_all}₽ / неограниченное кол-во квизов / 1 месяц`}/>
                </RadioGroup>
            </FormControl>
        {payType == 'unlimit_one' &&
        <FormControl fullWidth>
            <Select onChange={changeSelectQuizIdHandler} fullWidth value={selectQuizId}>
                <MenuItem value={0}>
                    <em>выберите квиз</em>
                </MenuItem>
                {Object.keys(quizzes).map((key) => 
                            <MenuItem key={key} value={key}>#{key} {quizzes[key].info.title}</MenuItem>
                )}

            </Select>
        </FormControl>

        }

        <Grid
            container
            direction="row"
            justify="space-between"
        >
            <Grid item>
                {payType == 'unlimit_all' &&
                <Chip label={`${OutSum}₽, Безлимит для всех квизов`}/>}
                {payType == 'unlimit_10' &&
                <Chip label={`${OutSum}₽, Безлимит для 10 квизов`}/>}
                {(payType == 'unlimit_one' && selectQuizId != 0) && <Chip label={`${OutSum}₽, Безлимит для квиза #${selectQuizId} ${quizzes[selectQuizId].info.title}`}/>}
            </Grid>
            <Grid item>
                <Button disabled={payType == 'unlimit_one' && !selectQuizId} onClick={payHandler} variant="contained">
                    ОПЛАТИТЬ
                </Button>
            </Grid>
        </Grid>


    </div>
;


export default View;
