import React from "react";
import {
    Badge,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    Tooltip,
    Typography,
    withStyles,
    FormHelperText
} from "@material-ui/core";
import classnames from "classnames";
import BranchQuestion from "../../../components/BranchQuestion";
import {
    AddCircle as AddIcon,
    CallSplit as CallSplitIcon,
    Delete as DeleteIcon,
    FileCopy as FileCopyIcon,
    OpenWith as LaunchIcon,
    Settings as SettingsIcon
} from "@material-ui/icons";
import {sortableContainer, sortableElement, sortableHandle} from "react-sortable-hoc";
import {TwitterPicker} from "react-color";
import ToolTip from "../../../../components/ToolTip";
import QuizQuestionContainer from './QuizQuestionContainer';
import answerTypeCustom from '../../../../img/answerTypeCustom.svg'
import answerTypeImage from '../../../../img/answerTypeImage.svg'
import answerTypeInput from '../../../../img/answerTypeInput.svg'
import answerTypeSlider from '../../../../img/answerTypeSlider.svg'
import answerTypeCalendar from '../../../../img/answerTypeCalendar.svg'
import answerTypeSelect from '../../../../img/answerTypeSelect.svg'

import typeImageDefault from '../../../../img/typeImageDefault.svg'
import typeImageSquare from '../../../../img/typeImageSquare.svg'
import typeImageRectangle from '../../../../img/typeImageRectangle.svg'

const DragHandle = sortableHandle(({children}) => children);

const SortableItem = sortableElement(({children}) => (
    children
));

const SortableContainer = sortableContainer(({children}) => children);


const View = ({
                  changeOtherHandler,
                  changeMultySelectHandler,
                  questionActive,
                  quizData,
                  quizState,
                  addQuestionHandler,
                  quizStateToQuestionHandler,
                  deleteQuestionHandler,
                  copyQuestionHandler,
                  changeHasConsultantHandler,
                  hasConsultant,
                  hasQuestionActiveText,
                  changeHasQuestionActiveTextHandler,
                  changeHasDiscount,
                  hasDiscount,
                  onSortEndHandler,
                  background,
                  handleChangeComplete,
                  changeColorOpenHandler,
                  colorOpen,
                  branchQuestionIndex,
                  setBranchQuestionIndexdHandler,
                  isSliderMultiple,
                  changeSliderMultipleHandler,
                  changeIsNoRequiredQuestionHandler,
                  isNoRequiredQuestion,
                  changeTypeQuestionHandler,
                  changeTypeImageHandler,
                  addAnswerHandler,
                  classes,
                  isQuizBot,
                  isOpenEditDiscount, openChangeTypeQuestion, changeDiscountValue, changeDiscountType, changeDiscountCurrency, setIsOpenEditDiscount
              }) => (

    <Grid justify="center" container>
        <Grid className={classnames(classes.content, isQuizBot && 'quiz_bot')}>
            <Grid container
                  className={classes.topPanel}
                  direction="row"
                  justify="space-between"
                  alignItems="flex-end"
            >
                <Typography variant='h6'>Вопрос
                    &nbsp;<span className={classes.questionNav} color="primary">{
                        +quizState.activeKeyItem + 1} из {quizData.questions.length}</span>
                </Typography>
                <Button className={classes.button} onClick={copyQuestionHandler}>
                    <FileCopyIcon/>
                    <span>Дублировать вопрос</span>
                </Button>

                <Button className={classes.button} onClick={deleteQuestionHandler.bind(this, questionActive.id)}>
                    <DeleteIcon/>
                    <span>Удалить вопрос</span>
                </Button>

                <Button color="primary" className={classes.button} onClick={addQuestionHandler}>
                    <AddIcon/>
                    <span>Добавить вопрос</span>
                </Button>
            </Grid>
            <Grid className={classnames(isQuizBot && 'dddd')}>
                <QuizQuestionContainer/>
                <div style={{width: '100%', display: 'flex', margin: 'auto', justifyContent: 'space-between'}}>
                    <div item style={{paddingTop: 5}}>
                        <div>
                            {(['input', 'date', 'slider'].indexOf(questionActive.type) === -1) &&

                            <Button className={classes.buttonAddAnswer} onClick={addAnswerHandler}>
                                <AddIcon/>
                                <span>Добавить ответ</span>
                            </Button>}
                        </div>
                    </div>
                    <div style={{float: 'right'}}>
                        {(questionActive.type === 'images' && quizData.isNewTheme) && <FormControl component="fieldset">

                            <Select onChange={changeTypeImageHandler} value={questionActive.typeImage ? questionActive.typeImage : 'default'}>
                                <MenuItem value="default"><img className={classes.typeQuestionImage}
                                                             src={typeImageDefault}/> вертикальные</MenuItem>
                                <MenuItem value="square"><img className={classes.typeQuestionImage}
                                                               src={typeImageSquare}/> квадратные</MenuItem>
                                <MenuItem value="rectangle"><img className={classes.typeQuestionImage} src={typeImageRectangle}/> горизонтальные</MenuItem>

                            </Select>
                            <FormHelperText>Формат изображения</FormHelperText>
                        </FormControl>}
                        &nbsp;&nbsp;&nbsp;
                        <FormControl component="fieldset">

                            <Select onChange={changeTypeQuestionHandler} value={questionActive.type}>
                                <MenuItem value="input"><img className={classes.typeQuestionImage}
                                                             src={answerTypeCustom}/> Поле ввода</MenuItem>
                                <MenuItem value="answers"><img className={classes.typeQuestionImage}
                                                               src={answerTypeInput}/> Варианты</MenuItem>
                                <MenuItem value="images"><img className={classes.typeQuestionImage}
                                                              src={answerTypeImage}/> С картинками</MenuItem>
                                <MenuItem value="slider"><img className={classes.typeQuestionImage}
                                                              src={answerTypeSlider}/> Ползунок</MenuItem>
                                <MenuItem value="date"><img className={classes.typeQuestionImage}
                                                            src={answerTypeCalendar}/> Дата</MenuItem>
                                <MenuItem value="select"><img className={classes.typeQuestionImage}
                                                            src={answerTypeSelect}/> Выпадающий список</MenuItem>

                            </Select>
                            <FormHelperText>Тип ответа</FormHelperText>
                        </FormControl>
                    </div>

                </div>
            </Grid>
            <br/>
            <br/>
            <br/>

            <div className={classes.questionPagination}>


                <div style={{width: '100%'}}>
                    <SortableContainer onSortEnd={onSortEndHandler} lockAxis="xy" axis="xy" useDragHandle>
                        <div>
                            {quizData.questions.map((question, key) =>
                                <SortableItem key={`${key}_${question.id}`} index={key}>
                                    <div
                                        className={
                                            classnames(
                                                classes.navCard,
                                                classes.navQuestion,
                                                {[classes.navQuestionActive]: key == quizState.activeKeyItem})
                                        }
                                        onClick={quizStateToQuestionHandler.bind(this, key)}
                                    >
                                        {key > 0 &&
                                        <Badge className={classes.branchQuestion}
                                               badgeContent={question.displayConditions ? question.displayConditions.length : null}
                                               color="secondary"
                                               onClick={setBranchQuestionIndexdHandler.bind(this, key)}>
                                            <CallSplitIcon/>
                                        </Badge>
                                        }
                                        <DragHandle><LaunchIcon className={classes.sortQuestion}/></DragHandle>
                                        <Typography>Вопрос {+key + 1}</Typography>
                                    </div>
                                </SortableItem>
                            )}
                        </div>
                    </SortableContainer>

                    <div className={classnames(classes.navCard, classes.addQuestionNav)} onClick={addQuestionHandler}>
                        <AddIcon/>
                        <Typography>Добавить вопрос</Typography>
                    </div>
                </div>
            </div>
        </Grid>
        <Grid
            container
            direction="column"
            className={classes.rightPanel}
        >
            <Grid>

                <FormControlLabel
                    control={
                        <Switch
                            checked={hasDiscount}
                            onChange={changeHasDiscount}
                        />
                    }
                    label={'Скидка'}

                />
                {hasDiscount && <Tooltip title={'Редактировать скидку'}>
                    <SettingsIcon className={classes.editDiscount} onClick={() => setIsOpenEditDiscount(true)}/>
                </Tooltip>}

                <FormControlLabel
                    control={
                        <Switch
                            checked={hasConsultant}
                            onChange={changeHasConsultantHandler}
                        />
                    }
                    label="Показывать ассистента (менеджера)"
                />
                <FormControlLabel
                    style={{marginTop: 8}}
                    control={
                        <Switch
                            checked={hasQuestionActiveText}
                            onChange={changeHasQuestionActiveTextHandler}
                        />
                    }
                    label={`Показывать подсказку для вопроса №${+quizState.activeKeyItem + 1}`}
                />

                <label className={classes.colorContainer}>
                    <span className={classes.color} style={{background: background}} onClick={changeColorOpenHandler}>
                    </span>
                    {colorOpen && <div className={classes.colorPanel}>
                        <TwitterPicker width={150} color={background} onChangeComplete={handleChangeComplete}/>
                    </div>}
                    <Typography onClick={changeColorOpenHandler}>Цвет формы</Typography>
                </label>


            </Grid>
            <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/><br/>
            <div
                style={{
                    height: 129,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end'
                }}
            >
                {(questionActive.type === 'answers' || questionActive.type === 'images') && <FormControlLabel

                    control={
                        <Switch
                            checked={questionActive.multySelect}
                            onChange={changeMultySelectHandler}
                        />
                    }
                    label={'несколько вариантов'}
                />}
                {(questionActive.type === 'date') && <FormControlLabel

                    control={
                        <Switch
                            checked={questionActive.multySelect}
                            onChange={changeMultySelectHandler}
                        />
                    }
                    label={'диапазон дат'}
                />}
                {questionActive.type === 'answers' && <FormControlLabel

                    control={
                        <Switch
                            checked={questionActive.other}
                            onChange={changeOtherHandler}
                        />
                    }
                    label={'Свой ответ'}
                />}

                {questionActive.type === 'slider' && <FormControlLabel

                    control={
                        <Switch
                            checked={isSliderMultiple}
                            onChange={changeSliderMultipleHandler}
                        />
                    }
                    label={["Выбор диапазона", <ToolTip keyItem={'sliderMultiple'}/>]}
                />}

                <FormControlLabel className={classes.requiredSwitch}
                                  control={
                                      <Switch
                                          checked={isNoRequiredQuestion}
                                          onChange={changeIsNoRequiredQuestionHandler}
                                      />
                                  }
                                  label={"Необязательный вопрос"}
                />

            </div>


        </Grid>
        {branchQuestionIndex !== null && <BranchQuestion closeHandler={setBranchQuestionIndexdHandler.bind(this, null)}
                                                         questionIndex={branchQuestionIndex}/>}
        <Dialog
            open={isOpenEditDiscount}
            onClose={() => setIsOpenEditDiscount(!isOpenEditDiscount)}
        >
            <DialogTitle>
                Параметры скидки
            </DialogTitle>

            <DialogContent>

                <FormControl>
                    <FormLabel>Тип скидки</FormLabel>
                    <RadioGroup
                        row
                        value={quizData.info.discount.type}
                        onChange={changeDiscountType}
                    >
                        <FormControlLabel value="increasing" control={<Radio/>}
                                          label={["нарастающая", <ToolTip keyItem={'discountIncreasing'}/>]}/>
                        <FormControlLabel value="melting" control={<Radio/>}
                                          label={["тающая", <ToolTip keyItem={'discountMelting'}/>]}/>
                        <FormControlLabel value="increasingPercent" control={<Radio/>}
                                          label={["нарастающий процент",
                                              <ToolTip keyItem={'discountIncreasingPercent'}/>]}/>

                    </RadioGroup>
                </FormControl>


                {quizData.info.discount && quizData.info.discount.type == 'increasingPercent' &&

                <FormControl component="fieldset">
                    <InputLabel>процент скидки</InputLabel>
                    <Input autoFocus placeholder='процент скидки' type="number" onChange={changeDiscountValue}
                           value={quizData.info.discount.value}/>
                </FormControl>
                }
                {quizData.info.discount && ['increasing', 'melting'].indexOf(quizData.info.discount.type) != -1 &&
                [<FormControl component="fieldset" className={classes.formControl}>
                    <InputLabel>Размер скидки</InputLabel>
                    <Input autoFocus placeholder='максимальный размер скидки' type="number"
                           onChange={changeDiscountValue} value={quizData.info.discount.value}
                    />
                </FormControl>,
                    <FormControl component="fieldset" className={classes.formControl}>
                        <InputLabel>Валюта скидки</InputLabel>
                        <Select
                            value={quizData.info.discount.currency ? quizData.info.discount.currency : "₽"}
                            onChange={changeDiscountCurrency}>
                            <MenuItem value="₽">₽</MenuItem>
                            <MenuItem value="$">$</MenuItem>
                            <MenuItem value="€">€</MenuItem>
                        </Select>
                    </FormControl>]

                }


            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsOpenEditDiscount(false)} color="primary">
                    Закрыть
                </Button>

            </DialogActions>

        </Dialog>
    </Grid>


);

const styles = theme => ({
    colorContainer: {
        display: 'inline-flex',
        cursor: 'pointer',
        alignItems: 'center',
        position: 'relative',
        marginTop: 5
    },
    questionPagination: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    topPanel: {
        marginTop: -10
    },
    colorPanel: {
        top: 50,
        position: 'absolute',
        width: '100%'
    },
    color: {
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        width: 30,
        height: 30,
        marginRight: 17,
        borderRadius: 5
    },
    content: {
        maxWidth: 1024,
        width: '100%',
        '@media(min-width: 1201px)': {
            width: 'calc(100% - 150px)',
        },
        '&.quiz_bot': {
            width: 'auto'
        }
    },
    rightPanel: {
        padding: 10,
        width: '100%',
        '@media(min-width: 1201px)': {
            width: 150,
            marginTop: 20,
            paddingBottom: 60
        },
        '& span,p': {
            fontSize: '13px !important'
        }
    },
    navQuestion: {
        borderWidth: 2,
        color: theme.palette.secondary.main,
        background: theme.palette.background.light,
        padding: 4,
        marginBottom: 3,
        '& p': {
            textAlign: 'left',
            fontSize: '0.5875rem',
            fontWeight: 600
        }
    },
    addQuestionNav: {
        color: theme.palette.primary.main,
        borderStyle: 'dotted',
        borderColor: theme.palette.primary.main,
        padding: 0,
        '& p': {
            display: 'block',
            lineHeight: 1,
            color: theme.palette.primary.main,
            fontSize: '0.5875rem',
            fontWeight: 600
        },
        '& svg': {
            fontSize: 24
        }
    },
    navCard: {
        cursor: 'pointer',
        textAlign: 'center',
        width: 60,
        height: 54,
        float: 'left',
        marginRight: 10,
        fontSize: 15,
        position: 'relative',
        marginTop: 10

    },
    navQuestionActive: {
        padding: 2,
        marginBottom: 3,
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid'
    },
    button: {
        cursor: 'pointer',
        textTransform: 'uppercase',
        '& svg': {
            fontSize: 20,
            marginRight: 10
        }
    },
    questionNav: {

        fontWeight: 600,
        fontSize: 25,
        color: theme.palette.primary.main
    },
    sortQuestion: {
        cursor: 'pointer',
        position: 'absolute',
        bottom: 3,
        right: 3
    },
    branchQuestion: {
        cursor: 'pointer',
        position: 'absolute',
        bottom: 3,
        left: 3
    },
    requiredSwitch: {
        marginTop: 15
    },
    typeQuestionImage: {
        width: 20,
        height: 20,
        marginRight: '1rem'
    },
    buttonAddAnswer: {
        cursor: 'pointer',
        float: 'left',
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        '& svg': {
            fontSize: 30,
            marginRight: 10
        }
    },
    editDiscount: {
        marginTop: -3,
        marginLight: -3,
        cursor: 'pointer',
        color: theme.palette.primary.main,
    },
    formControl: {
        margin: 5,
        minWidth: 120
    }
});

export default withStyles(styles)(View);
