
import { signOut } from '../_actions';
import { store} from '../_services';

export function handleResponse(response) {
    return response.json().then(data => {
        if (!response.ok) {
            if (response.status === 401) {
                store.dispatch(signOut())
            }
            const error = (data && data.error &&  data.error.message) || 'Неизвестная ошибка';
            return Promise.reject(error);
        }
        return data;
    }, errorData => {
            return Promise.reject('Неизвестная ошибка');
        }
    );
}

export function handleResponseNoLogout(response) {
    return response.json().then(data => {
        if (!response.ok) {
            const error = (data && data.error &&  data.error.message) || 'Неизвестная ошибка';
            return Promise.reject(error);
        }
        return data;
    }, errorData => {
        return Promise.reject('Неизвестная ошибка');
    });
}


