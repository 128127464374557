import {compose, withState, withHandlers, lifecycle} from 'recompose';
import {connect} from 'react-redux';
import { attachPromoCode, getQuizList} from "../../../_actions";
import BalanceView from './BalanceView';


export default compose(
    connect(
        state => ({
            userInfo: state.userInfo.toJS(),
            quizzes: state.quizzes.toJS()
        }),
        {attachPromoCode, getQuizList}
    ),
    withState('tableState', 'setTableState', 0),
    
    withHandlers({
        tableStateChangeHandler: props =>  (e, tableState) => {
            props.setTableState(tableState);
        },
        attachPromoCode: props =>  (e) => {
            e.preventDefault();
            const data = new FormData(e.target);
            const promoCode = data.get('code');
            props.attachPromoCode(promoCode);
            e.target.reset()
        }}),
    lifecycle({
        componentDidMount() {
            if(!this.props.quizzes.isLoading) {
                this.props.getQuizList();
            }
        }
    })
)(BalanceView);

